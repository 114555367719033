//$container-width: 192rem; //resolve in 192x12.5px(fontsize) = 2400px
$xs-min: 477px;
$sm-min: 768px;
$md-min: 992px;
$lg-min: 1728px;

$xs-max: ($xs-min - 1); // Mobile
$sm-max: ($sm-min - 1); // Tablet
$md-max: ($md-min - 1); // Desktop
$lg-max: ($lg-min - 1); // Large Desktop

@mixin lg {
	@media screen and (max-width: $lg-min) {
		@content;
	}
}
@mixin md {
	@media screen and (max-width: $md-max) {
		@content;
	}
}
@mixin md-up {
	@media screen and (min-width: $md-min) {
		@content;
	}
}
// tablet
@mixin sm {
	@media screen and (max-width: $sm-max) {
		@content;
	}
}
@mixin sm-up {
	@media screen and (min-width: $sm-min ) {
		@content;
	}
}
// mobile
@mixin xs {
	@media screen and (max-width: $xs-max) {
		@content;
	}
}

@mixin text($fontSize) {
	font-size: var(--fs-#{$fontSize});
	line-height: var(--lh-#{$fontSize});
}

@mixin size($w, $h: $w) {
	width: $w;
	height: $h;
}
@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}
@mixin animation($str) {
	animation: #{$str};
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
}
@mixin transform($args) {
	transform: $args;
	-webkit-transform: $args;
	-moz-transform: $args;
	-ms-transform: $args;
	-o-transform: $args;
}

@mixin transform-origin($args) {
	transform-origin: $args;
	-webkit-transform-origin: $args;
	-moz-transform-origin: $args;
	-ms-transform-origin: $args;
	-o-transform-origin: $args;
}
@mixin transition($args...) {
	transition: $args;
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
}
@mixin transition-delay($args) {
	transition-delay: $args;
	-webkit-transition-delay: $args;
	-moz-transition-delay: $args;
	-ms-transition-delay: $args;
	-o-transition-delay: $args;
}
@mixin custom-scroll($width: 0.7rem, $bgTrack: #ddd, $bgThumb: #1D1D1D) {
	&::-webkit-scrollbar {
		width: $width;
	}
	&::-webkit-scrollbar-track {
		background: $bgTrack;
	}
	&::-webkit-scrollbar-thumb {
		background: $bgThumb;
		border-radius: 2rem;
	}
}

@mixin border-gradient($color: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), $radius: inherit) {
    padding: 1px;
    border-radius: $radius;
    background: $color border-box;
    -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}