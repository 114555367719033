@use '../core/mixin' as *;
@use '../core/variables' as *;
.home {
    --height-header: 15.4rem;
    position: relative;
    z-index: 5;

    &-hero {
        .container {
            height: 100%;
        }

        z-index: 1;
        height: 100vh;
        min-height: 100vh;
        position: relative;

        @include sm {
            height: 100svh;
            min-height: auto;
        }

        &-content {
            --total-space: calc(100vh - 54rem);
            grid-column: 1/-1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            max-width: 120.3rem;
            margin: 0 auto;
            // padding-top: calc(.1644 * var(--total-space));
            padding-top: 0;
            padding-bottom: calc((.5432 - .1644) * var(--total-space));

            @include md {
                padding-top: 7.3rem;
            }

            @include sm {
                justify-content: flex-start;
                padding-top: 5.4rem;
                padding-bottom: 0;
            }
        }

        &-logo {
            width: 6.5rem;
            margin-bottom: calc(.078782 * var(--total-space));

            @include md {
                width: 4.4rem;
                margin-bottom: 7.4rem;
            }

            @include sm {
                width: 4.1rem;
                margin-bottom: 13rem;
            }
        }

        &-title {
            // margin-bottom: calc(.04834377798 * var(--total-space));
            margin-bottom: 5.4rem;

            @include md {
                max-width: 62rem;
            }
        }

        &-btn {
            width: 18rem;
            border-radius: var(--border-radius);

            @include sm {
                display: none;
            }
        }

        &-bg {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            @include size(100%, calc(100% + 7rem));
            margin: 0 auto;
            pointer-events: none;
            @include sm {
                @include size(100%, calc(100% + 9rem));
            }
            .img {
                margin-top: -19rem;
                object-fit: cover;
                @include size(100%, calc(100vh + 19rem + 7rem - 8.9rem));
                object-position: 50% 29%;
                position: relative;
                z-index: 1;
                background-color: rgba($cl-black, .4);

                @include md {
                    margin-top: 0;
                    @include size(100%, calc(100vh - 6rem));
                }

                @include sm {
                    margin-top: 0;
                    @include size(100%, calc(100vh - 10rem));
                }
            }

            &-overlay {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                @include size(100%);
                background-color: rgba($cl-bg, .4);
            }

            &-grad {
                position: absolute;
                left: 0;
                bottom: 0;
                min-height: 9.8rem;
                margin-top: -34.9rem;
                @include size(100%, 44.7rem);
                background: linear-gradient(180deg, rgba($cl-bg, 0.00) 0%, rgba($cl-bg, 1) calc(100% - 8.9rem));
                z-index: 3;
            }
        }

        &-bg-test {
            position: absolute;
            top: 0;
            left: 0;
            @include size(100%, 100vh);
            // opacity: 0;
            animation: fadeIn 6s;
            // background-color: blue;

            @keyframes fadeIn {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }

            &-wrap {
                @include size(100%);

                div {
                    @include size(100%);
                }
            }
        }
        &-bg {
            // display: none;
        }
        &-bg-test {
            display: none;
        }
        &-discover {
            display: flex;
            align-items: center;
            pointer-events: auto;
            position: absolute;
            left: 0;
            bottom: 0;
            margin-left: var(--container-padding);
            margin-bottom: 3rem;

            @include md {
                margin-bottom: 5rem;
            }

            @include sm {
                display: none;
            }

            &-txt {
                @include md {
                    display: none;
                }

                &.mod-tablet {
                    display: none;

                    @include md {
                        display: block;
                    }
                }
            }
        }

        &-mouse {
            --border-radius: 100vmax;

            position: relative;
            @include size(4.4rem, 6.6rem);
            display: flex;
            justify-content: center;
            margin-right: 1.6rem;
            background: rgba($cl-white, .08);
            border-radius: 100vmax;
            align-items: center;
            justify-content: center;

            @include md {
                @include size(4rem, 6rem);
                margin-right: 1.2rem;
            }

            &-border {
                z-index: 10;
                position: absolute;
                @include size(100%);
                pointer-events: none;
                padding: 1px;
                inset: 0px;
                mask-mode: match-source, match-source;
                background: linear-gradient(to bottom, rgba($cl-white, .1) 0%, transparent 80%) border-box;
                mask-composite: exclude;
                -webkit-mask-composite: xor;
                -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
                -webkit-mask-position: 0 0, 0 0;
                -webkit-mask-size: auto, auto;
                -webkit-mask-repeat: repeat, repeat;
                -webkit-mask-clip: content-box, border-box;
                -webkit-mask-origin: content-box, border-box;
                -webkit-mask-source-type: auto, auto;
                border-radius: 100vmax;

                @include md {
                    background: linear-gradient(to bottom, rgba($cl-primary-hl, .7) 0%, transparent 60%) border-box;
                }
            }
            &-slide {
                background: linear-gradient(to bottom, transparent 0%, rgba($cl-white, .1) 30%, rgba($cl-white, .1) 65%, transparent 100%);
                @include size(1px, 4.2rem);

                @include md {
                    height: 4rem;
                }
            }

            &-wheel {
                position: absolute;
                width: 12.5%;
                aspect-ratio: 1;
                background-color: $cl-white;
                border-radius: 50%;
                top: 33.33%;
                box-shadow: 0rem 0rem 1rem 0rem rgba($cl-white, 0.75);
                @include animation(moveWheel 2s cubic-bezier(0.83, 0, 0.17, 1) infinite alternate-reverse);
            }
        }


    }

    &-main {
        margin-top: calc(var(--height-header) * -1);
        position: relative;
        z-index: 2;

        &-inner {
            position: relative;
            z-index: 1;

            @include md {
                margin-top: 20rem;
            }
        }
    }

    &-showreel {
        z-index: 2;
        position: relative;
        height: 200vh;
        contain: paint;
        padding-bottom: 4rem;
        filter: drop-shadow(0px 4rem 4rem rgba($cl-black, .6));

        @include sm {
            height: auto;
        }
        &--bg-block {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 50vh;
            z-index: 1;
            background-color: rgba($cl-bg, 1);
        }
        &--wrap {
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            position: sticky;
            top: var(--height-header);
            height: calc(100vh - var(--height-header) - 4rem);
            margin: 0 auto;
            padding: 0 var(--container-padding);

            @include sm {
                height: auto;
                position: relative;
                top: auto;
                --container-padding: 2rem;
            }
        }

        &-main--inner {
            overflow: hidden;
            z-index: 3;
            position: relative;
            @include size(100%);

            @include md {
                padding-block: 28.125%;
                height: auto;
            }

            @include sm {
                @include size(100%);
                padding-block: 0;
                border-radius: 1rem;
            }
        }

        &-other--inner {
            position: absolute;
            display: flex;
            align-items: center;
            gap: 0 1.75vw;

            @include sm {
                display: none;
            }

            &:first-child {
                left: 4rem;

                @include md {
                    left: 9rem;
                }
            }

            &:last-child {
                flex-direction: row-reverse;
                right: 4rem;

                @include md {
                    right: 9rem;
                }
            }

            .home-showreel-item-other {
                flex: none;
                width: auto;
                aspect-ratio: 2 / 3;
                overflow: hidden;
                border-radius: 1.2rem;

                img {
                    object-fit: contain;
                    width: auto;
                    height: 100%;
                }
            }

            .home-showreel-item-other:nth-child(1) {
                width: 5.5vw;

                @include md {
                    display: none;
                }
            }

            .home-showreel-item-other:nth-child(2) {
                width: 9.2vw;
            }

            .home-showreel-item-other:nth-child(3) {
                width: 15.36vw;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            border-radius: 1.2rem;
            position: relative;
            height: 100%;

            &-main {
                width: 100%;

                @include md {
                    height: auto;
                }

                &:hover {
                    .home-showreel-play-ic {
                        svg {
                            path:first-child {
                                @include transform(translate(101%));
                                opacity: 0;
                                transition-delay: 0;
                            }

                            path:last-child {
                                @include transform(translate(0%));
                                opacity: 1;
                                transition-delay: .1s;
                            }
                        }
                    }
                }
            }

            &-overlay {
                position: absolute;
                top: 0;
                left: 0;
                @include size(100%);
                background-color: rgba($cl-bg, 1);
                z-index: 4;

                @include sm {
                    display: none;
                }
            }
        }

        &-thumb {
            position: relative;
            overflow: hidden;
            @include size(100%);

            &-link {
                position: absolute;
                top: 0;
                left: 0;
                @include size(100%);

                &-vid {
                    object-fit: cover;
                    @include size(100%);

                    &.hidden {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }

            .img {
                object-position: center center;

                &.hidden {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }

        &-play-wrapper {
            --magnetic-offset: 6rem;
            border-radius: 50%;
            width: calc(65% + var(--magnetic-offset) * 2);
            padding: var(--magnetic-offset);
            // pointer-events: auto;

            @include md {
                width: calc(40% + var(--magnetic-offset) * 2);
            }

            @include sm {
                width: calc(20% + var(--magnetic-offset) * 2);
            }
        }

        &-play {
            position: absolute;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
            // @include transition(opacity .3s ease);

            &-ic {
                border-radius: 50%;
                aspect-ratio: 1;
                background: rgba($cl-white, 0.20);
                display: flex;
                align-items: center;
                justify-content: center;
                flex: none;
                width: 100%;

                svg {
                    @include transition(opacity .5s ease);
                }

                &-play {
                    width: 32%;
                    margin-left: 5%;
                    overflow: hidden;

                    path {
                        @include transition(transform .65s cubic-bezier(.785, .135, .15, .86), opacity .65s cubic-bezier(.785, .135, .15, .86));
                    }

                    path:first-child {
                        transition-delay: .1s;
                    }

                    path:last-child {
                        @include transform(translate(-101%));
                        transition-delay: 0s;
                        opacity: 0;
                    }
                }

                &-pause {
                    position: absolute;
                    width: 32%;
                    opacity: 0;
                }
            }

            .h0 {
                position: absolute;
                white-space: nowrap;
                --text-position: 100%;

                span {
                    display: inline-block;
                    opacity: 1;
                    @include transform(translateX(0));
                    @include transition(all .4s ease);
                }

                @include md {
                    --fs-h0: 4.4rem;

                    --text-position: calc(100% - 6rem);
                }

                @include sm {
                    --fs-h0: 2.4rem;
                    --lh-h0: 1.2rem;

                    --text-position: calc(100% - 8.5rem);
                }
            }

            &-first {
                pointer-events: none;
                right: var(--text-position);
            }

            &-last {
                pointer-events: none;
                left: var(--text-position);
            }

            &.hidden {
                pointer-events: none;

                .home-showreel-play-first {
                    span {
                        opacity: 0;
                        @include transform(translateX(-5rem));
                    }
                }

                .home-showreel-play-last {
                    span {
                        opacity: 0;
                        @include transform(translateX(5rem));
                    }
                }

                .home-showreel-play-ic-play {
                    opacity: 0;
                }

                .home-showreel-play-ic-pause {
                    opacity: 1;
                }
            }
        }
    }

    &-service {
        &-preamble {
            position: relative;
            height: 650vh;
            z-index: 1;
            margin-top: -200vh;

            @include md {
                height: 520vh;
            }

            @include sm {
                height: 520vh;
                margin-top: unset;
            }

            &-sticky {
                position: sticky;
                top: 0;
                height: 100vh;
            }

            &-bg {
                position: absolute;
                top: 0;
                left: 0;
                pointer-events: none;
                @include size(100%, 221.6rem);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                overflow: hidden;
                opacity: 0;
                @include md {
                    @include size(100%, 137.3rem);
                }

                @include sm {
                    @include size(100%, 61.5rem);
                }
            }
            &-inner {
                position: relative;
                z-index: 2;
                display: grid;
                align-items: center;
                justify-content: center;
                justify-items: center;
                @include size(100%);

                &-item {
                    grid-column: 1/2;
                    grid-row: 1/2;
                    max-width: 123.2rem;
                    text-align: center;
                    opacity: 0;
                    pointer-events: none;

                    @include md {
                        max-width: 64.2rem;
                    }
                    @include sm {
                        max-width: 33rem;
                    }

                    &.active {
                        opacity: 1;
                        pointer-events: auto;
                    }

                    h1 {
                        @include sm {
                            font-size: 3.6rem;
                        }
                    }
                }
                &-item:nth-child(3){
                    max-width:112.2rem;
                    @include md {
                        max-width: 55.2rem;
                    }
                    @include sm {
                        max-width: 33rem;
                    }
                }
                &-item:nth-child(4){
                    max-width:112.2rem;
                    @include md {
                        max-width: 55.2rem;
                    }
                    @include sm {
                        max-width: 33rem;
                    }

                    h1{
                        @include sm {
                            font-size: 3.4rem;
                        }
                    }
                }
            }
        }

        &-main {
            position: relative;
            grid-column: 1/-1;

            @include md {
                padding-top: 0;
            }

            @include sm {
            }
            &-wrap {
                margin-top: -10vh;
                padding-bottom: 10rem;

                @include md {
                    margin-top: -30vh;
                }

                @include sm {
                    margin-top: -15vh;
                }
            }
        }

        &-listing {
            grid-column: 1/-1;
        }

        &-item {
            cursor: pointer;
            align-items: center;
            position: relative;
            padding: 4rem 0;
            @include md{
                padding: 5.6rem 0;
            }
            @include sm {
                padding: 3.2rem 0;
            }
            &:hover {
                .home-service-item-toggle svg g path:nth-child(1){
                    fill: #F5450D !important;
                    fill-opacity: 1;
                }
            }
            &.active {
                .home-service-item-title {
                    color: $cl-primary;
                }
                .home-service-item-toggle svg{
                    transform: rotate(-180deg);
                    g path:nth-child(1) {
                        fill: #F5450D !important;
                        fill-opacity: 1;
                        transform: scale(.9);
                    }
                    g path:nth-child(2){
                        opacity: 0;
                    }
                }
                &-question{
                    padding-bottom: 3rem;
                }
            }

            &-title {
                @include transition(color .4s ease-in-out);
                span {
                    opacity: .2;
                    font-weight: 400;
                }
            }
            &-question{
                display: flex;
                justify-content:space-between;
                align-items: center;
                grid-column: 2/16;

                @include md {
                    grid-column: 1/13;
                }

                @include sm {
                    grid-column: 1/-1;
                }
            }
            &-toggle{
                @include size(6rem);
                @include md {
                    @include size(4rem);
                }
                @include sm {
                    @include size(3.2rem);
                }
                svg {
                    @include transition(all .4s cubic-bezier(.455, .03, .515, .955));
                }
                svg g path{
                    transform-origin: center;
                    @include transition(all .4s cubic-bezier(.455, .03, .515, .955));
                }
            }
            &-desc {
                grid-column: 2/9;
                grid-row:2;
                margin-top: 3rem;

                @include md {
                    grid-column: 1/9;
                    margin-right: 1.6rem;
                    margin-top: 2rem;
                    &-frame {
                        margin-top:3rem;
                    }
                }

                @include sm {
                    grid-column: 1/-1;
                    opacity: 1;
                    display: none;
                    overflow: hidden;
                    margin-right: 0;
                }
                 &-frame{
                    margin-top:3rem;
                    @include sm {
                      margin-top:2rem;
                     }
                 }
            }

            .line {
                position: absolute;
                top: 0;
            }

            @media (hover:hover) {
                &:hover {
                    .home-service-item-title {
                        color: $cl-primary;
                        height: 100%;

                    }

                    .home-service-item-desc {
                        /* opacity: 1; */
                        @include transform(translate(0));
                    }
                }
            }
        }

        &-thumb {
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 53%;
            display: grid;
            grid-template-columns: 1fr;

            @include md {
                left: 30.8rem;
            }

            @include sm {
                display: none;
            }

            &-item {
                width: 32rem;
                grid-area: 1/1/2/2;
                opacity: 0;
                @include transform(scale(1.1));
                overflow: hidden;
                // @include transition(opacity 0.6s cubic-bezier(0.45, 0, 0.55, 1), scale 1s cubic-bezier(0.45, 0, 0.55, 1));
                @include transition(opacity .3s ease-in,
                    transform .2s cubic-bezier(0.45, 0.5, 0.55, 1));

                @include md {
                    width: 16.5rem;
                }

                @include sm {
                    width: 14.5rem;
                }

                .img.cloner {
                    --gap-horizontal: 20px;
                    --gap-vertical: 2px;
                    --time-anim: 2.25s;
                    --blend-mode-1: none;
                    --blend-mode-2: none;
                    --blend-mode-3: none;
                    --blend-mode-4: none;
                    --blend-mode-5: none;
                    --blend-color-1: transparent;
                    --blend-color-2: transparent;
                    --blend-color-3: transparent;
                    --blend-color-4: transparent;
                    --blend-color-5: transparent;

                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &.active {
                    opacity: 1;
                    @include transform(scale(1.1));
                    @include transition(opacity .8s ease-out, transform .2s cubic-bezier(0.45, 0.5, 0.55, 1));
                }

                // &:nth-child(5n).active {
                //     img.cloner {
                //         @include animation(glitch-anim-1 var(--time-anim) infinite linear alternate);

                //         &:nth-child(2) {
                //             animation-name: glitch-anim-1;
                //             animation-delay: calc((var(--time-anim) + 4s) * -1);
                //             animation-duration: calc((var(--time-anim) + 5s) * -1);
                //         }

                //         &:nth-child(3) {
                //             animation-name: glitch-anim-2;
                //             animation-delay: calc(var(--time-anim) * -1);
                //         }

                //         &:nth-child(4) {
                //             animation-name: glitch-anim-3;
                //             animation-duration: calc((var(--time-anim) + 2s) * -1);
                //             animation-delay: calc((var(--time-anim) + 5s) * -1);
                //         }

                //         &:nth-child(5) {
                //             animation-delay: calc((var(--time-anim) + 1s) * -1);
                //             animation-name: glitch-anim-flash;
                //         }
                //     }
                // }

                // &:nth-child(5n+1).active {
                //     img.cloner {
                //         @include animation(glitch-anim-1 var(--time-anim) infinite linear alternate);

                //         &:nth-child(2) {
                //             animation-name: glitch-anim-1;
                //             animation-direction: reverse;
                //             animation-delay: calc(var(--time-anim)/4);
                //         }

                //         &:nth-child(3) {
                //             animation-direction: reverse;
                //             animation-duration: calc(random(2) * var(--time-anim));
                //             animation-delay: calc(var(--time-anim) + 5s);
                //             animation-name: glitch-anim-2;
                //         }

                //         &:nth-child(4) {
                //             animation-duration: calc(random(5) * var(--time-anim));
                //             animation-delay: calc(var(--time-anim) + 2s);
                //             animation-name: glitch-anim-3;
                //         }

                //         &:nth-child(5) {
                //             animation-direction: reverse;
                //             animation-delay: calc(var(--time-anim)/2);
                //             animation-duration: calc(var(--time-anim)/2);
                //             animation-name: glitch-anim-flash;
                //         }
                //     }
                // }

                // &:nth-child(5n+2).active {
                //     img.cloner {
                //         @include animation(glitch-anim-1 var(--time-anim) infinite linear alternate);

                //         &:nth-child(2) {
                //             animation-direction: reverse;
                //             animation-name: glitch-anim-1;
                //             animation-delay: calc(var(--time-anim)*3);
                //             animation-duration: calc(var(--time-anim) + 1s);
                //         }

                //         &:nth-child(3) {
                //             animation-name: glitch-anim-2;
                //             animation-duration: calc(var(--time-anim) + 1s);
                //         }

                //         &:nth-child(4) {
                //             animation-direction: reverse;
                //             animation-duration: calc(var(--time-anim) + 4s);
                //             animation-delay: calc(var(--time-anim)/4 + 1s);
                //             animation-name: glitch-anim-3;
                //         }

                //         &:nth-child(5) {
                //             animation-delay: calc(var(--time-anim)*2);
                //             animation-duration: calc(var(--time-anim) + 4s);
                //             animation-name: glitch-anim-flash;
                //         }
                //     }
                // }

                // &:nth-child(5n+3).active {
                //     img.cloner {
                //         @include animation(glitch-anim-1 var(--time-anim) infinite linear alternate);

                //         &:nth-child(2) {
                //             animation-direction: reverse;
                //             animation-name: glitch-anim-2;
                //             animation-delay: calc(var(--time-anim)*3);
                //             animation-duration: calc(var(--time-anim) + 1s);
                //         }

                //         &:nth-child(3) {
                //             animation-name: glitch-anim-2;
                //             animation-duration: calc(var(--time-anim) + 1s);
                //         }

                //         &:nth-child(4) {
                //             animation-direction: reverse;
                //             animation-duration: calc(var(--time-anim) + 4s);
                //             animation-delay: calc(var(--time-anim)/4 + 1s);
                //             animation-name: glitch-anim-1;
                //         }

                //         &:nth-child(5) {
                //             animation-delay: calc(var(--time-anim)*2);
                //             animation-duration: calc(var(--time-anim) + 4s);
                //             animation-name: glitch-anim-flash;
                //         }
                //     }
                // }

                // &:nth-child(5n+4).active {
                //     img.cloner {
                //         @include animation(glitch-anim-1 calc(var(--time-anim) * 1.5) infinite linear alternate);

                //         &:nth-child(2) {
                //             animation-direction: reverse;
                //             animation-name: glitch-anim-3;
                //             animation-delay: calc((var(--time-anim) * 2) * -1);
                //             animation-duration: calc((var(--time-anim) + 2s) * -1);
                //         }

                //         &:nth-child(3) {
                //             animation-name: glitch-anim-2;
                //             animation-duration: calc((var(--time-anim) + 2s) * -1);
                //         }

                //         &:nth-child(4) {
                //             animation-direction: reverse;
                //             animation-duration: calc((var(--time-anim) + 3s) * -1);
                //             animation-delay: calc((var(--time-anim)/4 + 2s) * -1);
                //             animation-name: glitch-anim-1;
                //         }

                //         &:nth-child(5) {
                //             animation-delay: calc((var(--time-anim) + 1s) * -1);
                //             animation-duration: calc((var(--time-anim) + 3s) * -1);
                //             animation-name: glitch-anim-flash;
                //         }
                //     }
                // }
            }
        }
    }

    &-process {
        padding-bottom: 25rem;
        overflow: hidden;
        display: none;

        @include md {
            padding-bottom: 18rem;
        }

        @include sm {
            padding-bottom: 12rem;
        }

        &-title--wrap {
            grid-column: 2/7;
            display: flex;
            flex-direction: column;
            align-items: start;
            align-self: start;

            @include md {
                grid-column: 1/7;
            }

            @include sm {
                grid-column: 1/-1;
            }
        }

        &-title {
            margin-bottom: 5.8rem;

            @include sm {
                margin-bottom: 3.2rem;
            }
        }

        &-btn--wrapper {
            grid-column: 2/7;
            grid-row: 2/2;

            @include md {
                grid-column: 1/7;
            }

            @include sm {
                grid-column: 1/-1;
                grid-row: 4/4;

                .btn {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .txt-btn {
                        padding: 1.9rem 2.7rem;
                    }
                }
            }
        }

        &-desc {
            grid-column: 9/13;
            margin-top: 1.4rem;
            align-self: start;

            @include md {
                grid-column: 7/-1;
            }

            @include sm {
                grid-column: 1/-1;
                grid-row: 2/2;
            }
        }

        &-step-listing {
            grid-column: 2/16;
            grid-row: 2/2;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: -22.4rem;
            gap: 0 2rem;

            @include md {
                margin-top: 0;
                grid-column: 1/-1;
                gap: 0 2rem;
            }

            @include sm {
                grid-template-columns: repeat(1, 1fr);
                grid-column: 1/-1;
                grid-row: 3/3;
                margin-top: 10rem;
                margin-bottom: 4rem;
                gap: 2.4rem 0;
            }
        }

        &-step {
            position: relative;
            border-radius: 2rem;
            @include transform-origin(left top);

            * {
                @include transform-origin(left top);
            }

            &:nth-child(2n) .home-process-step-background {
                @include transform-origin(right top);

                * {
                    @include transform-origin(right top);
                }
            }

            &-background {
                position: absolute;
                @include size(100%);
                top: 0;
                left: 0;
                pointer-events: none;
                background-color: rgba($cl-white, .05);
                border-radius: 1.5rem;
                @include transform-origin(left top);

                &::before {
                    content: '';
                    position: absolute;
                    @include size(100%);
                    top: 0;
                    left: 0;
                    pointer-events: none;
                    @include border-gradient();
                }
            }

            &::after {
                content: '';
                width: 0;
                height: 100%;
                padding-bottom: 91.67%;
                display: block;
            }

            &:nth-child(1),
            &:nth-child(3) {
                grid-column: 2/3;
            }

            &:nth-child(3) {
                grid-row: 3/4;
            }

            @include sm {
                grid-column: 1/-1 !important;
            }

            &-content {
                position: absolute;
                padding: 8rem 6rem;
                @include size(100%);
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include md {
                    padding: 3.2rem 2.4rem;
                }

                @include sm {
                    padding: 3.2rem 2.4rem;
                }
            }

            &-img {
                position: absolute;
                top: -6.9rem;
                right: 4rem;
                width: 40rem;
                display: flex;
                align-items: center;

                &--wrapper {
                    position: relative;
                }

                @include md {
                    top: -5.2rem;
                    right: 0rem;
                    width: 16.8rem;
                }

                img.cloner {
                    --gap-horizontal: 20px;
                    --gap-vertical: 2px;
                    --time-anim: 2.25s;
                    --blend-mode-1: none;
                    --blend-mode-2: none;
                    --blend-mode-3: none;
                    --blend-mode-4: none;
                    --blend-mode-5: none;
                    --blend-color-1: transparent;
                    --blend-color-2: transparent;
                    --blend-color-3: transparent;
                    --blend-color-4: transparent;
                    --blend-color-5: transparent;

                    @include animation(glitch-anim-1 var(--time-anim) infinite linear alternate);
                    position: absolute;
                    transform-origin: center;

                    &:nth-child(2) {
                        animation-name: glitch-anim-1;
                        animation-delay: calc(var(--time-anim) + 4s);
                        animation-duration: calc(var(--time-anim) + 5s);
                    }

                    &:nth-child(3) {
                        animation-name: glitch-anim-2;
                        animation-delay: calc(var(--time-anim));
                    }

                    &:nth-child(4) {
                        animation-name: glitch-anim-3;
                        animation-duration: calc(var(--time-anim) + 2s);
                        animation-delay: calc(var(--time-anim) + 5s);
                    }

                    &:nth-child(5) {
                        animation-delay: calc(var(--time-anim) + 1s);
                        animation-name: glitch-anim-flash;
                    }
                }

            }

            &-title {
                margin-bottom: 3.2rem;
            }
        }
    }

    &-portfolio {
        background-color: rgba($cl-primary, 1);
        background: linear-gradient(to bottom, $cl-primary-li 0%, $cl-primary 30%, $cl-primary 100%);
        position: relative;
        display: grid;

        &-project {
            display: none;
            grid-column: 1/-1;
            grid-row: 1/1;
            overflow: hidden;
            padding-bottom: calc(10rem + (12rem * .9) + 2rem + (2 * 2rem * 1.4) + 3.4rem + (1.6rem * 1.4) + 2rem + 3.2rem + 16rem + 20vh);
            $grid-items: (
                (row: 1, column: 4),
                (row: 1, column: 1),
                (row: 2, column: 8),
                (row: 2, column: 5),
                (row: 3, column: 3),
                (row: 3, column: 1),
                (row: 4, column: 2),
                (row: 4, column: 5),
                (row: 5, column: 6),
                (row: 5, column: 8),
                (row: 6, column: 2),
                (row: 7, column: 3),
                (row: 8, column: 7),
                (row: 8, column: 2),
                (row: 9, column: 1),
                (row: 9, column: 6),
                (row: 10, column: 4),
                (row: 11, column: 2),
                (row: 11, column: 5),
                (row: 12, column: 6),
                (row: 13, column: 3),
                (row: 14, column: 5),
                (row: 15, column: 1),
                (row: 16, column: 2),
                (row: 16, column: 7),
                (row: 17, column: 8),
                (row: 18, column: 3),
                (row: 19, column: 5),
                (row: 20, column: 4),
                (row: 21, column: 2),
                (row: 22, column: 7),
                (row: 23, column: 1),
                (row: 24, column: 5),
                (row: 25, column: 4),
                (row: 26, column: 2),
                (row: 27, column: 3),
                (row: 28, column: 6),
                (row: 29, column: 5),
                (row: 30, column: 4),
                (row: 31, column: 1),
                (row: 32, column: 6),
                (row: 33, column: 3),
                (row: 34, column: 5),
                (row: 35, column: 1),
                (row: 36, column: 8),
                (row: 37, column: 6),
                (row: 38, column: 3),
                (row: 39, column: 5),
                (row: 40, column: 4)
            );

        &-list {
            --grid-portfolio-column: 8;
            --width-column: calc(100vw / var(--grid-portfolio-column));
            padding-top: 100vh;
            display: grid;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            grid-template-rows: var(--width-column);
            grid-template-columns: repeat(var(--grid-portfolio-column), var(--width-column));
            grid-auto-rows: var(--width-column);
            grid-auto-columns: 1fr;

            @include md {
                --grid-portfolio-column: 6;
            }

            @include sm {
                --grid-portfolio-column: 4;
            }
        }

        @for $i from 1 through length($grid-items) {
            &-item:nth-child(#{$i}) {
                $item: nth($grid-items, $i);
                grid-column: #{map-get($item, column)};
                grid-row: #{map-get($item, row)};
                will-change: transform;
                overflow: hidden;

                img {
                    background-size: cover;
                    background-position: 50% 50%;
                }
            }
        }
    }

    &-content {
        grid-column: 1/-1;
        grid-row: 1/1;
        color: $cl-heading-dark;

        .home-portfolio-content-stickywrap {
            position: sticky;
            top: 0;
            height: 100vh;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
        }

        &-intro {
            position: sticky;
            top: 0;
            padding-top: calc((100vh - (12rem * .9 + 2rem) - (2 * 2rem * 1.4)) / 2);
            padding-bottom: 18.8rem;
            text-align: center;

            @include md {
                padding-top: calc((100vh - (7.2rem * 1 + 1.6rem) - (3 * 1.8rem * 1)) / 2);
                padding-bottom: 18.4rem;
            }

            .home-portfolio-content-title {
                margin-bottom: 2rem;
                display: grid;

                @include md {
                    margin-bottom: 1.6rem;
                }

                .h0 {
                    grid-row: 1/-1;
                    grid-column: 1/-1;
                    color: $cl-heading-dark;
                    font-weight: bold;
                    opacity: 0;
                    @include transition(opacity .5s ease-in);
                }

                .h0.active {
                    opacity: 1;
                    @include transition(opacity .5s ease-in .2s);
                }
            }

            .home-portfolio-content-desc {
                position: relative;
                max-width: 65rem;
                width: 100%;
                margin: 0 auto;
                display: grid;

                @include md {
                    max-width: 41.7rem;
                    --fs-20: 1.8rem;
                    --lh-20: 1em;
                    max-width: 45.7rem;
                }

                @include sm {
                    max-width: 31.7rem;
                }

                p {
                    grid-row: 1/-1;
                    grid-column: 1/-1;
                    max-width:53rem;
                }

                p:nth-child(n + 2) {
                    opacity: 0;
                }
            }
        }

        &-award {
            margin-bottom: 8rem;
            text-align: center;

            @include md {
                margin-bottom: 4.95rem;
            }

            .txt {
                margin-bottom: 2rem;
                color: rgba($cl-sub, 1);
            }

            &-list {
                display: flex;
                align-items: center;
                gap: 4.1rem;

                @include md {
                    gap: 2.4rem;
                }
            }

            &-logo {
                height: 3.2rem;
                width: auto;

                @include md {
                    height: 1.6rem;
                }
            }
        }
    }
    }

    &-project {
        grid-row: 2/2;
        position: relative;
        padding-top: 8rem;
        padding-bottom: 10rem;
        display: grid;

        @include md {
            padding-bottom: 5.5rem;
        }

        @include sm {
            padding-bottom: 1rem;
            padding-top: 10rem;
        }

        &-wrap {
            grid-row: 1/1;
            grid-column: 1/-1;
            color: rgba($cl-black, 1);
            background-color: rgba($cl-primary, 1);
        }

        &-wrap-top {
            pointer-events: none;
            color: rgba($cl-white, 1);
            background-color: $cl-dark;
            @include transition(clip-path .4s ease-out);
            z-index: 2;

            .home-project-item-industry,
            .home-project-item-name,
            .home-project-item-view {
                color: rgba($cl-white, 1);
            }

            .home-project-item-view {
                img {
                    filter: invert(0);
                }
            }
        }

        &-item {
            position: relative;
            display: flex;
            padding: 6rem 0;
            align-items: flex-end;
            justify-content: space-between;
            @include transition(color .3s ease-out);
            cursor: pointer;
            overflow: hidden;

            @include md {
                padding: 4rem 0;
            }

            @include sm {
                padding: 2.4rem 0;
            }

            .hover-line {
                pointer-events: auto;
            }

            // @media (hover: hover) {
            //     .hover-line:hover::before {
            //         @include transform(translateX(calc(100% + var(--line-spacing))) rotate(.01deg));
            //         @include transition(transform .65s cubic-bezier(.6,0,.3,1));
            //     }
            //     .hover-line:hover::after {
            //         @include transform(translateX(var(--line-spacing)) rotate(.01deg));
            //         @include transition(transform .65s cubic-bezier(.6,0,.3,1) .15s);
            //     }
            // }

            &-industry {
                grid-column: 2/-1;
                color: rgba($cl-sub, 1);

                @include md {
                    grid-column: 1/-1;
                }
            }

            &-name {
                grid-column: 2/12;
                margin-top: 2rem;
                color: rgba($cl-black, 1);
                @include transition(color .3s ease-out);

                @include md {
                    grid-column: 1/7;
                    margin-top: .8rem;
                }

                @include sm {
                    grid-column: 1/4;
                    --fs-h2: 3.2rem;
                    --lh-h2: 1.1em;
                }
            }

            &-view {
                grid-column: 15/16;
                align-self: end;
                text-align: end;
                white-space: nowrap;
                color: rgba($cl-sub, 1);

                @include md {
                    grid-column: 8/13;
                    --fs-18: 1.6rem;
                    --lh-18: 1em;
                }

                @include sm {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    height: 4.8rem;
                    padding: 1.2rem;
                    margin-right: -1.2rem;
                    margin-bottom: -1.2rem;
                    align-self: end;
                    justify-self: end;
                }

                p {
                    @include sm {
                        display: none;
                    }
                }

                img {
                    display: none;
                    height: 100%;
                    width: auto;
                    filter: invert(1);

                    @include sm {
                        display: inline;
                    }
                }
            }

            .line {
                width: 200%;
                position: absolute;
                top: 0;
                left: 50%;
                @include transform(translateX(-50%));
                z-index: 999;
                background-color: rgba($cl-black, .2);

                &:nth-child(2n) {
                    top: auto;
                    bottom: 0;
                }
            }
        }

        &-thumb {
            position: absolute;
            top: 0;
            left: 80rem;
            width: 47.12rem;
            height: 28.28rem;
            z-index: 9999999;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            pointer-events: none;

            @include md {
                width: 22.8rem;
                height: 12.84rem;
                left: 36.2rem;
            }

            @include sm {
                width: 11.3rem;
                height: 6.1rem;
                left: 21rem;
            }

            &-wrap {
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                @include transition(opacity .5s ease-out);

                &.active {
                    opacity: 1;
                }
            }
        }
    }

    &-curtain {
        --block-height: 70rem;
        height: var(--block-height);
        background-color: rgba($cl-primary, 1);
        position: relative;
        z-index: 3;
        margin-top: -2px;

        @include md {
            --block-height: 55rem;
        }

        @include sm {
            --block-height: 40rem;
        }

        &-inner {
            width: 100%;
            height: calc(var(--block-height)/10 + 2px);
            margin-bottom: -2px;
            background-color: rgba($cl-bg, 1);
            @include transform(scaleY(0));
            @include transform-origin(center top);
        }
    }

    &-benefit {
        margin-bottom: 8.6rem;
        background-color: rgba($cl-bg, 1);
        height: 400vh;
        position: relative;
        z-index: 4;
        margin-top: -10vh;
        // display: none;

        @include sm {
            height: auto;
            margin-bottom: 8.2rem;
        }

        &-stick {
            position: sticky;
            top: var(--height-header);
            height: calc(100vh - var(--height-header));

            @include md {
                height: 60vh;
                top: calc((40vh - var(--height-header))/2 + var(--height-header));
            }

            @include sm {
                position: static;
                top: auto;
                height: auto;
            }
        }

        .container {
            height: 100%;
        }

        &--wrap {
            --border-radius: 2rem;

            position: relative;
            border-radius: 2rem;
            height: 100%;
            // height: calc(100% - 6rem);

            &::before {
                content: '';
                position: absolute;
                inset: -1px;
                @include size(100%);
                @include border-gradient();

                @include sm {
                    content: none;
                }
            }
        }

        &-list {
            display: flex;
            justify-content: start;
            height: 100%;
            position: relative;
            // padding-bottom: 12rem;
            border-radius: 2rem;
            overflow: hidden;

            @include sm {
                overflow: visible;
                flex-direction: column;
                border-radius: 0;
                padding-bottom: 0;
            }
        }

        &-item {
            position: relative;
            z-index: 3;
            padding-top: 4.2rem;
            background-color: $cl-bg;

            @include md {
                padding-top: 3.2rem;
                padding-left: 2.4rem;
            }

            &-overlay {
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                @include size(calc(100% - 12rem), 100%);
                background: linear-gradient(270deg, $cl-bg 11.76%, rgba($cl-bg, 0) 95.72%);

                @include sm {
                    display: none;
                }
            }
        }

        &-main {
            width: 38.6vw;
            padding-left: 4rem;
            z-index: 1;
            flex: none;

            @include md {
                width: 45.6vw;
            }

            @include sm {
                width: 100%;
                padding-top: 0rem;
                padding-left: 0rem;
                padding-right: 0rem;
                // position: sticky;
                // top: 3.7rem;
                margin-bottom: 5.4rem;
            }
            &-title {
                @include sm {
                    &>br {
                        display: none;
                    }
                }
            }
            &-btn {
                transform-origin: left top;
                width: fit-content;
            }

            &-sub {
                margin-top: 6.4rem;
                color: rgba($cl-white,.8);
                max-width: 29.1rem;
                margin-bottom: 3.2rem;
                transform-origin: left top;
                // Update mb
                @include sm {
                    margin-top: 2.4rem;
                    font-weight: 400;
                }
            }
        }

        &-other-wrap {
            $benefitItem: 6;

            position: relative;
            z-index: 2;
            display: grid;
            grid-template-columns: repeat($benefitItem, 1fr);

            @include sm {
                display: flex;
                flex-direction: column;
                row-gap: 1.6rem;
            }
        }

        &-other {
            --border-radius: 0rem;

            display: flex;
            flex-direction: column;
            /* justify-content: space-between; */
            padding-inline: 6rem;
            width: 25.38vw;
            border-width: 1px;
            border-style: solid;
            border-image: linear-gradient(180deg, rgba($cl-heading, 0.2) 0%, rgba($cl-heading, 0) 100%) 1 100%;
            border-right: 0;
            border-top: 0;
            border-bottom: 0;

            @include md {
                width: 35vw;
                padding-inline: 2.4rem 1.2rem;
            }

            @include sm {
                // width: calc(100% - 2px);
                width: 100%;
                border: none;
                border-radius: 2rem;
                padding: 3.2rem 2.4rem;
                position: relative;
                // top: calc(3.7rem + 3.6rem * 1.1 * 3 + 2.4rem + 1.6rem * 1.4 * 2 + 5.4rem);
                // top: 13.6rem;

                &::before {
                    pointer-events: none;
                    content: '';
                    position: absolute;
                    inset: 0px;
                    @include size(100%);
                    @include border-gradient();


                    // background: linear-gradient(#fff3 100%, #fff0 100%) border-box;
                }

                // &:nth-child(1) {
                //     top: calc(3.7rem + 3.6rem * 1.1 * 3 + 2.4rem + 1.6rem * 1.4 * 2 + 5.4rem - 1px);
                //     top: calc(13.6rem - 1px);
                // }
                // &:nth-child(n+2) {
                //     border-top: 1px solid rgba($cl-heading, 0.2);
                //     border-radius: 1.5rem;
                //     box-shadow: inset 0 1px .2rem rgba($cl-heading, 0.2);
                //     &::before {
                //         content: none
                //     }
                //     width: calc(100% - 2px);
                //     @include transform(translateX(1px));
                // }
            }

            &:last-child {
                width: 26vw;
                @include sm {
                    width: 100%;
                }
            }

            &-img {
                @include size(21rem, auto);
                margin-block: 3.2rem;
                margin-inline: 0;
                /* flex: 1; */
                opacity: 1;
                position: relative;
                z-index: 3;
                will-change: transform, opacity;
                transform-origin: left top;
                // @include transition(opacity .8s ease-out,
                //     transform 2.333s var(--spring-easing-1));

                img {
                    object-fit: contain;
                    height: 100%;
                    width: 90%;
                }

                &.hidden {
                    opacity: 0 !important;
                }

                @include md {
                    opacity: 1;
                    @include size(16rem, auto);
                }
            }
            &-title-wrap {

            }
            &-label {
                margin-bottom: .4rem;
                color: rgba($cl-white, .8);
            }
            &-sub {
                position: relative;
                z-index: 1;

                &-txt {
                    margin-bottom: 3.2rem;
                    max-width: 31rem;

                    @include md {
                        --fs-20: 1.4rem;
                        --lh-20: 1.3em;
                    }
                }

                &-btn {
                    padding: 1.6rem;
                    display: flex;
                    align-items: center;
                    position: relative;
                    justify-content: center;
                    border-radius: 50%;

                    &.hidden {
                        opacity: 0;
                        pointer-events: none;
                        display: none;
                    }

                    @include md {
                        padding: 1rem;
                    }

                    span {
                        position: absolute;
                        @include size(100%);
                        pointer-events: none;
                        @include border-gradient($border-line-3);
                    }

                    img {
                        @include md {
                            @include size(2rem);
                        }
                    }
                }
            }
            // &:hover,
            // &.active {
            //     .home-benefit-other-img {
            //         opacity: 1;
            //         @include transform(scale(1));
            //     }
            // }
        }
    }

    &-testi {
        position: relative;
        z-index: 5;
        padding-bottom: calc(21rem - var(--height-header));
        margin-top: -70vh;

        @include md {
            padding-bottom: 0;
            margin-top: -120vh;
        }

        @include sm {
            margin-top: unset;
            overflow: hidden;
            padding-bottom: calc(12rem - var(--height-header));
        }

        &-sticky {
            position: sticky;
            // top: var(--height-header);
            // height: calc(100vh - var(--height-header));
            top: 0;
            height: 100vh;
            padding-top: var(--height-header);
            padding-bottom: 4rem;
            // overflow: hidden;

            .grid {
                height: 100%;
            }

            @include md {
                padding-bottom: 0;
            }

            @include sm {
                position: static;
                top: auto;
                height: auto;
                padding-top: 0;
            }
        }

        &-text-wrap {
            grid-column: 2/7;
            display: flex;
            flex-direction: column;
            @include transform(translateY(-10rem));
            margin-top: auto;
            margin-bottom: auto;


            @include md {
                grid-column: 1/6;
            }

            @include sm {
                grid-column: 1/-1;
                @include transform(translateY(0));
                margin-bottom: 6rem;
                align-items: center;
                text-align: center;
            }
        }

        &-heading {
            margin-bottom: 6rem;
            max-width: 46rem;
            @include md {
                max-width: 25rem;
                margin-bottom: 3.2rem;
            }

            @include sm {
                --fs-h1: 5rem;
                --lh-h1: 1em;
                max-width: 46rem;
            }
        }

        &-content {
            grid-column: 8/17;
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            gap: 0 2rem;

            @include md {
                grid-column: 7/-1;
                grid-template-columns: repeat(1, 1fr);
            }

            @include sm {
                padding-inline: 2rem;
                grid-column: 1/-1;
                display: block;
                // margin-left: calc(-1 * var(--container-padding));
                // margin-right: calc(-1 * var(--container-padding));
                // padding-right: 0;
            }

            &-wrap {
                grid-column: span 8;

                @include md {
                    grid-column: span 1;
                }

                @include sm {
                    // margin-left: calc(-1 * var(--container-padding));
                    // padding-left: var(--container-padding);
                }
            }

            &-listing {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                perspective: 5000px;
                pointer-events: none;

                @include sm {
                    align-items: start;
                }
            }

            &-item {
                --border-radius: 2rem;

                // opacity: 0;
                // z-index: calc(var(--i) * -1);
                pointer-events: auto;
                position: absolute;
                padding: 10rem;
                border-radius: 2rem;
                background: linear-gradient(180deg, #121212 0%, #080808 100%);
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                @include transform-origin(top);
                max-height: 74.2rem;
                height: calc(100vh - var(--height-header) - 4rem);


                @include md {
                    padding: 3.2rem;
                    height: 46.4rem;
                }

                @include sm {
                    position: relative;
                    height: 55rem;
                    max-height: 60rem;
                    flex: none;
                    border-radius: 1rem;
                    transform-origin: center center;
                    @include transition(all .5s ease-out);

                    &.swiper-slide:has(+ .swiper-slide.swiper-slide-active) {
                        // transform: translateX(.2rem) scale(.95);
                        @include transform(translateX(1rem) scale(.95))
                    }

                    &.swiper-slide.swiper-slide-active+.swiper-slide {
                        // transform: translateX(-.2rem) scale(.95);
                        @include transform(translateX(-1rem) scale(.95))
                    }

                    &.swiper-slide-active {
                        @include transform(scale(1) translateX(0));
                    }
                }

                &.active {
                    opacity: 1;
                    pointer-events: auto;
                }

                &-fg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 4;
                    background: linear-gradient(to top, rgba($cl-bg, 1) .25, rgba($cl-bg, 0) .5);
                    background-size: 100% 200%;
                    background-position: 0px 0%;
                    @include size(100%);
                }

                &:before {
                    content: '';
                    position: absolute;
                    pointer-events: none;
                    inset: 0;
                    @include border-gradient(linear-gradient(180deg, rgba($cl-white, 0.1) 0%, rgba($cl-white, 0.00) 100%));
                }

                &-txt {
                    margin-bottom: 4rem;

                    @include md {
                        margin-bottom: 2.44rem;
                        --fs-h6: 2rem;
                        --lh-h6: 1.2em;
                    }

                    @include sm {
                        --fs-h6: 1.8rem;
                        --lh-h6: 1.4em;
                        margin-bottom: 2.4rem;
                    }
                }

                &-author {
                    margin-top: 4.3rem;
                    display: flex;
                    align-items: center;

                    @include md {
                        margin-top: 3.2rem;
                    }

                    @include sm {
                        margin-top: 2.4rem;
                    }

                    &-avt {
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 2rem;
                        @include size(8rem);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: none;

                        @include md {
                            @include size(4.8rem);
                        }

                        .img {
                            object-fit: cover;
                            object-position: 50% 50%;
                        }
                    }

                    &-info {
                        display: flex;
                        flex-direction: column;

                        &-name {
                            margin-bottom: .4rem;

                            @include md {
                                --fs-h6: 2rem;
                                --lh-h6: 1.2em;
                            }
                        }

                        &-role {

                            @include md {
                                --fs-18: 1.4rem;
                                --lh-18: 1.3em;
                            }
                        }
                    }
                }
            }

            &-progress {
                position: absolute;
                top: calc(50vh - var(--height-header));
                right: 2.4rem;
                align-self: center;
                justify-self: end;
                @include size(.5rem, 20rem);
                background-color: rgba($cl-white, 0.10);
                @include transform(translateY(-50%));
                border-radius: 100vmax;

                @include sm {
                    grid-column: 1/-1;
                    position: relative;
                    top: auto;
                    right: auto;
                    margin: auto;
                    margin-top: 2.4rem;
                    @include size(20rem, .5rem);
                }

                &-inner {
                    background-color: $cl-primary;
                    border-radius: 100vmax;
                    height: 4rem;

                    @include sm {
                        display: none;
                    }
                }

                .swiper-scrollbar-drag {
                    height: 100%;
                    background-color: $cl-primary;
                    border-radius: 100vmax;
                    @include size(4rem, 100%);
                }
            }
        }
    }

    &-pricing {
        // margin-top: calc(var(--height-header) * -1);
        padding-top: var(--height-header);
        // padding-bottom: calc(23rem - var(--height-header));
        padding-bottom: 15rem;
        position: relative;
        z-index: 5;
        background-color: $cl-bg;

        @include md {
        padding-top: 0;
            padding-bottom: calc(15.6rem - var(--height-header));
        }

        @include sm {
            padding-top: var(--height-header);
            // padding-bottom: calc(6.8rem- var(--height-header));
            margin-top: unset;
        }

        &--wrap {
            grid-column: 2/16;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 8rem;

            @include md {
                padding-top: 6rem;
                grid-column: 1/-1;
            }
        }

        &-text {
            grid-column: 2/16;
            display: flex;
            text-align: center;
            flex-direction: column;
            align-items: center;

            @include md {
                grid-column: 1/-1;
                padding: 0px;
            }

            &-title {
                margin-bottom: 3.2rem;
                max-width: 125.3rem;

                @include md {
                    max-width: 62rem;
                }
            }

            &-sub-wrap {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                column-gap: 3.2rem;
                @include md {
                    row-gap: .8rem;
                    max-width: 45rem;
                    column-gap: 2.4rem;
                }
            }
            &-sub-item {
                display: flex;
                align-items: center;
                column-gap: .8rem;
                .dot {
                    flex-shrink: 0;
                    @include size(1rem);
                    border-radius: 50%;
                    background-color: $cl-primary;
                }
                &-txt {
                    color: rgba($cl-white, .6);
                }
            }
        }

        &-plan {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            @include sm {
                width: 100%;
            }

            &-switch {
                --border-radius: 2rem;

                position: relative;
                display: flex;
                padding: 1rem;
                margin-bottom: 8rem;
                border-radius: 2rem;
                background-color: rgba($cl-white, 0.01);
                box-shadow: 0rem 0rem 1rem 0rem rgba($cl-white, 0.05) inset;

                @include md {
                    margin-bottom: 7.1rem;
                }

                @include sm {
                    width: 100%;
                    margin-bottom: 4rem;
                }

                &:before {
                    --border-width: 1px;
                    content: '';
                    position: absolute;
                    @include border-gradient(linear-gradient(180deg, rgba($cl-white, 0.20) 0%, rgba($cl-white, 0.00) 100%));
                    top: calc(var(--border-width) / 1.5* -1);
                    bottom: calc(var(--border-width) / 2.5* -1);
                    left: calc(var(--border-width) / 1.5* -1);
                    right: calc(var(--border-width) / 2.5* -1);
                }

                &-wrap {
                    --btn-width: 12.6rem;
                    position: relative;

                    @include md {
                        --btn-width: 10.7rem;
                    }

                    @include sm {
                        width: 100%;
                        --btn-width: calc(100% / 2);
                    }

                    &-btn {
                        display: flex;
                        align-items: center;
                    }
                }

                &-overlay {
                    width: var(--btn-width);
                    position: absolute;
                    height: 100%;
                    top: 0;
                    left: 0;
                    display: grid;
                    grid-template-columns: 1fr;
                    pointer-events: none;
                    will-change: transform, opacity;
                    -webkit-backface-visibility: visible;
                    backface-visibility: visible;
                    z-index: 3;
                    @include transition(transform .533s var(--spring-easing-3), opacity .5s ease-in-out);
                    pointer-events: none;

                    @include sm {
                        width: calc(100% / 2);
                    }

                    &:first-of-type {
                        mix-blend-mode: hard-light;
                        opacity: 0;
                    }

                    &:last-of-type {
                        mix-blend-mode: lighten;
                    }

                    .btn {
                        border-radius: var(--border-radius);

                        @include md {
                            --border-radius: 1rem;
                        }
                    }
                }

                &-btn {
                    color: $cl-heading;
                    width: var(--btn-width);
                    padding: 1.1rem 0;
                    cursor: pointer;
                    position: relative;
                    z-index: 1;

                    &.active {
                        color: $cl-primary;
                    }

                    @include md {
                        --fs-16: 1.4rem;
                        --lh-16: 1.3em;
                        width: var(--btn-width);
                    }
                }
            }
            &-wrapper {
                width: 100%;
                max-width: 124rem;
            }
            &-listing {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 7rem 1fr;
                gap: 0 2rem;
                margin-bottom: 8rem;

                @include md {
                    grid-template-rows: 6.8rem 1fr;
                    gap: 0 1.6rem;
                    margin-bottom: 6rem;
                }

                @include sm {
                    display: flex;
                    flex-direction: column;
                    gap: 2.4rem;
                    padding: 0px 2.15rem;
                    margin-bottom: 0;
                }
            }

            &-item {
                --border-radius: 2rem;

                grid-row: 2/3;
                padding: 4rem 4rem 5rem;
                position: relative;
                display: flex;
                flex-direction: column;
                box-shadow: inset 0 0 1rem 0 rgba($cl-white, 0.05);
                border-radius: 2rem;
                background-color: #0e0e0e;
                // background-color: rgba($cl-white, 0.05);

                @include md {
                    padding: 3.2rem 1.6rem;
                }

                @include sm {
                    padding: 3.2rem 2.4rem;
                    background-color: rgba($cl-white, .001);
                    box-shadow: inset 0 0 1.5rem 0 rgba($cl-white, 0.1);
                }

                // & * {
                //     z-index: 20;
                // }
                &:before {
                    content: '';
                    position: absolute;
                    inset: 0;
                    @include border-gradient();
                    pointer-events: none;
                    z-index: 20;
                }
                &-bg {
                    background-color: rgba($cl-white, 0.05);
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include size(100%);
                    border-radius: var(--border-radius);
                    z-index: 15;
                    backdrop-filter: blur(5rem);
                    pointer-events: none;
                    display: none;
                }
                &.popular {
                    grid-row: 1/3;
                    background: linear-gradient(180deg, #F5370D 0%, #F5430D 34%, $cl-primary-li 100%);
                    box-shadow: 0rem 0rem 1.5rem 0rem rgba($cl-white, 0.1) inset;
                    color: rgba($cl-heading, 1);

                    .home-pricing-plan-item-bg {
                        background: linear-gradient(180deg, #F5370D 0%, #F5430D 34%, $cl-primary-li 100%);
                        backdrop-filter: unset;
                    }
                    &::before {
                        pointer-events: none;
                        @include border-gradient(linear-gradient(180deg, rgba($cl-primary-hl, 1) 0%, rgba($cl-primary-hl, 0) 100%), 2rem);
                    }
                    .home-pricing-plan-item-time {
                        color: rgba($cl-white, .8);
                    }
                    .home-pricing-plan-item-detail-txt .img {
                        filter: brightness(0) saturate(100%) invert(99%) sepia(10%) saturate(7%) hue-rotate(132deg) brightness(103%) contrast(100%);
                    }
                }

                &-popularTag {
                    border: 1px solid rgba($cl-white, .1);
                    border-radius: 5rem;
                    padding: 1.2rem 2rem;
                    margin: 0 auto 2rem;
                    color: $cl-heading;
                    background: rgba($cl-white, 0.05);

                    @include md {
                        // margin-bottom: unset;
                    }

                    @include sm {
                        padding: 1rem 2rem;
                    }
                }

                &-label {
                    margin-bottom: 2rem;

                    @include md {
                        margin-bottom: 1.2rem;
                    }
                }
                &-time {
                    margin-bottom: 2rem;

                    @include md {
                        margin-bottom: 1.2rem;
                    }
                    @include sm {
                        @include text(12);
                    }
                }
                &-off {
                    margin-bottom: .8rem;
                    text-decoration: line-through;
                    color: rgba($cl-white, .6);

                    @include sm {
                        @include text(14);
                    }
                }

                &-price {
                    margin-bottom: 2rem;
                    display: flex;
                    align-items: end;
                    justify-content: center;

                    @include md {
                        margin-bottom: 1.2rem;
                    }

                    &-inner {
                        display: flex;
                        gap: 0 .4rem;
                    }

                    &-txt {
                        .h4 {
                            position: absolute;
                            opacity: 0;
                            pointer-events: none;

                            @include sm {
                                --fs-h4: 3.6rem;
                                --lh-h4: 1.1em;
                            }
                        }

                        .active {
                            position: relative;
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                    &-unit {
                        margin-top: .4rem;
                        color: $cl-white;
                    }
                    &-periodic {
                        margin-bottom: .4rem;
                        margin-left: .4rem;
                        color: rgba($cl-white, .6);

                        p {
                            // position: absolute;
                            // opacity: 0;
                            // pointer-events: none;

                            &.active {
                                // position: relative;
                                // opacity: 1;
                                // pointer-events: auto;
                            }
                        }
                    }
                }

                &-desc {
                    margin-bottom: 2rem;
                    max-width: 26rem;
                    margin-inline: auto;
                    text-align: center;
                    color: rgba($cl-white, 1);
                    opacity: .8;

                    @include md {
                        @include text(12);
                    }

                    @include sm {
                        margin-bottom: 1.6rem;
                        color: rgba($cl-white, 1);
                    }
                }

                &-detail {
                    display: flex;
                    flex-direction: column;
                    gap: 1.2rem 0;
                    margin-top: 3.2rem;
                    margin-bottom: 3.6rem;
                    text-align: left;

                    @include md {
                        margin-top: 2.8rem;
                        row-gap: .8rem;
                        margin-inline: -0.2rem;
                    }
                    @include sm {
                        margin-bottom: 3.6rem;
                    }

                    &-txt {
                        display: flex;
                        align-items: start;
                        gap: 0 .8rem;
                        color: $cl-white;

                        @include md {
                            --fs-18: 1.2rem;
                            --lh-18: 1.4em;
                        }

                        @include sm {
                            gap: 0 1rem;
                            --fs-18: 1.4rem;
                            --lh-18: 1.3em;
                        }

                        img {
                            flex: none;
                            width: 2rem;
                            margin-top: 0.4rem;

                            @include md {
                                width: 1.6rem;
                                margin-top: 0.1rem;
                            }
                        }
                    }
                }

                &-btn {
                    position: relative;
                    margin-top: auto;

                    .txt-btn {
                        @include md {
                            padding-top: 1.4rem;
                            padding-bottom: 1.4rem;
                            --fs-18: 1.6rem;
                            --lh-18: 1.4em;
                        }
                    }
                }
            }

            &-booking {
                text-align: center;
                margin: 0 auto;
                margin-top: 2.4rem;

                @include md {
                    margin-top: 1.2rem;
                    --fs-16: 1.4rem;
                    --lh-16: 1.3em;
                    font-weight: 500;
                }
            }

            &-cta {
                padding: 4rem 0;
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;

                @include sm {
                    padding-top: 6rem;
                    align-items: stretch;
                }
                &-btn{
                    display: flex;
                    gap: 2rem;
                    flex-direction: column;
                    align-items: center;

                    a {
                        color: #fff;
                    }

                    .btn-border{
                        @include sm {
                            width: 100%;
                        }
                    }
                }
                &-heading {
                    max-width: 66rem;
                    margin-bottom: 2rem;

                    @include md {
                        max-width: 57.3rem;
                    }

                    @include sm {
                        --lh-h6: 1.2em;
                        max-width: 32rem;
                    }
                }

                &-desc {
                    max-width: 81.5rem;
                    margin-bottom: 4rem;

                    @include md {
                        max-width: 57.3rem;
                        margin-bottom: 3.2rem;
                    }

                    @include sm {
                        margin-bottom: 4rem;
                    }
                }

                .btn {
                    @include sm {
                        @include size(100%, auto);
                    }
                }

                .txt-btn {
                    padding: 2.4rem 10.4rem;

                    @include md {
                        padding: 1.4rem 6.05rem;
                    }

                    @include sm {
                        --fs-18: 1.6rem;
                        --lh-18: 1.4em;
                    }
                }
            }
        }
    }

    &-explore {
        display: none;
        padding-top: var(--height-header);
        padding-bottom: calc(20rem - var(--height-header));

        @include md {
            padding-bottom: calc(14rem - var(--height-header));
        }

        @include sm {
            padding-bottom: calc(9.5rem - var(--height-header));
        }

        &-heading {
            grid-area: 1/1/2/17;
            max-width: 144rem;
            text-align: center;
            margin: 0 auto;

            @include md {
                grid-column: 2/12;
                --fs-h3: 3.6rem;
                --lh-h3: 1.1em;
            }

            @include sm {
                grid-column: 1/-1;
                --fs-h3: 3.2rem;
            }
        }

        &-img {
            grid-area: 1/2/2/16;
            width: 50.8rem;
            justify-self: center;
            margin-top: 16.3rem;

            @include md {
                grid-column: 2/12;
                margin-top: 10.1rem;
                width: 31.47rem;
            }

            @include sm {
                grid-column: 1/-1;
                width: 19.8rem;
                display: flex;
                align-items: center;
                margin-top: 0;
            }
        }

        &-industries {
            grid-column: 3/15;
            justify-self: center;
            margin-top: 9rem;

            @include md {
                grid-column: 2/12;
                margin-top: 8.2rem;
            }

            @include sm {
                grid-column: 1/-1;
                margin-top: 10.5rem;
            }

            &-title {
                max-width: 44rem;
                text-align: center;
                margin-bottom: 10.7rem;

                @include md {
                    --fs-h6: 2rem;
                    --lh-h6: 1.2em;
                    margin-bottom: 8.1rem;
                    max-width: 28rem;
                }

                @include sm {
                    margin-bottom: 4.318rem;
                    --fs-h6: 1.8rem;
                    --lh-h6: 1.4em;
                }
            }

            &-wrap {
                position: relative;
                grid-column: 1/-1;
                display: flex;
                align-items: center;
                justify-content: center;

                @include sm {
                    margin: 0px -1.6rem;
                }
            }

            &-logo {
                position: absolute;
                top: 0;
                left: 50%;
                @include transform(translate(-50%, -50%));
                z-index: 7;
                background: linear-gradient(180deg, #F5370D 0%, #F5430D 34%, $cl-primary-li 100%);
                @include size(12.5rem);
                display: flex;
                align-items: center;
                justify-content: center;
                filter: drop-shadow(0px .7rem 1.9rem rgba(245, 55, 13, 0.6));
                border-radius: 50%;

                @include md {
                    @include size(9rem);
                }

                @include sm {
                    @include size(4.62rem);
                }

                &-img {
                    width: 44%;
                    margin-top: 10%;
                }
            }

            &-radar {
                $offset-1: 76.05196078%;
                $offset-2: 49.01960784%;
                $offset-3: 20.06470588%;

                position: relative;
                width: 110.6rem;
                aspect-ratio: 2;
                border: 1px solid rgba(245, 55, 13, 0.2);
                border-radius: 0 0 1000rem 1000rem;
                border-top: none;
                overflow: hidden;

                &-wrapper {
                    position: relative;
                    width: 92.22423146%;
                    aspect-ratio: 2;
                    margin: auto;
                    border: 1px solid rgba(245, 55, 13, 0.2);
                    border-radius: 0 0 1000rem 1000rem;
                    border-top: 1px solid rgba(80, 20, 7, 0.5);

                    &-inner {
                        position: relative;
                        @include size(100%);
                        border-radius: 0 0 1000rem 1000rem;
                        overflow: hidden;
                        z-index: 1;

                        &-wave {
                            position: absolute;
                            top: -100%;
                            left: 0;
                            right: 0;
                            border: 1px solid rgba(245, 55, 13, 0.15);
                            background: rgba(1, 1, 1, 0.01);
                            box-shadow: 0px 0px 70px 30px rgba(245, 67, 13, 0.15) inset, 0px 0px 100px 60px rgba(245, 67, 13, 0.05) inset;
                            width: 100%;
                            border-radius: 50%;
                            padding-bottom: 100%;

                            &:nth-child(2) {
                                // transform: scale($offset-1);
                                @include transform(scale($offset-1));
                            }

                            &:nth-child(3) {
                                // transform: scale($offset-2);
                                @include transform(scale($offset-2));
                            }

                            &:nth-child(4) {
                                // transform: scale($offset-3);
                                @include transform(scale($offset-3));
                            }
                        }
                    }

                    &-item {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        @include size(100%);
                        z-index: 6;

                        &-line {
                            position: absolute;
                            width: 50%;
                            height: 1px;
                            background-color: rgba(80, 20, 7, 0.5);
                            display: flex;
                            align-items: center;
                            @include transform-origin(left center);
                            @include transform(translate(100%, -50%) rotate(calc(var(--i) * 30deg)));

                            &-dot {
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: .6rem;
                                border-radius: 50%;
                                aspect-ratio: 1;
                                background-color: rgba($cl-primary, 1);
                                margin-left: $offset-1;
                                translate: -50%;
                                filter: drop-shadow(0px 0px 7px rgba(255, 79, 4, 0.90));
                                rotate: calc(var(--i) * -30deg);

                                @include sm {
                                    width: .2rem;
                                }

                                &-wrap {
                                    width: calc(6.4rem * 1.2);
                                    aspect-ratio: 1;
                                    opacity: 0;
                                    position: absolute;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    @include transition(opacity .5s cubic-bezier(0.12, 0, 0.39, 0));

                                    @include md {
                                        width: 4.35rem;
                                    }

                                    @include sm {
                                        width: 2rem;
                                    }

                                    &.active,
                                    &.on-hover {
                                        opacity: 1;
                                        @include transition(opacity 1s cubic-bezier(0.61, 1, 0.88, 1));
                                    }
                                }

                                &:nth-child(2) {
                                    margin-left: $offset-2;
                                }
                            }

                            &-innerdot {
                                @include keyframes(loopDot) {
                                    0% {
                                        scale: 0;
                                        opacity: 1
                                    }

                                    ;

                                    50% {
                                        scale: .5;
                                        opacity: 1
                                    }

                                    ;

                                    100% {
                                        scale: 1;
                                        opacity: 0
                                    }
                                }

                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(245, 67, 13, 0.1);
                                border-radius: 50%;
                                pointer-events: none;
                                @include animation(loopDot 4s linear infinite);

                                &:nth-child(1) {
                                    animation-delay: -1s;
                                }

                                &:nth-child(2) {
                                    animation-delay: -2s;
                                }

                                &:nth-child(3) {
                                    animation-delay: -3s;
                                }

                                &:nth-child(4) {
                                    animation-delay: -4s;
                                }
                            }

                            &-content {
                                position: absolute;
                                padding: .6rem 1.6rem;
                                translate: 0 -5rem;
                                white-space: nowrap;
                                background: linear-gradient(180deg, rgba($cl-white, 0.15) 0%, rgba($cl-white, 0.03) 100%);
                                border-radius: 100vmax;
                                pointer-events: none;

                                @include md {
                                    translate: 0 -3rem;
                                }

                                @include sm {
                                    padding: .4rem .8rem;
                                    translate: 0 -1.5rem;
                                }

                                &:before {
                                    content: '';
                                    position: absolute;
                                    inset: 0;
                                    @include border-gradient(linear-gradient(180deg, rgba($cl-white, 0.20) 0%, rgba($cl-white, 0.00) 100%), 100vmax);
                                }

                                p {
                                    margin: 0;
                                    line-height: 1;

                                    @include md {
                                        --fs-14: 1.2rem;
                                    }

                                    @include sm {
                                        --fs-14: .8rem;
                                    }
                                }
                            }

                            &:nth-child(2) {
                                .home-explore-industries-radar-wrapper-item-line-content {
                                    @include sm {
                                        translate: 0 -2.5rem;
                                    }
                                }
                            }

                            &:nth-child(4) {
                                .home-explore-industries-radar-wrapper-item-line-content {
                                    @include sm {
                                        translate: 0 -2.3rem;
                                    }
                                }
                            }
                        }
                    }
                }

                &-scan {
                    position: absolute;
                    width: 107.25%;
                    top: -107.25%;
                    left: -3.625%;
                    right: 0;
                    pointer-events: none;
                    z-index: 2;
                }
            }
        }
    }

    &-faq {
        // padding-top: var(--height-header);
        padding-top: 15rem;
        padding-bottom: 25rem;

        @include md {
        padding-top: 10rem;
            padding-bottom: 12rem;
        }

        @include sm {
            padding-bottom: 10rem;
        }

        &-heading {
            grid-column: 4/14;
            text-align: center;
            margin-bottom: 10rem;
            max-width: 100rem;
            margin-inline: auto;

            @include md {
                grid-column: 3/11;
                margin-bottom: 6rem;
            }

            @include sm {
                grid-column: 1/-1;
                margin-bottom: 4.6rem;
            }
        }

        &-content {
            grid-column: 4/14;

            @include md {
                grid-column: 1/-1;
            }

            &-item {
                --widthOrdinal: 13rem;
                // border-bottom: 1px solid rgba($cl-white, 0.10);
                position: relative;

                @include md {
                    --widthOrdinal: 7rem;
                }

                @include sm {
                    --widthOrdinal: 6.2rem;
                }

                &-ques {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding: 4rem 0 4rem;
                    @include md {
                        padding-bottom: 3.2rem;
                    }

                    @include sm {
                        align-items: flex-start;
                        padding: 2.4rem 0;
                    }

                    &-ordinal {
                        color: rgba($cl-white, .2);
                        width: var(--widthOrdinal);
                        flex: none;
                    }

                    &-title {
                        flex-grow: 1;
                        @include transition(color 1.333s var(--spring-easing-2));

                        @include md {
                            --lh-h6: 1.2em;
                        }

                        @include sm {
                            --fs-h6: 2rem;
                        }
                    }

                    &-dot {
                        --border-radius: 100vmax;

                        flex: none;
                        position: relative;
                        margin-left: 3rem;
                        @include size(2rem);
                        border-radius: 100vmax;
                        border: 1.5px solid rgba($cl-white, 0.2);
                        @include transition(border .65s cubic-bezier(.6, 0, .3, 1) .2s);

                        @include md {
                            @include size(1.2rem);
                        }


                        &:before {
                            content: '';
                            position: absolute;
                            inset: -1px;
                            @include transform(translateY(100%));
                            border-radius: 50%;
                            background: rgba($cl-primary, 1);
                            opacity: 0;
                            @include transition(transform .6s ease-in-out, opacity .6s ease-in-out);
                        }
                    }
                }

                &-answer {
                    margin-left: var(--widthOrdinal);
                    padding-bottom: 4rem;
                    margin-top: -1.6rem;
                    display: flex;
                    flex-direction: column;
                    row-gap: 1em;

                    @include md {
                        padding-bottom: 3.2rem;
                    }

                    @include sm {
                        --fs-20: 1.4rem;
                        --lh-20: 1.3em;
                        margin-top: -.8rem;
                    }
                }
                .line {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    @include size(100%, 1.5px);
                    overflow: hidden;

                    &:before,
                    &:after {
                        --line-spacing: 1rem;

                        content: '';
                        position: absolute;
                        display: block;
                        inset: auto 0 0;
                        width: calc(100% - var(--widthOrdinal));
                        height: 1.5px;
                        @include transition(transform .65s cubic-bezier(.6, 0, .3, 1) .15s);

                        @include sm {
                            height: 1px;
                        }
                    }

                    &:before {
                        opacity: 0;
                        left: var(--widthOrdinal);
                        background: linear-gradient(270deg, rgba($cl-primary, 1) 34%, rgba($cl-primary-li, 1) 100%);
                        @include transition(transform .65s cubic-bezier(.6, 0, .3, 1), opacity .65s cubic-bezier(.6, 0, .3, 1) .05s);
                    }

                    &:after {
                        left: calc(-1 * var(--line-spacing));
                        opacity: .8;
                        @include transform(translateX(-100%));
                        background: linear-gradient(90deg, rgba($cl-primary, 1) 34%, rgba($cl-primary-li, 1) 100%);
                        @include transition(transform .65s cubic-bezier(.6, 0, .3, 1) .2s);
                    }
                }

                &.active {
                    .home-faq-content-item-ques-title {
                        color: $cl-primary;
                    }

                    .home-faq-content-item-ques-dot {
                        border-color: rgba($cl-primary, 1);

                        // &:before {
                        //     opacity: 1;
                        //     @include transform(scale(1));
                        // }
                    }

                    .line {
                        &:before {
                            opacity: 1;
                            @include transform(translateX(calc(100% + var(--line-spacing))) rotate(.01deg));
                        }

                        &:after {
                            @include transform(translateX(calc(var(--line-spacing) + var(--widthOrdinal))) rotate(.01deg));
                        }
                    }
                }

                &:hover {
                    .home-faq-content-item-ques-title {
                        color: $cl-primary;
                    }

                    .home-faq-content-item-ques-dot {
                        border: 1.5px solid rgba($cl-primary, 1);
                    }

                    .line {
                        &:before {
                            opacity: 1;
                            @include transform(translateX(calc(100% + var(--line-spacing))) rotate(.01deg));
                        }

                        &:after {
                            @include transform(translateX(calc(var(--line-spacing) + var(--widthOrdinal))) rotate(.01deg));
                        }
                    }
                }
            }
        }
    }
}