@use './mixin' as *;

$cl-bg: #010101;
$cl-white: #ffffff;
$cl-black: #000000;
$cl-primary: #f5450d;
$cl-primary-li: #F58A0D;
$cl-primary-hl: #ff693a;
$cl-red: #ff0000;

$cl-heading: #fff;
$cl-body: #CCCCCC;
$cl-sub: #313131;
$cl-disable: #343434;
$cl-dark: #010101;
$cl-heading-dark: #010101;

$cl-primary-border: #F5450D;
$cl-secondary: #3800AE;
$cl-bg: #010101;
$cl-disable: #DBDBDB;
$cl-border: #343434;
$cl-border-li: #C1C1C1;
$border-line-3: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
.body.dark-mode {
    $cl-heading: #000;

}
:root {
    --spring-easing-1: linear(
        0, 0.002, 0.009 1%, 0.035, 0.078, 0.141 4.4%, 0.281 6.7%, 0.723 12.9%, 0.842,
        0.938, 1.017, 1.077 20.4%, 1.101, 1.121, 1.137, 1.149 24.3%, 1.159, 1.163,
        1.161, 1.154, 1.143, 1.129 32.8%, 1.051 39.6%, 1.017 43.1%, 1.002, 0.991,
        0.982 48.9%, 0.977 51%, 0.974 53.8%, 0.975 57.1%, 0.979 60%, 0.997 69.8%,
        1.001, 1.003 76.9%, 1.004 83.8%, 1
    );
    --spring-easing-2: linear(
        0, 0.009, 0.035 2.1%, 0.141 4.4%, 0.723 12.9%, 0.938, 1.077 20.4%, 1.121,
        1.149 24.3%, 1.163 27%, 1.154 29.9%, 1.017 43.1%, 0.991, 0.977 51%,
        0.975 57.1%, 1.003 76.9%, 1
    );
    --spring-easing-3: linear(
        0, 0.938 16.7%, 1.149 24.3%, 1.154 29.9%, 0.977 51%, 1
    )
}

$cl-bg-beige: #F6F1EE;
$dur-default: 400ms;

@include keyframes(marquee) {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}
@include keyframes(glitch-anim-1) {
    0%, 30% {
        opacity: 1;
        transform: translate3d(var(--gap-horizontal),0,0);
        -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
        clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
    2%, 32% {
        -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
        clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    4%, 34% {
        -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
        clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }
    6%, 36% {
        -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
        clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }
    8%, 38% {
        -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
        clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    10%, 40% {
        -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
        clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    12%, 42% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    14%, 44% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
        clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    16%, 46% {
        -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
        clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    18%, 48% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
        clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    20%, 50% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
        clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    21.9%, 51.9% {
        opacity: 1;
        transform: translate3d(var(--gap-horizontal),0,0);
    }
    22%, 29.9%, 52%, 100% {
        opacity: 0;
        transform: translate3d(0,0,0);
        -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
}
@include keyframes(glitch-anim-2) {
    0%, 50% {
        opacity: 1;
        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
        -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
        clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }
    3%, 53% {
        -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
        clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
    5%, 55% {
        -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
        clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }
    7%, 57% {
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
        clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    9%, 59% {
        -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
        clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }
    11%, 61% {
        -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
        clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }
    13%, 63% {
        -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
        clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    15%, 65% {
        -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
        clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }
    17%, 67% {
        -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
        clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }
    19%, 69% {
        -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
        clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }
    20%, 70% {
        -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
        clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }
    21.9%, 71.9% {
        opacity: 1;
        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
    }
    22%, 49%, 80%, 100% {
        opacity: 0;
        transform: translate3d(0,0,0);
        -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
}
@include keyframes(glitch-anim-3) {
    0%, 80% {
        opacity: 1;
        transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
        -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
        clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }
    1.5%, 81.5% {
        -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
        clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
    2%, 82% {
        -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
        clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
    2.5%, 82.5% {
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
        clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    3%, 83% {
        -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
        clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
    5%, 85% {
        -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
        clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
    5.5%, 85.5% {
        -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
        clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
    7%, 87% {
        -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
        clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }
    8%, 88% {
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
        clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    9%, 89% {
        -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
        clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }
    10.5%, 90.5% {
        -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
        clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }
    11%, 91% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
        clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }
    13%, 93% {
        -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
        clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }
    14%, 94% {
        -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
        clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }
    14.5%, 94.5% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
        clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }
    15%, 95% {
        -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
        clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }
    16%, 96% {
        -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
        clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    18%, 98% {
        -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
        clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }
    20%, 98.5% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
        clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
    21.9%, 99% {
        opacity: 1;
        transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
    }
    22%, 79%, 100% {
        opacity: 0;
        transform: translate3d(0,0,0);
        -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
        clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
}
@include keyframes(glitch-anim-flash) {
    0%, 5% {
        opacity: 0.2;
        transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
    }
    5.5%, 100% {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }
}
@include keyframes(moveWheel) {
    0% {
        top: 33.33%;
    };
    100% {
        top: 55.55%;
    }
}
@include keyframes(noise) {
    0% {
        transform: translateZ(0);
    }
    10% {
        transform: translate3d(-7vw,-7vw,0);
    }
    20% {
        transform: translate3d(-14vw,7vw,0);
    }
    30% {
        transform: translate3d(7vw,-14vw,0);
    }
    40% {
        transform: translate3d(-7vw,-21vw,0);
    }
    50% {
        transform: translate3d(-14vw,7vw,0);
    }
    60% {
        transform: translate3d(21vw,21vw,0);
    }
    70% {
        transform: translate3d(14vw,14vw,0);
    }
    80% {
        transform: translate3d(-21vw,-21vw,0);
    }
    90% {
        transform: translate3d(14vw,7vw,0);
    }
    100% {
        transform: translate3d(7vw,7vw,0);
    }
}