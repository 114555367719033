@use '../core/mixin' as *;
@use '../core/variables' as *;

.policy {
    --height-header: 15.4rem;

    &-heading {
        grid-column: 3/15;
        white-space: nowrap;
        text-align: center;
        color: rgba($cl-white, 1);
        margin-top: calc(27rem - var(--height-header));

        @include md {
            grid-column: 3/11;
            margin-top: calc(16.7286245353rem);
        }
        @include sm {
            grid-column: 1/-1;
            white-space: normal;
            margin-top: calc(10.3646992164rem);
        }

        &-date {
            margin-top: 5rem;

            @include md {
                margin-top: 3.09789343247rem;
            }
            @include sm {
                margin-top: 1.9193887438rem;
                padding: 0 3rem;
            }
        }
    }

    &-content {
        grid-column: 5/13;
        margin-top: 15rem;
        margin-bottom: 20rem;
        display: flex;
        flex-direction: column;
        row-gap: 4rem;

        @include md {
            grid-column: 3/11;
            margin-top: 9.2936802974rem;
            margin-bottom: 12.3915737299rem;
        }

        @include sm {
            margin-top: 5.7581662314rem;
            margin-bottom: 7.6775549752rem;
            grid-column: 1/-1;
        }

        &-item {
            &-desc {
                font-size: var(--fs-20);
                line-height: var(--lh-20);

                *:not(li, a) {
                    margin-top: 2rem;
                }

                h6 {
                    font-size: var(--fs-20);
                    line-height: var(--lh-20);
                    font-weight: 500;
                }

                ul {
                    padding-left: 2rem;
                }
                a {
                    display: inline-block;
                    color: $cl-body;

                    span {
                        color: rgba($cl-white, 1);

                    }
                }
            }
        }
    }

    .header-main {
        margin-right: 0;
    }

    .header-menu {
        display: none;
    }
    .header-hamburger {
        display: none;
    }
    .header-main-inner {
        padding: 1rem;
    }
}