:root {
  --spring-easing-1: linear(0, .002, .009 1%, .035, .078, .141 4.4%, .281 6.7%, .723 12.9%, .842, .938, 1.017, 1.077 20.4%, 1.101, 1.121, 1.137, 1.149 24.3%, 1.159, 1.163, 1.161, 1.154, 1.143, 1.129 32.8%, 1.051 39.6%, 1.017 43.1%, 1.002, .991, .982 48.9%, .977 51%, .974 53.8%, .975 57.1%, .979 60%, .997 69.8%, 1.001, 1.003 76.9%, 1.004 83.8%, 1);
  --spring-easing-2: linear(0, .009, .035 2.1%, .141 4.4%, .723 12.9%, .938, 1.077 20.4%, 1.121, 1.149 24.3%, 1.163 27%, 1.154 29.9%, 1.017 43.1%, .991, .977 51%, .975 57.1%, 1.003 76.9%, 1);
  --spring-easing-3: linear(0, .938 16.7%, 1.149 24.3%, 1.154 29.9%, .977 51%, 1);
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes glitch-anim-1 {
  0%, 30% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal), 0, 0);
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }

  2%, 32% {
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }

  4%, 34% {
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }

  6%, 36% {
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }

  8%, 38% {
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }

  10%, 40% {
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }

  12%, 42% {
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }

  14%, 44% {
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }

  16%, 46% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }

  18%, 48% {
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }

  20%, 50% {
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }

  21.9%, 51.9% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal), 0, 0);
  }

  22%, 29.9%, 52%, 100% {
    opacity: 0;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes glitch-anim-2 {
  0%, 50% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
  }

  3%, 53% {
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }

  5%, 55% {
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
  }

  7%, 57% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }

  9%, 59% {
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }

  11%, 61% {
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
  }

  13%, 63% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }

  15%, 65% {
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
  }

  17%, 67% {
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
  }

  19%, 69% {
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
  }

  20%, 70% {
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }

  21.9%, 71.9% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
  }

  22%, 49%, 80%, 100% {
    opacity: 0;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes glitch-anim-3 {
  0%, 80% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
  }

  1.5%, 81.5% {
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
  }

  2%, 82% {
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
  }

  2.5%, 82.5% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }

  3%, 83% {
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
  }

  5%, 85% {
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
  }

  5.5%, 85.5% {
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
  }

  7%, 87% {
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
  }

  8%, 88% {
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }

  9%, 89% {
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
  }

  10.5%, 90.5% {
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
  }

  11%, 91% {
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
  }

  13%, 93% {
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
  }

  14%, 94% {
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
  }

  14.5%, 94.5% {
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
  }

  15%, 95% {
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
  }

  16%, 96% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }

  18%, 98% {
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
  }

  20%, 98.5% {
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
  }

  21.9%, 99% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
  }

  22%, 79%, 100% {
    opacity: 0;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes glitch-anim-flash {
  0%, 5% {
    opacity: .2;
    transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
  }

  5.5%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveWheel {
  0% {
    top: 33.33%;
  }

  100% {
    top: 55.55%;
  }
}

@keyframes noise {
  0% {
    transform: translateZ(0);
  }

  10% {
    transform: translate3d(-7vw, -7vw, 0);
  }

  20% {
    transform: translate3d(-14vw, 7vw, 0);
  }

  30% {
    transform: translate3d(7vw, -14vw, 0);
  }

  40% {
    transform: translate3d(-7vw, -21vw, 0);
  }

  50% {
    transform: translate3d(-14vw, 7vw, 0);
  }

  60% {
    transform: translate3d(21vw, 21vw, 0);
  }

  70% {
    transform: translate3d(14vw, 14vw, 0);
  }

  80% {
    transform: translate3d(-21vw, -21vw, 0);
  }

  90% {
    transform: translate3d(14vw, 7vw, 0);
  }

  100% {
    transform: translate3d(7vw, 7vw, 0);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  --fs-global: .578704vw;
  --container-max-width: none;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: none;
  -webkit-text-rendering: optimizeSpeed;
  text-rendering: optimizeSpeed;
  font-family: Saans;
  font-size: var(--fs-global);
  color: #ccc;
  background-color: #010101;
}

@media only screen and (width >= 991px) and (aspect-ratio >= 1600 / 800) {
  html {
    --fs-global: .925926vh;
  }
}

@media only screen and (width <= 991px) {
  html {
    --fs-global: 1.19904vw;
  }
}

@media only screen and (width <= 767px) {
  html {
    --fs-global: 1.8vw;
  }
}

@media only screen and (width <= 476px) {
  html {
    --fs-global: 2.667vw;
  }
}

html.lenis {
  height: auto;
}

.main {
  contain: paint;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

button, input, textarea {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  font-family: inherit;
}

button {
  cursor: pointer;
}

:root {
  --container-padding: 4rem;
}

.container {
  z-index: 2;
  width: 100%;
  padding: 0 var(--container-padding);
  max-width: var(--container-max-width);
  margin: 0 auto;
  position: relative;
}

.container.fluid {
  max-width: none;
}

@media screen and (width <= 991px) {
  .container {
    --grid-gap: 1.6rem;
  }
}

@media screen and (width <= 767px) {
  .container {
    --container-padding: 1.6rem;
  }
}

.grid {
  --grid-column: 16;
  --grid-gap: 2rem;
  column-gap: var(--grid-gap);
  grid-template-columns: repeat(var(--grid-column), 1fr);
  display: grid;
}

@media screen and (width <= 991px) {
  .grid {
    --grid-column: 12;
    --grid-gap: 1.6rem;
  }
}

@media screen and (width <= 767px) {
  .grid {
    --grid-column: 4;
    --container-padding: 1.6rem;
  }
}

.swiper, .swiper-wrapper, .swiper-slide {
  width: 100%;
}

.swiper-slide {
  height: auto;
}

.grain-noise-bg {
  pointer-events: none;
  opacity: .3;
  z-index: 99999;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.grain-noise-bg:before {
  content: "";
  -ms-animation: noise 2s steps(4) infinite;
  background-image: url("bg-noise-grain.98c83be2.webp");
  background-position: 40%;
  width: calc(100% + 42vw);
  height: calc(100% + 42vw);
  animation: 2s steps(4, end) infinite noise;
  position: absolute;
  top: -21vw;
  left: -21vw;
}

@media screen and (width <= 991px) {
  .grain-noise-bg {
    display: none;
  }
}

.cursor-wrap {
  z-index: 999999;
  pointer-events: none;
  mix-blend-mode: normal;
  color: #fff;
  opacity: 1;
  -o-transition: opacity .4s ease;
  width: 100%;
  height: 100%;
  transition: opacity .4s;
  position: fixed;
  top: 0;
  left: 0;
}

.cursor-wrap.on-hidden {
  opacity: 0;
}

.cursor-wrap.mixBlendMode {
  mix-blend-mode: lighten;
}

@media screen and (width <= 767px) {
  .cursor-wrap {
    display: none;
  }
}

.cursor-wrap .cursor {
  will-change: transform;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.cursor-wrap .cursor-dot {
  will-change: width, height, background-color;
  -o-transition: background-color .3s ease-in-out, width .3s ease-in-out, height .3s ease-in-out, transform .3s ease;
  background-color: #f5450d;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  transition: background-color .3s ease-in-out, width .3s ease-in-out, height .3s ease-in-out, transform .3s;
  position: absolute;
  transform: scale(1);
}

.cursor-wrap .cursor-dot.blackdot {
  background-color: #000;
}

.cursor-wrap .cursor-dot.whitedot {
  background-color: #fff;
}

.cursor-wrap .cursor-dot.stickstepdot {
  width: calc(3.2rem + 24px);
  height: calc(3.2rem + 24px);
}

.cursor-wrap .cursor-dot.stickfaq {
  width: 2rem;
  height: 2rem;
}

.cursor-wrap .cursor-dot.smdot {
  width: .6rem;
  height: .6rem;
}

.cursor-wrap .cursor-dot.hide {
  transform: scale(0);
}

.cursor-wrap .cursor-border {
  will-change: width, height, opacity;
  opacity: 1;
  -o-transition: background-color .3s ease-in-out, width .3s ease-in-out, height .3s ease-in-out, opacity .3s ease;
  width: 4.5rem;
  height: 4.5rem;
  transition: background-color .3s ease-in-out, width .3s ease-in-out, height .3s ease-in-out, opacity .3s;
  position: absolute;
}

.cursor-wrap .cursor-border-inner {
  -ms-animation: rotate 20s linear infinite;
  will-change: rotate;
  border: 2px dotted #fff3;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 20s linear infinite rotate;
}

.cursor-wrap .cursor-border.stickstepdot {
  width: calc(6.2rem + 24px);
  height: calc(6.2rem + 24px);
}

.cursor-wrap .cursor-border.mddot {
  width: 5rem;
  height: 5rem;
}

.cursor-wrap .cursor-border.hide {
  opacity: 0;
  -o-transition: width .5s ease-in-out, height .5s ease-in-out, opacity .3s ease;
  width: calc(6.2rem + 24px);
  height: calc(6.2rem + 24px);
  transition: width .5s ease-in-out, height .5s ease-in-out, opacity .3s;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cursor-wrap .cursor-glow {
  will-change: width, height;
  -o-transition: opacity .8s ease;
  width: 16.1rem;
  height: 16.1rem;
  transition: opacity .8s;
  position: absolute;
  transform: scale(1);
}

.cursor-wrap .cursor-glow.hide {
  opacity: 0;
}

.popup {
  pointer-events: none;
  z-index: 99;
  pointer-events: none;
  opacity: 0;
  -o-transition: opacity .5s ease-in-out;
  background-color: #000000b3;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  transition: opacity .5s ease-in-out;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.popup .container {
  height: 100%;
}

.popup.active {
  opacity: 1;
  pointer-events: auto;
}

.popup.active .popup--sidebar {
  transform: translateX(0);
}

.popup-inner {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.popup-content {
  align-items: center;
  height: 100%;
  display: flex;
}

.popup-close {
  background-color: #0000000d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin-top: -3rem;
  margin-right: -3rem;
  padding: 1rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .popup-close {
    margin-top: -1rem;
    margin-right: -1rem;
  }
}

@media screen and (width <= 767px) {
  .popup-close {
    margin-top: -2.2rem;
    margin-right: -1.4rem;
  }
}

.popup--sidebar {
  color: #010101;
  -o-transition: transform .5s ease-in-out;
  background-color: #fff;
  border-radius: 2rem;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  max-width: 71rem;
  height: calc(100% - 8rem);
  margin-left: auto;
  padding: 6rem;
  transition: transform .5s ease-in-out;
  display: flex;
  transform: translateX(100%);
}

@media screen and (width <= 991px) {
  .popup--sidebar {
    width: 100%;
    max-width: none;
    padding: 3rem;
  }
}

@media screen and (width <= 767px) {
  .popup--sidebar {
    padding: 3.2rem 2.4rem;
  }
}

.popup--sidebar-header {
  z-index: 2;
  justify-content: space-between;
  padding-bottom: 3rem;
  display: flex;
  position: relative;
}

.popup--sidebar-header-heading {
  flex: 1;
  color: #010101 !important;
}

.popup--sidebar-content {
  z-index: 1;
  flex-direction: column;
  row-gap: 3.2rem;
  margin: -3rem -2rem -3rem 0;
  padding: 3rem 2rem 3rem 0;
  display: flex;
  position: relative;
  -webkit-mask-image: linear-gradient(#0000 .5rem, #fff 6rem calc(100% - 6rem), #0000 calc(100% - .5rem));
  mask-image: linear-gradient(#0000 .5rem, #fff 6rem calc(100% - 6rem), #0000 calc(100% - .5rem));
}

@media screen and (width <= 991px) {
  .popup--sidebar-content {
    row-gap: 1.2rem;
  }
}

@media screen and (width <= 767px) {
  .popup--sidebar-content {
    margin: -3rem -1rem -3.2rem 0;
    padding: 3rem 1rem 3rem 0;
  }
}

.line {
  background-color: #ffffff1a;
  width: 100%;
  height: 1px;
}

.custome-scroll {
  scroll-behavior: smooth;
  overflow: clip auto;
}

.custome-scroll::-webkit-scrollbar {
  width: .3rem;
}

@media only screen and (width <= 476px) {
  .custome-scroll::-webkit-scrollbar {
    width: 3px;
  }
}

.custome-scroll::-webkit-scrollbar-track {
  background: #01010133;
}

.custome-scroll::-webkit-scrollbar-thumb {
  background: #f5450d;
}

.marquee {
  display: flex;
  overflow-x: clip;
}

.marquee-inner {
  display: flex;
}

.marquee-inner-item {
  will-change: transform;
  flex: none;
  width: max-content;
  display: flex;
}

.marquee-anim {
  --delay: 0s;
  --direction: normal;
  --duration: 353.152s;
  --iteration-count: infinite;
  animation: marquee var(--duration) linear var(--delay) var(--iteration-count);
  -webkit-animation: marquee var(--duration) linear var(--delay) var(--iteration-count);
  -moz-animation: marquee var(--duration) linear var(--delay) var(--iteration-count);
  -ms-animation: marquee var(--duration) linear var(--delay) var(--iteration-count);
  -o-animation: marquee var(--duration) linear var(--delay) var(--iteration-count);
  animation-direction: var(--direction);
  will-change: transform;
}

[data-border-glow] {
  position: relative;
}

[data-border-glow] .glow-el {
  width: var(--glow);
  height: var(--glow);
  background-image: url("glow-1.4b6dd250.png");
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[data-border-glow] .glow-el:before {
  content: "";
  background-color: var(--bg-cl);
  mix-blend-mode: lighten;
  width: 100%;
  height: 100%;
  filter: blur(calc(var(--glow) / 2.5));
  border-radius: 100vmax;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

[data-border-glow] .glow-el.force-cl {
  background: radial-gradient(var(--bg-cl), transparent 80%);
}

[data-border-glow] .glow-el.force-cl:before {
  content: unset;
}

[data-border-glow] .glow-big {
  background-image: url("glow-2.2febab1d.png");
}

[data-border-glow] .glow-nor {
  background-image: url("glow-4.5f9437b3.png");
}

[data-border-glow] .border-outer {
  z-index: 10;
  top: calc(var(--border-width) / 1.5 * -1);
  bottom: calc(var(--border-width) / 2.5 * -1);
  left: calc(var(--border-width) / 1.5 * -1);
  right: calc(var(--border-width) / 2.5 * -1);
  border-radius: var(--border-radius);
  pointer-events: none;
  width: 100%;
  height: 100%;
  opacity: calc(var(--opacity) * 1.5);
  padding: var(--border-width);
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

[data-border-glow] .border-inner {
  border-radius: 100vmax;
  width: 0;
  height: 0;
}

[data-border-glow] .glow-outer, [data-border-glow] .glow-inner {
  z-index: -1;
  pointer-events: none;
  border-radius: var(--border-radius);
  width: 100%;
  height: 100%;
  opacity: var(--opacity);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[data-border-glow] .glow-outer-inner, [data-border-glow] .glow-inner-inner {
  border-radius: 100vmax;
  width: 0;
  height: 0;
}

[data-border-glow] .glow-outer-inner .glow-el, [data-border-glow] .glow-inner-inner .glow-el {
  width: calc(var(--glow) / 1.5);
  height: calc(var(--glow) / 1.5);
  opacity: .2;
  display: flex;
}

[data-border-glow] .glow-inner {
  z-index: 20;
  mix-blend-mode: soft-light;
  overflow: hidden;
}

[data-border-glow] .glow-inner-inner .glow-el {
  width: calc(var(--glow) / 1.2);
  height: calc(var(--glow) / 1.2);
  opacity: .3;
}

[data-dot-glow-test] .border-dot-outer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

[data-dot-glow-test] .border-dot-inner {
  z-index: 1000;
  background-color: #00f;
  border-radius: 100vmax;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.test {
  margin-top: 5rem;
}

.test .border-box {
  filter: blur(1rem);
  clip-path: inset(0 -300px 0 300px);
  background-color: #0000;
  width: 20rem;
  height: 15rem;
  position: relative;
}

.test .border-box:before {
  content: "";
  border: 1px solid #ffffff8a;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.test .border-box .inner {
  z-index: 2;
  filter: blur(1rem);
  background-color: #00f;
  border-radius: 100vmax;
  width: 4rem;
  height: 4rem;
  position: relative;
  top: 40rem;
  left: 10rem;
}

.test .border-box .inner:before {
  content: "";
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

@media screen and (width <= 991px) {
  .cursor {
    display: none;
  }
}

:root {
  --fs-h0: 12rem;
  --lh-h0: .9em;
  --fs-h1: 10rem;
  --lh-h1: 1em;
  --fs-h2: 8rem;
  --lh-h2: .95em;
  --fs-h3: 7.2rem;
  --lh-h3: 1em;
  --fs-h4: 6.4rem;
  --lh-h4: .95em;
  --fs-h5: 6rem;
  --lh-h5: 1.3em;
  --fs-h6: 3.2rem;
  --lh-h6: 1.3em;
  --fs-12: 1.2rem;
  --lh-12: 1.6em;
  --fs-14: 1.4rem;
  --lh-14: 1.5em;
  --fs-16: 1.6rem;
  --lh-16: 1.4em;
  --fs-18: 1.8rem;
  --lh-18: 1.6em;
  --fs-20: 2rem;
  --lh-20: 1.4em;
  --fs-24: 2.4rem;
  --lh-24: 1.3em;
  --fs-26: 2.6rem;
  --lh-26: 1.3em;
  --fs-36: 3.6rem;
  --lh-36: 1.2em;
}

@media screen and (width <= 991px) {
  :root {
    --fs-h0: 7.2rem;
    --lh-h0: 1em;
    --fs-h1: 5rem;
    --fs-h2: 4.4rem;
    --lh-h2: 1em;
    --fs-h4: 3.6rem;
    --lh-h4: 1.1em;
    --fs-h5: 3.2rem;
    --lh-h5: 1em;
    --fs-h6: 2.4rem;
    --lh-h6: 1em;
    --fs-20: 1.6rem;
    --ld-20: 1.4rem;
  }
}

@media screen and (width <= 767px) {
  :root {
    --fs-h0: 5rem;
    --fs-h1: 4.4rem;
    --fs-h2: 3.6rem;
    --lh-h2: 1.1em;
    --fs-h5: 3.2rem;
    --lh-h5: 1.1em;
    --fs-h4: 2.4rem;
    --lh-h4: 1.3em;
    --fs-20: 1.6rem;
  }
}

.txt-12 {
  font-size: var(--fs-12);
  line-height: var(--lh-12);
}

.txt-14 {
  font-size: var(--fs-14);
  line-height: var(--lh-14);
}

.txt-16 {
  font-size: var(--fs-16);
  line-height: var(--lh-16);
}

.txt-18 {
  font-size: var(--fs-18);
  line-height: var(--lh-18);
}

.txt-20 {
  font-size: var(--fs-20);
  line-height: var(--lh-20);
}

.txt-24 {
  font-size: var(--fs-24);
  line-height: var(--lh-24);
}

.txt-26 {
  font-size: var(--fs-26);
  line-height: var(--lh-26);
}

.txt-36 {
  font-size: var(--fs-36);
  line-height: var(--lh-36);
}

.txt-reg {
  font-weight: 400 !important;
}

.txt-med {
  font-weight: 500;
}

.txt-bold {
  font-weight: 700;
}

.txt-hl {
  color: #f5450d;
}

.txt-link {
  --line-spacing: 1rem;
  color: #fff;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.txt-link:before, .txt-link:after {
  pointer-events: none;
  content: "";
  -o-transition: transform .5s cubic-bezier(.59, .23, .22, .96);
  background-color: currentColor;
  width: 100%;
  height: 1px;
  transition: transform .5s cubic-bezier(.59, .23, .22, .96);
  display: block;
  position: absolute;
  bottom: 0;
}

.txt-link:before {
  left: 0;
}

.txt-link:after {
  pointer-events: none;
  left: calc(-1 * var(--line-spacing));
  transform: translateX(-100%);
}

@media (hover: hover) {
  .txt-link:hover:before {
    transform-origin: 0;
    transform: scaleX(1);
    transform: translateX(calc(100% + var(--line-spacing)));
    -webkit-transform: translateX(calc(100% + var(--line-spacing)));
    -moz-transform: translateX(calc(100% + var(--line-spacing)));
    -ms-transform: translateX(calc(100% + var(--line-spacing)));
    -o-transform: translateX(calc(100% + var(--line-spacing)));
  }

  .txt-link:hover:after {
    transform: translateX(var(--line-spacing));
    -webkit-transform: translateX(var(--line-spacing));
    -moz-transform: translateX(var(--line-spacing));
    -ms-transform: translateX(var(--line-spacing));
    -o-transform: translateX(var(--line-spacing));
  }
}

.txt-subscript {
  vertical-align: sub;
}

h0, .h0 {
  color: #fff;
  font-weight: 500;
  font-size: var(--fs-h0);
  line-height: var(--lh-h0);
}

h1, .h1 {
  color: #fff;
  font-weight: 500;
  font-size: var(--fs-h1);
  line-height: var(--lh-h1);
}

h2, .h2 {
  color: #fff;
  font-weight: 500;
  font-size: var(--fs-h2);
  line-height: var(--lh-h2);
}

h3, .h3 {
  color: #fff;
  font-weight: 500;
  font-size: var(--fs-h3);
  line-height: var(--lh-h3);
}

h4, .h4 {
  color: #fff;
  font-weight: 500;
  font-size: var(--fs-h4);
  line-height: var(--lh-h4);
}

h5, .h5 {
  color: #fff;
  font-weight: 500;
  font-size: var(--fs-h5);
  line-height: var(--lh-h5);
}

h6, .h6 {
  color: #fff;
  font-weight: 500;
  font-size: var(--fs-h6);
  line-height: var(--lh-h6);
}

body {
  font-size: var(--fs-20);
  line-height: var(--lh-20);
}

.icon {
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon svg {
  width: 100%;
  height: auto;
}

.icon-12 {
  width: 1.2rem;
  height: 1.2rem;
}

.icon-16 {
  width: 1.6rem;
  height: 1.6rem;
}

.icon-20 {
  width: 2rem;
  height: 2rem;
}

.icon-22 {
  width: 2.2rem;
  height: 2.2rem;
}

.icon-24 {
  width: 2.4rem;
  height: 2.4rem;
}

.icon-28 {
  width: 2.8rem;
  height: 2.8rem;
}

.icon-32 {
  width: 3.2rem;
  height: 3.2rem;
}

.icon-64 {
  width: 6.4rem;
  height: 6.4rem;
}

.icon-80 {
  width: 8rem;
  height: 8rem;
}

.icon-48 {
  width: 4.8rem;
  height: 4.8rem;
}

.icon-100 {
  width: 10rem;
  height: 10rem;
}

.btn {
  --border-radius: 1.5rem;
  color: #fff;
  -o-transition: color .3s ease-out;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  transition: color .3s ease-out;
  display: flex;
  position: relative;
}

@media screen and (width <= 767px) {
  .btn {
    --border-radius: 1.2rem;
  }
}

.btn:before {
  content: "";
  border-radius: var(--border-radius);
  z-index: 1;
  background: linear-gradient(#f5450d 34%, #f58a0d 100%);
  position: absolute;
  inset: 0;
  box-shadow: inset 0 0 1rem .4rem #ff000082;
}

.btn:after {
  content: "";
  z-index: 3;
  border-radius: var(--border-radius);
  -webkit-mask-composite: xor;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#ff693a 0%, #ff693a00 100%) border-box;
  padding: 1px;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.btn .btnoverlay {
  border-radius: var(--border-radius);
  opacity: 0;
  z-index: 2;
  -o-transition: opacity .6s cubic-bezier(.34, 1.56, .64, 1);
  background: linear-gradient(#ff3f15 0%, #ff5d2c 34%, #ff9f2e 100%);
  width: 100%;
  height: 100%;
  transition: opacity .6s cubic-bezier(.34, 1.56, .64, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.btn:hover .btnoverlay {
  opacity: 1;
  -o-transition: opacity .4s cubic-bezier(.33, 1, .68, 1);
  transition: opacity .4s cubic-bezier(.33, 1, .68, 1);
}

.btn .txt-btn {
  text-align: center;
  -webkit-backface-visibility: hidden;
  z-index: 3;
  justify-content: center;
  align-items: center;
  padding: 2.4rem 2.7rem;
  display: inline-block;
  position: relative;
}

@media screen and (width <= 991px) {
  .btn .txt-btn {
    padding: 2.4rem 2.7rem;
  }
}

.btn .txt-wrap {
  border-radius: var(--border-radius);
}

.btn-border {
  --border-radius: 2rem;
  --border-padding: .5rem;
  padding: var(--border-padding);
  border-radius: var(--border-radius);
  background-color: #ffffff0d;
  border: 1px solid #ffffff1a;
  position: relative;
}

@media screen and (width <= 767px) {
  .btn-border {
    --border-radius: 1.7rem;
  }
}

.btn-border:before {
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  top: .5rem;
  left: .5rem;
}

.btn-border:after {
  pointer-events: none;
  inset: .5rem;
}

.btn-white {
  color: #313131;
}

.btn-white:before {
  background: #fff;
  box-shadow: inset 0 0 1rem .4rem #0000001f;
}

.btn-white:after {
  border-radius: var(--border-radius);
  -webkit-mask-composite: xor;
  opacity: .2;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#fff 0%, #fff0 100%) border-box;
  padding: 1px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.btn-white .btnoverlay {
  background: var(--dark, linear-gradient(180deg, #101010 10%, #212121 95%));
}

.btn-white:hover {
  color: #fff;
}

.btn-dark:before {
  opacity: .15;
  background: linear-gradient(#fff 0%, #fff3 100%);
  border-color: #fff;
  box-shadow: inset 0 0 1rem .4rem #ffffff1a;
}

.btn-dark:after {
  -webkit-mask-composite: xor;
  opacity: .15;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#fff 0%, #fff0 100%) border-box;
  border-radius: 1.5rem;
  padding: 1px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

img {
  width: 100%;
  max-width: none;
  height: auto;
  display: block;
}

.img {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  pointer-events: none;
  outline: 0;
  height: auto;
  display: block;
}

.img-wrap {
  position: relative;
}

.img-wrap:after {
  pointer-events: none;
  content: "";
  width: 100%;
  height: 0;
  padding-bottom: var(--aspect);
  display: block;
}

.img-height {
  width: auto;
  height: 100%;
}

.img-fill {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-abs {
  position: absolute;
  inset: 0;
}

.vid-bg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.link-hidden {
  display: none;
}

input, textarea {
  -webkit-appearance: none;
  appearance: none;
}

textarea {
  resize: vertical;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

button {
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
}

.hover-line {
  --line-spacing: 1rem;
  vertical-align: bottom;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.hover-line:before, .hover-line:after {
  pointer-events: none;
  content: "";
  background-color: currentColor;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: .15em;
}

@media screen and (width <= 991px) {
  .hover-line:before, .hover-line:after {
    bottom: 0;
  }
}

.hover-line:before {
  left: 0;
}

.hover-line:after {
  left: calc(-1 * var(--line-spacing));
  transform: translateX(-100%);
}

@media (hover: hover) {
  .hover-line:hover:before {
    transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
    -webkit-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
    -moz-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
    -ms-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
    -o-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
    -o-transition: transform .65s cubic-bezier(.6, 0, .3, 1);
    transition: transform .65s cubic-bezier(.6, 0, .3, 1);
  }

  .hover-line:hover:after {
    transform: translateX(var(--line-spacing)) rotate(.01deg);
    -webkit-transform: translateX(var(--line-spacing)) rotate(.01deg);
    -moz-transform: translateX(var(--line-spacing)) rotate(.01deg);
    -ms-transform: translateX(var(--line-spacing)) rotate(.01deg);
    -o-transform: translateX(var(--line-spacing)) rotate(.01deg);
    -o-transition: transform .65s cubic-bezier(.6, 0, .3, 1) .15s;
    transition: transform .65s cubic-bezier(.6, 0, .3, 1) .15s;
  }
}

.hover-un {
  cursor: pointer;
  color: currentColor;
  width: fit-content;
  position: relative;
}

.hover-un:before {
  pointer-events: none;
  content: "";
  transform-origin: 100%;
  -o-transition: .6s transform cubic-bezier(.66, 0, .15, 1);
  background-color: currentColor;
  width: 100%;
  height: 1px;
  transition: transform .6s cubic-bezier(.66, 0, .15, 1);
  position: absolute;
  bottom: .15em;
  left: 0;
  transform: scaleX(0);
}

@media (hover: hover) {
  .hover-un:hover:before {
    transform-origin: 0;
    transform: scaleX(1);
  }
}

.header {
  color: #fff;
  z-index: 99;
  pointer-events: none;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  position: sticky;
  top: 0;
}

@media screen and (width <= 767px) {
  .header {
    height: 9rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.header-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (width <= 767px) {
  .header-container {
    align-items: flex-start;
    position: absolute;
    top: 1.2rem;
  }
}

.header-logo {
  -o-transition: opacity .4s ease;
  opacity: 0;
  align-items: center;
  margin-right: 3.2rem;
  transition: opacity .4s;
  display: flex;
}

@media screen and (width <= 767px) {
  .header-logo {
    width: 0;
    margin-top: .78rem;
    margin-right: 0;
    position: absolute;
  }
}

.header-logo-img {
  pointer-events: auto;
  height: 6.4rem;
}

.header-logo-line {
  -o-transition: transform .4s ease;
  background-color: #ffffff1a;
  width: 1px;
  height: 4rem;
  margin-left: 1.6rem;
  margin-right: 1rem;
  transition: transform .4s;
  transform: translateX(-.5rem);
}

@media screen and (width <= 767px) {
  .header-logo-line {
    display: none;
  }
}

.header-logo-txt {
  color: #fff9;
  text-transform: uppercase;
  -o-transition: transform .4s ease;
  max-width: 21.8rem;
  font-size: .9rem;
  font-weight: 600;
  line-height: 1.4em;
  transition: transform .4s;
  transform: translateX(-1rem);
}

.header-logo-txt-hl {
  color: #fffc;
}

@media screen and (width <= 767px) {
  .header-logo-txt {
    display: none;
  }
}

.header-logo.active {
  opacity: 1;
}

.header-logo.active .header-logo-txt, .header-logo.active .header-logo-line {
  transform: translateX(0);
}

.header-main {
  pointer-events: auto;
  align-items: center;
  margin-left: auto;
  display: flex;
}

@media screen and (width <= 767px) {
  .header-main {
    margin-right: auto;
  }
}

.header-main-schedule {
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (width <= 991px) {
  .header-main-schedule {
    --fs-16: 1.4rem;
    --lh-16: 1.3em;
    display: none;
  }
}

@media screen and (width <= 767px) {
  .header-main-schedule {
    display: none;
  }
}

.header-main-inner {
  --border-radius: 2.3rem;
  border-radius: var(--border-radius);
  backdrop-filter: blur(1rem);
  background-color: #000c;
  margin-left: 3.2rem;
  padding: 1rem 1rem 1rem .4rem;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 1rem #ffffff0d;
}

@media screen and (width <= 991px) {
  .header-main-inner {
    align-items: center;
    margin-left: 2.4rem;
    padding: .8rem;
  }
}

@media screen and (width <= 767px) {
  .header-main-inner {
    border-radius: 2rem;
    flex-direction: column-reverse;
    margin-left: 0;
  }
}

.header-main-inner:before {
  --border-width: 1px;
  content: "";
  padding: var(--border-width);
  -webkit-mask-composite: xor;
  border-radius: inherit;
  top: calc(var(--border-width) / 1.5 * -1);
  bottom: calc(var(--border-width) / 2.5 * -1);
  left: calc(var(--border-width) / 1.5 * -1);
  right: calc(var(--border-width) / 2.5 * -1);
  pointer-events: none;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: #ffffff1a border-box;
  position: absolute;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.header-main-inner .btn {
  white-space: nowrap;
}

@media screen and (width <= 991px) {
  .header-main-inner .btn {
    align-items: center;
    display: flex;
  }

  .header-main-inner .btn .txt {
    padding: 1.9rem 2.7rem;
  }
}

.header-hamburger {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 991px) {
  .header-hamburger {
    width: 6rem;
    height: 6rem;
  }
}

@media screen and (width <= 767px) {
  .header-hamburger {
    width: 0;
    height: 6rem;
  }
}

.header-hamburger-line {
  transform-origin: center;
  -o-transition: all .4s ease;
  background-color: #fff;
  width: 2.4rem;
  height: 1px;
  transition: all .4s;
  position: absolute;
}

.header-hamburger-line:first-child {
  transform: translate(0, .6rem);
}

.header-hamburger-line:last-child {
  transform: translate(0, -.6rem);
}

.header-hamburger.active .header-hamburger-line:nth-child(2) {
  opacity: 0;
  transform: scaleX(0);
}

.header-hamburger.active .header-hamburger-line:first-child {
  background-color: #ffffff59;
  width: 2.2rem;
  transform: translate(0)rotate(-45deg);
}

.header-hamburger.active .header-hamburger-line:last-child {
  background-color: #ffffff59;
  width: 2.2rem;
  transform: translate(0)rotate(45deg);
}

.header-hamburger.active:hover .header-hamburger-line:first-child, .header-hamburger.active:hover .header-hamburger-line:last-child {
  background-color: #fff;
}

.header-menu {
  -o-transition: all .4s ease;
  align-items: center;
  padding-left: 0;
  transition: all .4s;
  display: flex;
}

@media screen and (width <= 991px) {
  .header-menu {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (width <= 767px) {
  .header-menu {
    display: none;
  }
}

.header-menu-label {
  --d-width: 0;
  width: var(--d-width);
  -o-transition: width .6s ease;
  transition: width .6s;
  display: grid;
}

@media screen and (width <= 991px) {
  .header-menu-label {
    margin-bottom: .1rem;
    margin-left: 0;
  }
}

@media screen and (width <= 767px) {
  .header-menu-label {
    margin-left: 0;
  }
}

.header-menu-label-item {
  text-align: center;
  opacity: 0;
  text-align: center;
  grid-area: 1 / 1 / 2 / 2;
  justify-content: center;
  place-self: center start;
  align-items: center;
  display: flex;
}

.header-menu-label-item span {
  -o-transition: transform ease .6s;
  transition: transform .6s;
  transform: scale(.8);
}

@media screen and (width <= 991px) {
  .header-menu-label-item {
    text-align: left;
    --fs-16: 1.4rem;
    --lh-16: 1.3em;
  }
}

.header-menu-prog {
  -o-transition: all .6s ease;
  align-items: center;
  column-gap: 0;
  margin-left: 0;
  transition: all .6s;
  display: flex;
  position: relative;
}

.header-menu-prog-item {
  opacity: 0;
  background-color: #ffffff1a;
  border-radius: 9.9rem;
  height: .6rem;
  position: relative;
  overflow: hidden;
}

.header-menu-prog-item-wrap {
  -o-transition: padding .6s ease;
  transition: padding .6s;
}

.header-menu-prog-item-inner {
  opacity: 0;
  -o-transition: opacity .6s ease;
  background-color: #fff;
  border-radius: 9.9rem;
  width: 100%;
  height: 100%;
  transition: opacity .6s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.header-menu-prog-item-link {
  -o-transition: all .6s ease;
  width: 0;
  height: .6rem;
  transition: all .6s;
  display: block;
}

.header-menu-prog-label {
  opacity: 0;
  transition: opacity .35s ease-out .2;
  -webkit-transition: opacity .35s ease-out .2;
  -moz-transition: opacity .35s ease-out .2;
  -ms-transition: opacity .35s ease-out .2;
  -o-transition: opacity .35s ease-out .2;
  background-color: #010101;
  border-radius: .6rem;
  padding: .5rem 1rem;
  position: absolute;
  top: -.8rem;
  left: 0;
  transform: translateY(-100%);
}

.header-menu.active {
  padding-left: 2.6rem;
}

@media screen and (width <= 991px) {
  .header-menu.active {
    width: auto;
    margin-right: 3.2rem;
    padding-left: 1.2rem;
  }
}

.header-menu.active .header-menu-label-item.active {
  opacity: 1;
}

.header-menu.active .header-menu-label-item.active span {
  transform: scale(1);
}

.header-menu.active .header-menu-prog {
  margin-left: .6rem;
}

.header-menu.active .header-menu-prog-item {
  opacity: 1;
}

.header-menu.active .header-menu-prog-item-wrap {
  padding: .6rem;
}

.header-menu.active .header-menu-prog-item-wrap.active {
  pointer-events: none;
}

.header-menu.active .header-menu-prog-item-link {
  width: .6rem;
}

.header-menu.active .header-menu-prog-item.active .header-menu-prog-item-inner {
  opacity: 1;
}

.header-menu.active .header-menu-prog-item.active .header-menu-prog-item-link {
  width: 7.2rem;
}

@media screen and (width <= 991px) {
  .header-menu.active .header-menu-prog-item.active .header-menu-prog-item-link {
    width: 3.1rem;
  }
}

.header-menu:not(.active) .header-menu-label {
  --d-width: 0 !important;
}

@media screen and (width <= 767px) {
  .header-menu-sm {
    --header-marginTop: 2.4rem;
    --header-marginBot: 2.7rem;
    width: 100%;
    display: none;
    overflow: hidden;
  }

  .header-menu-sm .header-menu-sm-process {
    width: 6rem;
    margin-top: var(--header-marginTop);
    margin-bottom: var(--header-marginBot);
  }

  .header-menu-sm .header-menu-sm-process-inner {
    background: #ffffff1a;
    border-radius: 100vmax;
    width: 4px;
    height: 100%;
    margin: auto;
    overflow: hidden;
  }

  .header-menu-sm .header-menu-sm-process-inner-line {
    -o-transition: top .5s ease;
    background: #fff;
    border-radius: 100vmax;
    width: 100%;
    height: 3.16rem;
    transition: top .5s;
    position: relative;
  }

  .header-menu-sm .header-menu-sm-list {
    padding-top: var(--header-marginTop);
    padding-bottom: var(--header-marginBot);
  }

  .header-menu-sm a {
    --lh-18: 1.2em;
    color: #fff6;
    -o-transition: color .5s ease;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: color .5s;
    display: block;
  }

  .header-menu-sm a:first-child {
    padding-top: .5rem;
  }

  .header-menu-sm a:last-child {
    padding-bottom: .5rem;
  }

  .header-menu-sm a.active {
    color: #fff;
  }
}

.header-btn-wrapper {
  display: flex;
}

.header-btn-wrapper .btn {
  border-radius: var(--border-radius);
}

.footer {
  padding-top: 6rem;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 767px) {
  .footer {
    padding-top: 0;
  }
}

.footer-cta {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 2.8rem 0;
  margin-bottom: 10.3rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .footer-cta {
    margin-bottom: 6rem;
  }
}

@media screen and (width <= 767px) {
  .footer-cta {
    margin-bottom: 4.7rem;
  }
}

.footer-cta-title {
  color: #f5450d;
  line-height: 1.2em;
}

.footer-cta-title.hover-line:before, .footer-cta-title.hover-line:after {
  height: .4rem;
  bottom: .1em;
}

@media screen and (width <= 991px) {
  .footer-cta-title.hover-line:before, .footer-cta-title.hover-line:after {
    height: .3rem;
  }
}

@media screen and (width <= 767px) {
  .footer-cta-title {
    --fs-h0: 4.4rem;
    --ld-h0: 1em;
  }

  .footer-cta-title.hover-line:before, .footer-cta-title.hover-line:after {
    height: .2rem;
    bottom: .05em;
  }
}

.footer-cta-title:before, .footer-cta-title:after {
  height: .4rem;
  bottom: 0;
}

.footer-cta-sub1 {
  color: #fff;
}

@media screen and (width <= 991px) {
  .footer-cta-sub1 {
    --fs-20: 1.8rem;
    --ld-20: 1.2em;
  }
}

@media screen and (width <= 767px) {
  .footer-cta-sub1 {
    --fs-20: 1.6rem;
    --ld-20: 1.4em;
    padding: 0 2.2rem;
  }
}

.footer-cta-sub2 {
  color: #ffffffb3;
}

@media screen and (width <= 991px) {
  .footer-cta-sub2 {
    --fs-20: 1.6rem;
    --ld-20: 1.4em;
  }
}

.footer-cta-sub3 {
  color: #fff9;
  gap: 0 .5rem;
  display: flex;
}

@media screen and (width <= 767px) {
  .footer-cta-sub3 {
    --fs-18: 1.6rem;
    --lh-18: 1.4em;
  }
}

.footer-cta-sub3 a {
  color: #fff;
}

@media screen and (width <= 991px) {
  .footer-cta-sub3 a {
    font-weight: 600;
  }
}

.footer-cta-btn .txt-btn {
  padding: 2.4rem 8.55rem;
}

@media screen and (width <= 991px) {
  .footer-cta-btn .txt-btn {
    --fs-18: 1.6rem;
    --ld-18: 1.4rem;
    padding: 1.9rem 8.55rem;
  }
}

@media screen and (width <= 767px) {
  .footer-cta-btn .txt-btn {
    padding: 1.4rem 3.2rem;
  }
}

.footer-brands {
  margin-bottom: 2rem;
  padding: 1.6rem 0;
}

@media screen and (width <= 991px) {
  .footer-brands {
    margin-bottom: 3rem;
  }
}

.footer-brands-marquee {
  align-items: center;
  gap: 0 4rem;
  padding: 0 2rem;
  display: flex;
}

.footer-brands-marquee-item {
  width: 10rem;
  height: auto;
}

@media screen and (width <= 767px) {
  .footer-brands-marquee {
    gap: 0 1.6rem;
    padding: 0 .8rem;
  }
}

.footer-curtain {
  z-index: 1;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.footer-curtain .footer-curtain-line {
  background: url("ft-line1.5ea1651f.svg") 0 0 / auto 100% repeat-x;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-curtain .footer-curtain-line2 {
  z-index: 3;
  background: url("ft-line2.4c484a4f.svg") 0 0 / auto 100% repeat-x;
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-curtain--inner {
  z-index: 1;
  top: calc((var(--zindex)  - 1) * 10%);
  transform-origin: top;
  background-color: #f5450d;
  width: 100%;
  height: 10%;
  display: block;
  position: absolute;
}

.footer-curtain--inner:nth-of-type(n+7) {
  z-index: 4;
}

.footer-curtain-logo {
  z-index: 2;
  width: 36.7rem;
  margin-bottom: 6.8rem;
}

@media screen and (width <= 991px) {
  .footer-curtain-logo {
    width: 28.9rem;
    margin-bottom: 6.4rem;
  }
}

@media screen and (width <= 767px) {
  .footer-curtain-logo {
    width: 23.2rem;
    margin-bottom: 6.4rem;
  }
}

.footer-curtain-hide {
  opacity: 1;
  z-index: 3;
  background-color: #000;
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 0;
}

.footer .footer-copyright-wrapper {
  z-index: 1;
  margin-top: -4.1rem;
  padding-bottom: 2rem;
}

@media screen and (width <= 991px) {
  .footer .footer-copyright-wrapper {
    background-color: #f5450d;
    margin-top: -3.5rem;
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
  }
}

@media screen and (width <= 767px) {
  .footer .footer-copyright-wrapper {
    background-color: #f5450d;
    margin-top: 0;
    padding-top: .9rem;
    padding-bottom: .6rem;
  }
}

@media screen and (width <= 991px) {
  .footer-premble {
    --fs-16: 1.4rem;
    --lh-16: 1.3em;
  }
}

@media screen and (width <= 767px) {
  .footer-premble {
    --fs-16: 1.2rem;
    --lh-16: 1.4em;
    text-align: center;
    width: 100%;
    max-width: 19.3rem;
    margin-bottom: .4rem;
  }
}

.footer-copyright {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (width <= 767px) {
  .footer-copyright {
    flex-direction: column;
  }
}

.footer-links {
  align-items: center;
  gap: 4.4rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .footer-links {
    gap: 2rem;
  }
}

@media screen and (width <= 767px) {
  .footer-links {
    gap: 3.6rem;
  }
}

.footer-link {
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (width <= 991px) {
  .footer-link {
    --fs-16: 1.4rem;
    --lh-16: 1.3em;
  }
}

@media screen and (width <= 767px) {
  .footer-link {
    --fs-16: 1.2rem;
    --lh-16: 1.4em;
  }
}

.footer-link:after {
  content: "";
  background-color: #fff3;
  width: 2px;
  height: 100%;
  position: absolute;
  right: -2.2rem;
}

@media screen and (width <= 991px) {
  .footer-link:after {
    right: -1.2rem;
  }
}

@media screen and (width <= 767px) {
  .footer-link:after {
    right: -2rem;
  }
}

.footer-link:last-child:after {
  display: none;
}

.footer-overlap {
  z-index: 4;
  background-color: #f5450d;
  width: 100%;
  height: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.popup-contact-inner {
  grid-template-columns: repeat(17, 1fr);
  column-gap: 2rem;
  display: grid;
}

@media screen and (width <= 991px) {
  .popup-contact-inner {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media screen and (width <= 767px) {
  .popup-contact-inner {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.6rem;
  }
}

.popup-contact-inner-content {
  grid-area: 1 / 7 / 1 / 18;
  height: 100vh;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content {
    grid-area: 1 / 1 / 1 / 13;
  }
}

.popup-contact-inner-content-sidebar {
  background-color: #f1f1f1;
  flex-direction: column;
  grid-template-columns: repeat(10, 1fr);
  align-items: start;
  column-gap: 2rem;
  width: 100%;
  max-width: none;
  padding: 5rem 0;
  display: grid;
  position: relative;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar {
    padding: 6rem;
    display: flex;
  }
}

@media screen and (width <= 767px) {
  .popup-contact-inner-content-sidebar {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.6rem;
    max-height: calc(100vh - 3.2rem);
    padding: 2.4rem 4rem;
  }
}

.popup-contact-inner-content-sidebar .popup-close {
  background: none;
  width: 2.4rem;
  height: 2.4rem;
  margin-top: 0;
  margin-right: 0;
  padding: 0;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar .popup-close {
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    display: flex;
    top: 1rem;
    right: 1rem;
  }

  .popup-contact-inner-content-sidebar .popup-close img {
    width: 2.4rem;
    height: 2.4rem;
  }
}

@media screen and (width <= 991px) and (width <= 767px) {
  .popup-contact-inner-content-sidebar .popup-close img {
    width: 2rem;
    height: 2rem;
  }
}

.popup-contact-inner-content-sidebar-left {
  grid-area: 1 / 2 / 1 / 5;
  max-width: 25.5rem;
  margin-left: -2.4rem;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar-left {
    grid-template-columns: repeat(10, 1fr);
    align-items: end;
    column-gap: 2rem;
    max-width: 100%;
    margin-bottom: 6rem;
    margin-left: 0;
    display: grid;
  }
}

@media screen and (width <= 767px) {
  .popup-contact-inner-content-sidebar-left {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 4rem;
    margin-left: 0;
    display: flex;
  }
}

.popup-contact-inner-content-sidebar-left-heading {
  color: #010101;
  margin-bottom: 4rem;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar-left-heading {
    font-size: var(--fs-h5);
    line-height: var(--lh-h4);
    grid-area: 1 / 1 / 1 / 5;
    margin-bottom: 0;
  }
}

@media screen and (width <= 767px) {
  .popup-contact-inner-content-sidebar-left-heading {
    font-size: var(--fs-h4);
    margin-bottom: 1.2rem;
  }
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar-left-sub {
    grid-area: 1 / 7 / 1 / 10;
    margin-right: -.6rem;
  }
}

.popup-contact-inner-content-sidebar-left-sub-txt {
  color: #8e8e8e;
}

.popup-contact-inner-content-sidebar-left-sub a {
  color: #f5450d;
}

.popup-contact-inner-content-sidebar-form {
  -ms-overflow-style: none;
  flex-direction: column;
  grid-area: 1 / 5 / 1 / 10;
  height: 100%;
  margin-right: -4.8rem;
  display: flex;
  overflow-y: auto;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar-form {
    height: 70vh;
    margin-right: -2rem;
  }
}

@media screen and (width <= 767px) {
  .popup-contact-inner-content-sidebar-form {
    flex: 1;
    height: 55vh;
  }
}

.popup-contact-inner-content-sidebar-form-top {
  flex: 1;
  padding-bottom: 3rem;
  padding-right: 2.4rem;
  overflow-y: auto;
  scrollbar-width: initial !important;
}

.popup-contact-inner-content-sidebar-form-top::-webkit-scrollbar {
  width: .3rem;
  display: block !important;
}

.popup-contact-inner-content-sidebar-form-top::-webkit-scrollbar-thumb {
  background-color: #f5450d;
  border-radius: 5rem;
}

.popup-contact-inner-content-sidebar-form-top::-webkit-scrollbar-track {
  background-color: #ffffff1a;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar-form-top {
    padding-right: 2rem;
  }
}

.popup-contact-inner-content-sidebar-form-title {
  color: #010101cc;
  margin-bottom: .4rem;
  font-size: 2rem;
  line-height: 1.2em;
}

.popup-contact-inner-content-sidebar-form-title .txt-disabled {
  color: #01010133;
}

.popup-contact-inner-content-sidebar-form-control {
  cursor: pointer;
  color: #010101;
  width: 100%;
  font-size: var(--fs-16);
  line-height: var(--lh-16);
  background-color: #0000;
  border: none;
  margin-bottom: 0;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar-form-control {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.popup-contact-inner-content-sidebar-form-control:placeholder-shown ~ .popup-contact-inner-content-sidebar-form-text-label {
  color: #4d4d4d;
  font-size: var(--fs-16);
  top: 2.4rem;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar-form-control:placeholder-shown ~ .popup-contact-inner-content-sidebar-form-text-label {
    top: 2rem;
  }
}

.popup-contact-inner-content-sidebar-form-control:focus ~ .popup-contact-inner-content-sidebar-form-text-label {
  color: #8e8e8e;
  font-size: var(--fs-12);
  top: .8rem;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar-form-control:focus ~ .popup-contact-inner-content-sidebar-form-text-label {
    top: .6rem;
  }
}

.popup-contact-inner-content-sidebar-form-control:focus ~ .popup-contact-inner-content-sidebar-form-group-bar:before {
  transform-origin: 0;
  transform: scale(1);
}

.popup-contact-inner-content-sidebar-form-group {
  font-size: var(--fs-16);
  line-height: var(--lh-16);
  position: relative;
}

.popup-contact-inner-content-sidebar-form-group-bar {
  background-color: #0101011a;
  width: 100%;
  height: 1px;
  position: relative;
}

.popup-contact-inner-content-sidebar-form-group-bar:before {
  content: "";
  transform-origin: 100%;
  background: linear-gradient(#ff9f2e 0%, #ff5d2c 69%, #ff3f15 100%);
  width: 100%;
  height: 1px;
  transition: transform .4s ease-out;
  position: absolute;
  bottom: 50%;
  left: 0;
  transform: scale(0);
}

.popup-contact-inner-content-sidebar-form-group input {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.popup-contact-inner-content-sidebar-form-group input::placeholder {
  color: #0000;
}

.popup-contact-inner-content-sidebar-form-group textarea {
  resize: none;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar-form-group textarea {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.popup-contact-inner-content-sidebar-form-group textarea::placeholder {
  color: #0000;
}

.popup-contact-inner-content-sidebar-form-group textarea::-webkit-scrollbar {
  width: .5rem;
}

.popup-contact-inner-content-sidebar-form-group textarea::-webkit-scrollbar-thumb {
  background: #f5450d;
  border-radius: 1rem;
  transition: all .4s;
}

.popup-contact-inner-content-sidebar-form-group textarea::-webkit-scrollbar-thumb:hover {
  background: #f5430dcf;
}

.popup-contact-inner-content-sidebar-form-group:hover .popup-contact-inner-content-sidebar-form-group-bar:before {
  transform-origin: 0;
  transform: scale(1);
}

.popup-contact-inner-content-sidebar-form-text-label {
  color: #8e8e8e;
  font-size: var(--fs-12);
  transition: all .4s;
  position: absolute;
  top: .8rem;
  left: 0;
}

@media screen and (width <= 991px) {
  .popup-contact-inner-content-sidebar-form-text-label {
    top: .6rem;
  }
}

.popup-contact-inner-content-sidebar-form-error {
  color: #9b2626;
  font-size: var(--fs-12);
  line-height: var(--lh-36);
  margin-top: .6rem;
  display: none;
}

.popup-contact-inner-content-sidebar-form-btn {
  cursor: pointer;
  background-color: #ffffff1a;
  margin-right: 2.4rem;
}

@media screen and (width <= 991px) {
  .btn .popup-contact-inner-content-sidebar-form-btn-submit {
    padding: 1.9rem 2.7rem;
  }
}

@media screen and (width <= 767px) {
  .btn .popup-contact-inner-content-sidebar-form-btn-submit {
    padding: 1.4rem 2.7rem;
  }
}

.popup-contact-inner-content-sidebar-form-accordion {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media screen and (width <= 767px) {
  .popup-contact-inner-content-sidebar-form-accordion {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.popup-contact-inner-content-sidebar-form-accordion .accordion__link {
  cursor: pointer;
  color: #4d4d4d;
}

.popup-contact-inner-content-sidebar-form-accordion-submenu {
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 2.4rem;
  display: flex;
}

.popup-contact-inner-content-sidebar-form-accordion-submenu .accordion__submenu-dot {
  border: 1px solid #01010133;
  border-radius: 99rem;
  width: 1.2rem;
  height: 1.2rem;
  transition: all .4s;
}

.popup-contact-inner-content-sidebar-form-accordion .accordion__submenu-item {
  cursor: pointer;
  transition: all .4s;
}

.popup-contact-inner-content-sidebar-form-accordion .accordion__submenu-item:hover {
  color: #f5450d;
}

.popup-contact-inner-content-sidebar-form-accordion .accordion__submenu-item:hover .accordion__submenu-dot {
  background-color: #f5450d;
  border: none;
}

.popup-contact-inner-content-sidebar-form-accordion-label {
  color: currentColor;
  transition: all .4s ease-in-out;
}

.popup-contact-inner-content-sidebar-form-accordion-label-wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.popup-contact-inner-content-sidebar-form-accordion-label-wrap .ic-embed {
  transition: all .4s;
  transform: rotate(-180deg);
}

.accordion__item.is-open .popup-contact-inner-content-sidebar-form-accordion-label-wrap .ic-embed {
  transform: rotate(0);
}

.accordion__item:hover ~ .popup-contact-inner-content-sidebar-form-group-bar:before, .accordion__item.is-open ~ .popup-contact-inner-content-sidebar-form-group-bar:before {
  transform-origin: 0;
  transform: scale(1);
}

#topic {
  display: none;
}

.accordion__submenu-item.active {
  color: #f5450d;
}

.accordion__submenu-item.active .accordion__submenu-dot {
  background-color: #f5450d;
  border: none;
}

.home {
  --height-header: 15.4rem;
  z-index: 5;
  position: relative;
}

.home-hero {
  z-index: 1;
  height: 100vh;
  min-height: 100vh;
  position: relative;
}

.home-hero .container {
  height: 100%;
}

@media screen and (width <= 767px) {
  .home-hero {
    height: 100svh;
    min-height: auto;
  }
}

.home-hero-content {
  --total-space: calc(100vh - 54rem);
  text-align: center;
  width: 100%;
  max-width: 120.3rem;
  padding-top: 0;
  padding-bottom: calc(.3788 * var(--total-space));
  flex-direction: column;
  grid-column: 1 / -1;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-hero-content {
    padding-top: 7.3rem;
  }
}

@media screen and (width <= 767px) {
  .home-hero-content {
    justify-content: flex-start;
    padding-top: 5.4rem;
    padding-bottom: 0;
  }
}

.home-hero-logo {
  width: 6.5rem;
  margin-bottom: calc(.078782 * var(--total-space));
}

@media screen and (width <= 991px) {
  .home-hero-logo {
    width: 4.4rem;
    margin-bottom: 7.4rem;
  }
}

@media screen and (width <= 767px) {
  .home-hero-logo {
    width: 4.1rem;
    margin-bottom: 13rem;
  }
}

.home-hero-title {
  margin-bottom: 5.4rem;
}

@media screen and (width <= 991px) {
  .home-hero-title {
    max-width: 62rem;
  }
}

.home-hero-btn {
  border-radius: var(--border-radius);
  width: 18rem;
}

@media screen and (width <= 767px) {
  .home-hero-btn {
    display: none;
  }
}

.home-hero-bg {
  z-index: 1;
  pointer-events: none;
  width: 100%;
  height: calc(100% + 7rem);
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (width <= 767px) {
  .home-hero-bg {
    width: 100%;
    height: calc(100% + 9rem);
  }
}

.home-hero-bg .img {
  object-fit: cover;
  object-position: 50% 29%;
  z-index: 1;
  background-color: #0006;
  width: 100%;
  height: calc(100vh + 17.1rem);
  margin-top: -19rem;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-hero-bg .img {
    width: 100%;
    height: calc(100vh - 6rem);
    margin-top: 0;
  }
}

@media screen and (width <= 767px) {
  .home-hero-bg .img {
    width: 100%;
    height: calc(100vh - 10rem);
    margin-top: 0;
  }
}

.home-hero-bg-overlay {
  z-index: 2;
  background-color: #01010166;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-hero-bg-grad {
  z-index: 3;
  background: linear-gradient(#01010100 0%, #010101 calc(100% - 8.9rem));
  width: 100%;
  height: 44.7rem;
  min-height: 9.8rem;
  margin-top: -34.9rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.home-hero-bg-test {
  width: 100%;
  height: 100vh;
  animation: 6s fadeIn;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.home-hero-bg-test-wrap, .home-hero-bg-test-wrap div {
  width: 100%;
  height: 100%;
}

.home-hero-bg-test {
  display: none;
}

.home-hero-discover {
  pointer-events: auto;
  margin-left: var(--container-padding);
  align-items: center;
  margin-bottom: 3rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (width <= 991px) {
  .home-hero-discover {
    margin-bottom: 5rem;
  }
}

@media screen and (width <= 767px) {
  .home-hero-discover {
    display: none;
  }
}

@media screen and (width <= 991px) {
  .home-hero-discover-txt {
    display: none;
  }
}

.home-hero-discover-txt.mod-tablet {
  display: none;
}

@media screen and (width <= 991px) {
  .home-hero-discover-txt.mod-tablet {
    display: block;
  }
}

.home-hero-mouse {
  --border-radius: 100vmax;
  background: #ffffff14;
  border-radius: 100vmax;
  justify-content: center;
  align-items: center;
  width: 4.4rem;
  height: 6.6rem;
  margin-right: 1.6rem;
  display: flex;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-hero-mouse {
    width: 4rem;
    height: 6rem;
    margin-right: 1.2rem;
  }
}

.home-hero-mouse-border {
  z-index: 10;
  pointer-events: none;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#ffffff1a 0%, #0000 80%) border-box;
  border-radius: 100vmax;
  width: 100%;
  height: 100%;
  padding: 1px;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

@media screen and (width <= 991px) {
  .home-hero-mouse-border {
    background: linear-gradient(#ff693ab3 0%, #0000 60%) border-box;
  }
}

.home-hero-mouse-slide {
  background: linear-gradient(#0000 0%, #ffffff1a 30% 65%, #0000 100%);
  width: 1px;
  height: 4.2rem;
}

@media screen and (width <= 991px) {
  .home-hero-mouse-slide {
    height: 4rem;
  }
}

.home-hero-mouse-wheel {
  aspect-ratio: 1;
  -ms-animation: moveWheel 2s cubic-bezier(.83, 0, .17, 1) infinite alternate-reverse;
  background-color: #fff;
  border-radius: 50%;
  width: 12.5%;
  animation: 2s cubic-bezier(.83, 0, .17, 1) infinite alternate-reverse moveWheel;
  position: absolute;
  top: 33.33%;
  box-shadow: 0 0 1rem #ffffffbf;
}

.home-main {
  margin-top: calc(var(--height-header) * -1);
  z-index: 2;
  position: relative;
}

.home-main-inner {
  z-index: 1;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-main-inner {
    margin-top: 20rem;
  }
}

.home-showreel {
  z-index: 2;
  contain: paint;
  filter: drop-shadow(0 4rem 4rem #0009);
  height: 200vh;
  padding-bottom: 4rem;
  position: relative;
}

@media screen and (width <= 767px) {
  .home-showreel {
    height: auto;
  }
}

.home-showreel--bg-block {
  z-index: 1;
  background-color: #010101;
  width: 100%;
  height: 50vh;
  position: absolute;
  top: 0;
  left: 0;
}

.home-showreel--wrap {
  z-index: 2;
  top: var(--height-header);
  height: calc(100vh - var(--height-header)  - 4rem);
  padding: 0 var(--container-padding);
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: sticky;
}

@media screen and (width <= 767px) {
  .home-showreel--wrap {
    --container-padding: 2rem;
    height: auto;
    position: relative;
    top: auto;
  }
}

.home-showreel-main--inner {
  z-index: 3;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 991px) {
  .home-showreel-main--inner {
    height: auto;
    padding-block: 28.125%;
  }
}

@media screen and (width <= 767px) {
  .home-showreel-main--inner {
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    padding-block: 0;
  }
}

.home-showreel-other--inner {
  align-items: center;
  gap: 0 1.75vw;
  display: flex;
  position: absolute;
}

@media screen and (width <= 767px) {
  .home-showreel-other--inner {
    display: none;
  }
}

.home-showreel-other--inner:first-child {
  left: 4rem;
}

@media screen and (width <= 991px) {
  .home-showreel-other--inner:first-child {
    left: 9rem;
  }
}

.home-showreel-other--inner:last-child {
  flex-direction: row-reverse;
  right: 4rem;
}

@media screen and (width <= 991px) {
  .home-showreel-other--inner:last-child {
    right: 9rem;
  }
}

.home-showreel-other--inner .home-showreel-item-other {
  aspect-ratio: 2 / 3;
  border-radius: 1.2rem;
  flex: none;
  width: auto;
  overflow: hidden;
}

.home-showreel-other--inner .home-showreel-item-other img {
  object-fit: contain;
  width: auto;
  height: 100%;
}

.home-showreel-other--inner .home-showreel-item-other:first-child {
  width: 5.5vw;
}

@media screen and (width <= 991px) {
  .home-showreel-other--inner .home-showreel-item-other:first-child {
    display: none;
  }
}

.home-showreel-other--inner .home-showreel-item-other:nth-child(2) {
  width: 9.2vw;
}

.home-showreel-other--inner .home-showreel-item-other:nth-child(3) {
  width: 15.36vw;
}

.home-showreel-item {
  border-radius: 1.2rem;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
}

.home-showreel-item-main {
  width: 100%;
}

@media screen and (width <= 991px) {
  .home-showreel-item-main {
    height: auto;
  }
}

.home-showreel-item-main:hover .home-showreel-play-ic svg path:first-child {
  opacity: 0;
  transition-delay: 0;
  transform: translate(101%);
}

.home-showreel-item-main:hover .home-showreel-play-ic svg path:last-child {
  opacity: 1;
  transition-delay: .1s;
  transform: translate(0%);
}

.home-showreel-item-overlay {
  z-index: 4;
  background-color: #010101;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (width <= 767px) {
  .home-showreel-item-overlay {
    display: none;
  }
}

.home-showreel-thumb {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.home-showreel-thumb-link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-showreel-thumb-link-vid {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.home-showreel-thumb-link-vid.hidden {
  opacity: 0;
  pointer-events: none;
}

.home-showreel-thumb .img {
  object-position: center center;
}

.home-showreel-thumb .img.hidden {
  opacity: 0;
  pointer-events: none;
}

.home-showreel-play-wrapper {
  --magnetic-offset: 6rem;
  width: calc(65% + var(--magnetic-offset) * 2);
  padding: var(--magnetic-offset);
  border-radius: 50%;
}

@media screen and (width <= 991px) {
  .home-showreel-play-wrapper {
    width: calc(40% + var(--magnetic-offset) * 2);
  }
}

@media screen and (width <= 767px) {
  .home-showreel-play-wrapper {
    width: calc(20% + var(--magnetic-offset) * 2);
  }
}

.home-showreel-play {
  z-index: 2;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.home-showreel-play-ic {
  aspect-ratio: 1;
  background: #fff3;
  border-radius: 50%;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.home-showreel-play-ic svg {
  -o-transition: opacity .5s ease;
  transition: opacity .5s;
}

.home-showreel-play-ic-play {
  width: 32%;
  margin-left: 5%;
  overflow: hidden;
}

.home-showreel-play-ic-play path {
  -o-transition: transform .65s cubic-bezier(.785, .135, .15, .86), opacity .65s cubic-bezier(.785, .135, .15, .86);
  transition: transform .65s cubic-bezier(.785, .135, .15, .86), opacity .65s cubic-bezier(.785, .135, .15, .86);
}

.home-showreel-play-ic-play path:first-child {
  transition-delay: .1s;
}

.home-showreel-play-ic-play path:last-child {
  opacity: 0;
  transition-delay: 0s;
  transform: translate(-101%);
}

.home-showreel-play-ic-pause {
  opacity: 0;
  width: 32%;
  position: absolute;
}

.home-showreel-play .h0 {
  white-space: nowrap;
  --text-position: 100%;
  position: absolute;
}

.home-showreel-play .h0 span {
  opacity: 1;
  -o-transition: all .4s ease;
  transition: all .4s;
  display: inline-block;
  transform: translateX(0);
}

@media screen and (width <= 991px) {
  .home-showreel-play .h0 {
    --fs-h0: 4.4rem;
    --text-position: calc(100% - 6rem);
  }
}

@media screen and (width <= 767px) {
  .home-showreel-play .h0 {
    --fs-h0: 2.4rem;
    --lh-h0: 1.2rem;
    --text-position: calc(100% - 8.5rem);
  }
}

.home-showreel-play-first {
  pointer-events: none;
  right: var(--text-position);
}

.home-showreel-play-last {
  pointer-events: none;
  left: var(--text-position);
}

.home-showreel-play.hidden {
  pointer-events: none;
}

.home-showreel-play.hidden .home-showreel-play-first span {
  opacity: 0;
  transform: translateX(-5rem);
}

.home-showreel-play.hidden .home-showreel-play-last span {
  opacity: 0;
  transform: translateX(5rem);
}

.home-showreel-play.hidden .home-showreel-play-ic-play {
  opacity: 0;
}

.home-showreel-play.hidden .home-showreel-play-ic-pause {
  opacity: 1;
}

.home-service-preamble {
  z-index: 1;
  height: 650vh;
  margin-top: -200vh;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-service-preamble {
    height: 520vh;
  }
}

@media screen and (width <= 767px) {
  .home-service-preamble {
    height: 520vh;
    margin-top: unset;
  }
}

.home-service-preamble-sticky {
  height: 100vh;
  position: sticky;
  top: 0;
}

.home-service-preamble-bg {
  pointer-events: none;
  opacity: 0;
  width: 100%;
  height: 221.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

@media screen and (width <= 991px) {
  .home-service-preamble-bg {
    width: 100%;
    height: 137.3rem;
  }
}

@media screen and (width <= 767px) {
  .home-service-preamble-bg {
    width: 100%;
    height: 61.5rem;
  }
}

.home-service-preamble-inner {
  z-index: 2;
  justify-content: center;
  place-items: center;
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
}

.home-service-preamble-inner-item {
  text-align: center;
  opacity: 0;
  pointer-events: none;
  grid-area: 1 / 1 / 2 / 2;
  max-width: 123.2rem;
}

@media screen and (width <= 991px) {
  .home-service-preamble-inner-item {
    max-width: 64.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-service-preamble-inner-item {
    max-width: 33rem;
  }
}

.home-service-preamble-inner-item.active {
  opacity: 1;
  pointer-events: auto;
}

@media screen and (width <= 767px) {
  .home-service-preamble-inner-item h1 {
    font-size: 3.6rem;
  }
}

.home-service-preamble-inner-item:nth-child(3) {
  max-width: 112.2rem;
}

@media screen and (width <= 991px) {
  .home-service-preamble-inner-item:nth-child(3) {
    max-width: 55.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-service-preamble-inner-item:nth-child(3) {
    max-width: 33rem;
  }
}

.home-service-preamble-inner-item:nth-child(4) {
  max-width: 112.2rem;
}

@media screen and (width <= 991px) {
  .home-service-preamble-inner-item:nth-child(4) {
    max-width: 55.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-service-preamble-inner-item:nth-child(4) {
    max-width: 33rem;
  }

  .home-service-preamble-inner-item:nth-child(4) h1 {
    font-size: 3.4rem;
  }
}

.home-service-main {
  grid-column: 1 / -1;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-service-main {
    padding-top: 0;
  }
}

.home-service-main-wrap {
  margin-top: -10vh;
  padding-bottom: 10rem;
}

@media screen and (width <= 991px) {
  .home-service-main-wrap {
    margin-top: -30vh;
  }
}

@media screen and (width <= 767px) {
  .home-service-main-wrap {
    margin-top: -15vh;
  }
}

.home-service-listing {
  grid-column: 1 / -1;
}

.home-service-item {
  cursor: pointer;
  align-items: center;
  padding: 4rem 0;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-service-item {
    padding: 5.6rem 0;
  }
}

@media screen and (width <= 767px) {
  .home-service-item {
    padding: 3.2rem 0;
  }
}

.home-service-item:hover .home-service-item-toggle svg g path:first-child {
  fill-opacity: 1;
  fill: #f5450d !important;
}

.home-service-item.active .home-service-item-title {
  color: #f5450d;
}

.home-service-item.active .home-service-item-toggle svg {
  transform: rotate(-180deg);
}

.home-service-item.active .home-service-item-toggle svg g path:first-child {
  fill-opacity: 1;
  transform: scale(.9);
  fill: #f5450d !important;
}

.home-service-item.active .home-service-item-toggle svg g path:nth-child(2) {
  opacity: 0;
}

.home-service-item.active-question {
  padding-bottom: 3rem;
}

.home-service-item-title {
  -o-transition: color .4s ease-in-out;
  transition: color .4s ease-in-out;
}

.home-service-item-title span {
  opacity: .2;
  font-weight: 400;
}

.home-service-item-question {
  grid-column: 2 / 16;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-service-item-question {
    grid-column: 1 / 13;
  }
}

@media screen and (width <= 767px) {
  .home-service-item-question {
    grid-column: 1 / -1;
  }
}

.home-service-item-toggle {
  width: 6rem;
  height: 6rem;
}

@media screen and (width <= 991px) {
  .home-service-item-toggle {
    width: 4rem;
    height: 4rem;
  }
}

@media screen and (width <= 767px) {
  .home-service-item-toggle {
    width: 3.2rem;
    height: 3.2rem;
  }
}

.home-service-item-toggle svg {
  -o-transition: all .4s cubic-bezier(.455, .03, .515, .955);
  transition: all .4s cubic-bezier(.455, .03, .515, .955);
}

.home-service-item-toggle svg g path {
  transform-origin: center;
  -o-transition: all .4s cubic-bezier(.455, .03, .515, .955);
  transition: all .4s cubic-bezier(.455, .03, .515, .955);
}

.home-service-item-desc {
  grid-area: 2 / 2 / auto / 9;
  margin-top: 3rem;
}

@media screen and (width <= 991px) {
  .home-service-item-desc {
    grid-column: 1 / 9;
    margin-top: 2rem;
    margin-right: 1.6rem;
  }

  .home-service-item-desc-frame {
    margin-top: 3rem;
  }
}

@media screen and (width <= 767px) {
  .home-service-item-desc {
    opacity: 1;
    grid-column: 1 / -1;
    margin-right: 0;
    display: none;
    overflow: hidden;
  }
}

.home-service-item-desc-frame {
  margin-top: 3rem;
}

@media screen and (width <= 767px) {
  .home-service-item-desc-frame {
    margin-top: 2rem;
  }
}

.home-service-item .line {
  position: absolute;
  top: 0;
}

@media (hover: hover) {
  .home-service-item:hover .home-service-item-title {
    color: #f5450d;
    height: 100%;
  }

  .home-service-item:hover .home-service-item-desc {
    transform: translate(0);
  }
}

.home-service-thumb {
  pointer-events: none;
  grid-template-columns: 1fr;
  display: grid;
  position: absolute;
  top: 0;
  left: 53%;
}

@media screen and (width <= 991px) {
  .home-service-thumb {
    left: 30.8rem;
  }
}

@media screen and (width <= 767px) {
  .home-service-thumb {
    display: none;
  }
}

.home-service-thumb-item {
  opacity: 0;
  -o-transition: opacity .3s ease-in, transform .2s cubic-bezier(.45, .5, .55, 1);
  grid-area: 1 / 1 / 2 / 2;
  width: 32rem;
  transition: opacity .3s ease-in, transform .2s cubic-bezier(.45, .5, .55, 1);
  overflow: hidden;
  transform: scale(1.1);
}

@media screen and (width <= 991px) {
  .home-service-thumb-item {
    width: 16.5rem;
  }
}

@media screen and (width <= 767px) {
  .home-service-thumb-item {
    width: 14.5rem;
  }
}

.home-service-thumb-item .img.cloner {
  --gap-horizontal: 20px;
  --gap-vertical: 2px;
  --time-anim: 2.25s;
  --blend-mode-1: none;
  --blend-mode-2: none;
  --blend-mode-3: none;
  --blend-mode-4: none;
  --blend-mode-5: none;
  --blend-color-1: transparent;
  --blend-color-2: transparent;
  --blend-color-3: transparent;
  --blend-color-4: transparent;
  --blend-color-5: transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.home-service-thumb-item.active {
  opacity: 1;
  -o-transition: opacity .8s ease-out, transform .2s cubic-bezier(.45, .5, .55, 1);
  transition: opacity .8s ease-out, transform .2s cubic-bezier(.45, .5, .55, 1);
  transform: scale(1.1);
}

.home-process {
  padding-bottom: 25rem;
  display: none;
  overflow: hidden;
}

@media screen and (width <= 991px) {
  .home-process {
    padding-bottom: 18rem;
  }
}

@media screen and (width <= 767px) {
  .home-process {
    padding-bottom: 12rem;
  }
}

.home-process-title--wrap {
  flex-direction: column;
  grid-column: 2 / 7;
  align-self: start;
  align-items: start;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-process-title--wrap {
    grid-column: 1 / 7;
  }
}

@media screen and (width <= 767px) {
  .home-process-title--wrap {
    grid-column: 1 / -1;
  }
}

.home-process-title {
  margin-bottom: 5.8rem;
}

@media screen and (width <= 767px) {
  .home-process-title {
    margin-bottom: 3.2rem;
  }
}

.home-process-btn--wrapper {
  grid-area: 2 / 2 / 2 / 7;
}

@media screen and (width <= 991px) {
  .home-process-btn--wrapper {
    grid-column: 1 / 7;
  }
}

@media screen and (width <= 767px) {
  .home-process-btn--wrapper {
    grid-area: 4 / 1 / 4 / -1;
  }

  .home-process-btn--wrapper .btn {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  .home-process-btn--wrapper .btn .txt-btn {
    padding: 1.9rem 2.7rem;
  }
}

.home-process-desc {
  grid-column: 9 / 13;
  align-self: start;
  margin-top: 1.4rem;
}

@media screen and (width <= 991px) {
  .home-process-desc {
    grid-column: 7 / -1;
  }
}

@media screen and (width <= 767px) {
  .home-process-desc {
    grid-area: 2 / 1 / 2 / -1;
  }
}

.home-process-step-listing {
  grid-area: 2 / 2 / 2 / 16;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 2rem;
  margin-top: -22.4rem;
  display: grid;
}

@media screen and (width <= 991px) {
  .home-process-step-listing {
    grid-column: 1 / -1;
    gap: 0 2rem;
    margin-top: 0;
  }
}

@media screen and (width <= 767px) {
  .home-process-step-listing {
    grid-area: 3 / 1 / 3 / -1;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.4rem 0;
    margin-top: 10rem;
    margin-bottom: 4rem;
  }
}

.home-process-step {
  transform-origin: 0 0;
  border-radius: 2rem;
  position: relative;
}

.home-process-step * {
  transform-origin: 0 0;
}

.home-process-step:nth-child(2n) .home-process-step-background, .home-process-step:nth-child(2n) .home-process-step-background * {
  transform-origin: 100% 0;
}

.home-process-step-background {
  pointer-events: none;
  transform-origin: 0 0;
  background-color: #ffffff0d;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-process-step-background:before {
  content: "";
  pointer-events: none;
  border-radius: inherit;
  -webkit-mask-composite: xor;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#fff3 0%, #fff0 100%) border-box;
  width: 100%;
  height: 100%;
  padding: 1px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.home-process-step:after {
  content: "";
  width: 0;
  height: 100%;
  padding-bottom: 91.67%;
  display: block;
}

.home-process-step:first-child, .home-process-step:nth-child(3) {
  grid-column: 2 / 3;
}

.home-process-step:nth-child(3) {
  grid-row: 3 / 4;
}

@media screen and (width <= 767px) {
  .home-process-step {
    grid-column: 1 / -1 !important;
  }
}

.home-process-step-content {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 8rem 6rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (width <= 991px) {
  .home-process-step-content {
    padding: 3.2rem 2.4rem;
  }
}

@media screen and (width <= 767px) {
  .home-process-step-content {
    padding: 3.2rem 2.4rem;
  }
}

.home-process-step-img {
  align-items: center;
  width: 40rem;
  display: flex;
  position: absolute;
  top: -6.9rem;
  right: 4rem;
}

.home-process-step-img--wrapper {
  position: relative;
}

@media screen and (width <= 991px) {
  .home-process-step-img {
    width: 16.8rem;
    top: -5.2rem;
    right: 0;
  }
}

.home-process-step-img img.cloner {
  --gap-horizontal: 20px;
  --gap-vertical: 2px;
  --time-anim: 2.25s;
  --blend-mode-1: none;
  --blend-mode-2: none;
  --blend-mode-3: none;
  --blend-mode-4: none;
  --blend-mode-5: none;
  --blend-color-1: transparent;
  --blend-color-2: transparent;
  --blend-color-3: transparent;
  --blend-color-4: transparent;
  --blend-color-5: transparent;
  animation: glitch-anim-1 var(--time-anim) infinite linear alternate;
  -webkit-animation: glitch-anim-1 var(--time-anim) infinite linear alternate;
  -moz-animation: glitch-anim-1 var(--time-anim) infinite linear alternate;
  -ms-animation: glitch-anim-1 var(--time-anim) infinite linear alternate;
  -o-animation: glitch-anim-1 var(--time-anim) infinite linear alternate;
  transform-origin: center;
  position: absolute;
}

.home-process-step-img img.cloner:nth-child(2) {
  animation-name: glitch-anim-1;
  animation-delay: calc(var(--time-anim)  + 4s);
  animation-duration: calc(var(--time-anim)  + 5s);
}

.home-process-step-img img.cloner:nth-child(3) {
  animation-name: glitch-anim-2;
  animation-delay: calc(var(--time-anim));
}

.home-process-step-img img.cloner:nth-child(4) {
  animation-name: glitch-anim-3;
  animation-duration: calc(var(--time-anim)  + 2s);
  animation-delay: calc(var(--time-anim)  + 5s);
}

.home-process-step-img img.cloner:nth-child(5) {
  animation-delay: calc(var(--time-anim)  + 1s);
  animation-name: glitch-anim-flash;
}

.home-process-step-title {
  margin-bottom: 3.2rem;
}

.home-portfolio {
  background: linear-gradient(#f58a0d 0%, #f5450d 30% 100%);
  display: grid;
  position: relative;
}

.home-portfolio-project {
  grid-area: 1 / 1 / 1 / -1;
  padding-bottom: calc(55.24rem + 20vh);
  display: none;
  overflow: hidden;
}

.home-portfolio-project-list {
  --grid-portfolio-column: 8;
  --width-column: calc(100vw / var(--grid-portfolio-column));
  grid-template-rows: var(--width-column);
  grid-template-columns: repeat(var(--grid-portfolio-column), var(--width-column));
  grid-auto-rows: var(--width-column);
  grid-auto-columns: 1fr;
  padding-top: 100vh;
  display: grid;
  inset: 0;
}

@media screen and (width <= 991px) {
  .home-portfolio-project-list {
    --grid-portfolio-column: 6;
  }
}

@media screen and (width <= 767px) {
  .home-portfolio-project-list {
    --grid-portfolio-column: 4;
  }
}

.home-portfolio-project-item:first-child {
  will-change: transform;
  grid-area: 1 / 4;
  overflow: hidden;
}

.home-portfolio-project-item:first-child img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(2) {
  will-change: transform;
  grid-area: 1 / 1;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(2) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(3) {
  will-change: transform;
  grid-area: 2 / 8;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(3) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(4) {
  will-change: transform;
  grid-area: 2 / 5;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(4) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(5) {
  will-change: transform;
  grid-area: 3 / 3;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(5) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(6) {
  will-change: transform;
  grid-area: 3 / 1;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(6) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(7) {
  will-change: transform;
  grid-area: 4 / 2;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(7) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(8) {
  will-change: transform;
  grid-area: 4 / 5;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(8) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(9) {
  will-change: transform;
  grid-area: 5 / 6;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(9) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(10) {
  will-change: transform;
  grid-area: 5 / 8;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(10) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(11) {
  will-change: transform;
  grid-area: 6 / 2;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(11) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(12) {
  will-change: transform;
  grid-area: 7 / 3;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(12) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(13) {
  will-change: transform;
  grid-area: 8 / 7;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(13) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(14) {
  will-change: transform;
  grid-area: 8 / 2;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(14) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(15) {
  will-change: transform;
  grid-area: 9 / 1;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(15) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(16) {
  will-change: transform;
  grid-area: 9 / 6;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(16) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(17) {
  will-change: transform;
  grid-area: 10 / 4;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(17) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(18) {
  will-change: transform;
  grid-area: 11 / 2;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(18) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(19) {
  will-change: transform;
  grid-area: 11 / 5;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(19) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(20) {
  will-change: transform;
  grid-area: 12 / 6;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(20) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(21) {
  will-change: transform;
  grid-area: 13 / 3;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(21) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(22) {
  will-change: transform;
  grid-area: 14 / 5;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(22) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(23) {
  will-change: transform;
  grid-area: 15 / 1;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(23) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(24) {
  will-change: transform;
  grid-area: 16 / 2;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(24) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(25) {
  will-change: transform;
  grid-area: 16 / 7;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(25) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(26) {
  will-change: transform;
  grid-area: 17 / 8;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(26) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(27) {
  will-change: transform;
  grid-area: 18 / 3;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(27) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(28) {
  will-change: transform;
  grid-area: 19 / 5;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(28) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(29) {
  will-change: transform;
  grid-area: 20 / 4;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(29) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(30) {
  will-change: transform;
  grid-area: 21 / 2;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(30) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(31) {
  will-change: transform;
  grid-area: 22 / 7;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(31) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(32) {
  will-change: transform;
  grid-area: 23 / 1;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(32) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(33) {
  will-change: transform;
  grid-area: 24 / 5;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(33) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(34) {
  will-change: transform;
  grid-area: 25 / 4;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(34) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(35) {
  will-change: transform;
  grid-area: 26 / 2;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(35) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(36) {
  will-change: transform;
  grid-area: 27 / 3;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(36) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(37) {
  will-change: transform;
  grid-area: 28 / 6;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(37) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(38) {
  will-change: transform;
  grid-area: 29 / 5;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(38) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(39) {
  will-change: transform;
  grid-area: 30 / 4;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(39) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(40) {
  will-change: transform;
  grid-area: 31 / 1;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(40) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(41) {
  will-change: transform;
  grid-area: 32 / 6;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(41) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(42) {
  will-change: transform;
  grid-area: 33 / 3;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(42) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(43) {
  will-change: transform;
  grid-area: 34 / 5;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(43) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(44) {
  will-change: transform;
  grid-area: 35 / 1;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(44) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(45) {
  will-change: transform;
  grid-area: 36 / 8;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(45) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(46) {
  will-change: transform;
  grid-area: 37 / 6;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(46) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(47) {
  will-change: transform;
  grid-area: 38 / 3;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(47) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(48) {
  will-change: transform;
  grid-area: 39 / 5;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(48) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-project-item:nth-child(49) {
  will-change: transform;
  grid-area: 40 / 4;
  overflow: hidden;
}

.home-portfolio-project-item:nth-child(49) img {
  background-position: 50%;
  background-size: cover;
}

.home-portfolio-content {
  color: #010101;
  grid-area: 1 / 1 / 1 / -1;
}

.home-portfolio-content .home-portfolio-content-stickywrap {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  display: flex;
  position: sticky;
  top: 0;
}

.home-portfolio-content-intro {
  text-align: center;
  padding-top: calc(50vh - 9.2rem);
  padding-bottom: 18.8rem;
  position: sticky;
  top: 0;
}

@media screen and (width <= 991px) {
  .home-portfolio-content-intro {
    padding-top: calc(50vh - 7.1rem);
    padding-bottom: 18.4rem;
  }
}

.home-portfolio-content-intro .home-portfolio-content-title {
  margin-bottom: 2rem;
  display: grid;
}

@media screen and (width <= 991px) {
  .home-portfolio-content-intro .home-portfolio-content-title {
    margin-bottom: 1.6rem;
  }
}

.home-portfolio-content-intro .home-portfolio-content-title .h0 {
  color: #010101;
  opacity: 0;
  -o-transition: opacity .5s ease-in;
  grid-area: 1 / 1 / -1 / -1;
  font-weight: bold;
  transition: opacity .5s ease-in;
}

.home-portfolio-content-intro .home-portfolio-content-title .h0.active {
  opacity: 1;
  -o-transition: opacity .5s ease-in .2s;
  transition: opacity .5s ease-in .2s;
}

.home-portfolio-content-intro .home-portfolio-content-desc {
  width: 100%;
  max-width: 65rem;
  margin: 0 auto;
  display: grid;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-portfolio-content-intro .home-portfolio-content-desc {
    --fs-20: 1.8rem;
    --lh-20: 1em;
    max-width: 45.7rem;
  }
}

@media screen and (width <= 767px) {
  .home-portfolio-content-intro .home-portfolio-content-desc {
    max-width: 31.7rem;
  }
}

.home-portfolio-content-intro .home-portfolio-content-desc p {
  grid-area: 1 / 1 / -1 / -1;
  max-width: 53rem;
}

.home-portfolio-content-intro .home-portfolio-content-desc p:nth-child(n+2) {
  opacity: 0;
}

.home-portfolio-content-award {
  text-align: center;
  margin-bottom: 8rem;
}

@media screen and (width <= 991px) {
  .home-portfolio-content-award {
    margin-bottom: 4.95rem;
  }
}

.home-portfolio-content-award .txt {
  color: #313131;
  margin-bottom: 2rem;
}

.home-portfolio-content-award-list {
  align-items: center;
  gap: 4.1rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-portfolio-content-award-list {
    gap: 2.4rem;
  }
}

.home-portfolio-content-award-logo {
  width: auto;
  height: 3.2rem;
}

@media screen and (width <= 991px) {
  .home-portfolio-content-award-logo {
    height: 1.6rem;
  }
}

.home-project {
  grid-row: 2 / 2;
  padding-top: 8rem;
  padding-bottom: 10rem;
  display: grid;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-project {
    padding-bottom: 5.5rem;
  }
}

@media screen and (width <= 767px) {
  .home-project {
    padding-top: 10rem;
    padding-bottom: 1rem;
  }
}

.home-project-wrap {
  color: #000;
  background-color: #f5450d;
  grid-area: 1 / 1 / 1 / -1;
}

.home-project-wrap-top {
  pointer-events: none;
  color: #fff;
  -o-transition: clip-path .4s ease-out;
  z-index: 2;
  background-color: #010101;
  transition: clip-path .4s ease-out;
}

.home-project-wrap-top .home-project-item-industry, .home-project-wrap-top .home-project-item-name, .home-project-wrap-top .home-project-item-view {
  color: #fff;
}

.home-project-wrap-top .home-project-item-view img {
  filter: invert(0);
}

.home-project-item {
  -o-transition: color .3s ease-out;
  cursor: pointer;
  justify-content: space-between;
  align-items: flex-end;
  padding: 6rem 0;
  transition: color .3s ease-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 991px) {
  .home-project-item {
    padding: 4rem 0;
  }
}

@media screen and (width <= 767px) {
  .home-project-item {
    padding: 2.4rem 0;
  }
}

.home-project-item .hover-line {
  pointer-events: auto;
}

.home-project-item-industry {
  color: #313131;
  grid-column: 2 / -1;
}

@media screen and (width <= 991px) {
  .home-project-item-industry {
    grid-column: 1 / -1;
  }
}

.home-project-item-name {
  color: #000;
  -o-transition: color .3s ease-out;
  grid-column: 2 / 12;
  margin-top: 2rem;
  transition: color .3s ease-out;
}

@media screen and (width <= 991px) {
  .home-project-item-name {
    grid-column: 1 / 7;
    margin-top: .8rem;
  }
}

@media screen and (width <= 767px) {
  .home-project-item-name {
    --fs-h2: 3.2rem;
    --lh-h2: 1.1em;
    grid-column: 1 / 4;
  }
}

.home-project-item-view {
  text-align: end;
  white-space: nowrap;
  color: #313131;
  grid-column: 15 / 16;
  align-self: end;
}

@media screen and (width <= 991px) {
  .home-project-item-view {
    --fs-18: 1.6rem;
    --lh-18: 1em;
    grid-column: 8 / 13;
  }
}

@media screen and (width <= 767px) {
  .home-project-item-view {
    justify-content: flex-end;
    place-self: end;
    align-items: flex-end;
    height: 4.8rem;
    margin-bottom: -1.2rem;
    margin-right: -1.2rem;
    padding: 1.2rem;
    display: flex;
  }

  .home-project-item-view p {
    display: none;
  }
}

.home-project-item-view img {
  filter: invert();
  width: auto;
  height: 100%;
  display: none;
}

@media screen and (width <= 767px) {
  .home-project-item-view img {
    display: inline;
  }
}

.home-project-item .line {
  z-index: 999;
  background-color: #0003;
  width: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.home-project-item .line:nth-child(2n) {
  top: auto;
  bottom: 0;
}

.home-project-thumb {
  z-index: 9999999;
  pointer-events: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  width: 47.12rem;
  height: 28.28rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 80rem;
  overflow: hidden;
}

@media screen and (width <= 991px) {
  .home-project-thumb {
    width: 22.8rem;
    height: 12.84rem;
    left: 36.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-project-thumb {
    width: 11.3rem;
    height: 6.1rem;
    left: 21rem;
  }
}

.home-project-thumb-wrap {
  opacity: 0;
  -o-transition: opacity .5s ease-out;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .5s ease-out;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.home-project-thumb-wrap.active {
  opacity: 1;
}

.home-curtain {
  --block-height: 70rem;
  height: var(--block-height);
  z-index: 3;
  background-color: #f5450d;
  margin-top: -2px;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-curtain {
    --block-height: 55rem;
  }
}

@media screen and (width <= 767px) {
  .home-curtain {
    --block-height: 40rem;
  }
}

.home-curtain-inner {
  width: 100%;
  height: calc(var(--block-height) / 10 + 2px);
  transform-origin: top;
  background-color: #010101;
  margin-bottom: -2px;
  transform: scaleY(0);
}

.home-benefit {
  z-index: 4;
  background-color: #010101;
  height: 400vh;
  margin-top: -10vh;
  margin-bottom: 8.6rem;
  position: relative;
}

@media screen and (width <= 767px) {
  .home-benefit {
    height: auto;
    margin-bottom: 8.2rem;
  }
}

.home-benefit-stick {
  top: var(--height-header);
  height: calc(100vh - var(--height-header));
  position: sticky;
}

@media screen and (width <= 991px) {
  .home-benefit-stick {
    height: 60vh;
    top: calc((40vh - var(--height-header)) / 2 + var(--height-header));
  }
}

@media screen and (width <= 767px) {
  .home-benefit-stick {
    height: auto;
    position: static;
    top: auto;
  }
}

.home-benefit .container {
  height: 100%;
}

.home-benefit--wrap {
  --border-radius: 2rem;
  border-radius: 2rem;
  height: 100%;
  position: relative;
}

.home-benefit--wrap:before {
  content: "";
  border-radius: inherit;
  -webkit-mask-composite: xor;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#fff3 0%, #fff0 100%) border-box;
  width: 100%;
  height: 100%;
  padding: 1px;
  position: absolute;
  inset: -1px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

@media screen and (width <= 767px) {
  .home-benefit--wrap:before {
    content: none;
  }
}

.home-benefit-list {
  border-radius: 2rem;
  justify-content: start;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 767px) {
  .home-benefit-list {
    border-radius: 0;
    flex-direction: column;
    padding-bottom: 0;
    overflow: visible;
  }
}

.home-benefit-item {
  z-index: 3;
  background-color: #010101;
  padding-top: 4.2rem;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-benefit-item {
    padding-top: 3.2rem;
    padding-left: 2.4rem;
  }
}

.home-benefit-item-overlay {
  z-index: 2;
  background: linear-gradient(270deg, #010101 11.76%, #01010100 95.72%);
  width: calc(100% - 12rem);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (width <= 767px) {
  .home-benefit-item-overlay {
    display: none;
  }
}

.home-benefit-main {
  z-index: 1;
  flex: none;
  width: 38.6vw;
  padding-left: 4rem;
}

@media screen and (width <= 991px) {
  .home-benefit-main {
    width: 45.6vw;
  }
}

@media screen and (width <= 767px) {
  .home-benefit-main {
    width: 100%;
    margin-bottom: 5.4rem;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .home-benefit-main-title > br {
    display: none;
  }
}

.home-benefit-main-btn {
  transform-origin: 0 0;
  width: fit-content;
}

.home-benefit-main-sub {
  color: #fffc;
  transform-origin: 0 0;
  max-width: 29.1rem;
  margin-top: 6.4rem;
  margin-bottom: 3.2rem;
}

@media screen and (width <= 767px) {
  .home-benefit-main-sub {
    margin-top: 2.4rem;
    font-weight: 400;
  }
}

.home-benefit-other-wrap {
  z-index: 2;
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  position: relative;
}

@media screen and (width <= 767px) {
  .home-benefit-other-wrap {
    flex-direction: column;
    row-gap: 1.6rem;
    display: flex;
  }
}

.home-benefit-other {
  --border-radius: 0rem;
  border-style: none none none solid;
  border-width: 0 0 0 1px;
  border-top-color: currentColor;
  border-bottom-color: currentColor;
  border-right-color: currentColor;
  border-image: linear-gradient(#fff3 0%, #fff0 100%) 1 100%;
  flex-direction: column;
  width: 25.38vw;
  padding-inline: 6rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-benefit-other {
    width: 35vw;
    padding-inline: 2.4rem 1.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-benefit-other {
    border: none;
    border-radius: 2rem;
    width: 100%;
    padding: 3.2rem 2.4rem;
    position: relative;
  }

  .home-benefit-other:before {
    pointer-events: none;
    content: "";
    border-radius: inherit;
    -webkit-mask-composite: xor;
    -webkit-mask-composite: xor;
    -webkit-mask-source-type: auto, auto;
    background: linear-gradient(#fff3 0%, #fff0 100%) border-box;
    width: 100%;
    height: 100%;
    padding: 1px;
    position: absolute;
    inset: 0;
    -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    -webkit-mask-position: 0 0, 0 0;
    -webkit-mask-size: auto, auto;
    -webkit-mask-repeat: repeat, repeat;
    -webkit-mask-clip: content-box, border-box;
    -webkit-mask-origin: content-box, border-box;
    mask-composite: exclude;
    mask-mode: match-source, match-source;
  }
}

.home-benefit-other:last-child {
  width: 26vw;
}

@media screen and (width <= 767px) {
  .home-benefit-other:last-child {
    width: 100%;
  }
}

.home-benefit-other-img {
  opacity: 1;
  z-index: 3;
  will-change: transform, opacity;
  transform-origin: 0 0;
  width: 21rem;
  height: auto;
  margin-block: 3.2rem;
  margin-inline: 0;
  position: relative;
}

.home-benefit-other-img img {
  object-fit: contain;
  width: 90%;
  height: 100%;
}

.home-benefit-other-img.hidden {
  opacity: 0 !important;
}

@media screen and (width <= 991px) {
  .home-benefit-other-img {
    opacity: 1;
    width: 16rem;
    height: auto;
  }
}

.home-benefit-other-label {
  color: #fffc;
  margin-bottom: .4rem;
}

.home-benefit-other-sub {
  z-index: 1;
  position: relative;
}

.home-benefit-other-sub-txt {
  max-width: 31rem;
  margin-bottom: 3.2rem;
}

@media screen and (width <= 991px) {
  .home-benefit-other-sub-txt {
    --fs-20: 1.4rem;
    --lh-20: 1.3em;
  }
}

.home-benefit-other-sub-btn {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  display: flex;
  position: relative;
}

.home-benefit-other-sub-btn.hidden {
  opacity: 0;
  pointer-events: none;
  display: none;
}

@media screen and (width <= 991px) {
  .home-benefit-other-sub-btn {
    padding: 1rem;
  }
}

.home-benefit-other-sub-btn span {
  pointer-events: none;
  border-radius: inherit;
  -webkit-mask-composite: xor;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#fff3 0%, #fff0 100%) border-box;
  width: 100%;
  height: 100%;
  padding: 1px;
  position: absolute;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

@media screen and (width <= 991px) {
  .home-benefit-other-sub-btn img {
    width: 2rem;
    height: 2rem;
  }
}

.home-testi {
  z-index: 5;
  padding-bottom: calc(21rem - var(--height-header));
  margin-top: -70vh;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-testi {
    margin-top: -120vh;
    padding-bottom: 0;
  }
}

@media screen and (width <= 767px) {
  .home-testi {
    margin-top: unset;
    padding-bottom: calc(12rem - var(--height-header));
    overflow: hidden;
  }
}

.home-testi-sticky {
  height: 100vh;
  padding-top: var(--height-header);
  padding-bottom: 4rem;
  position: sticky;
  top: 0;
}

.home-testi-sticky .grid {
  height: 100%;
}

@media screen and (width <= 991px) {
  .home-testi-sticky {
    padding-bottom: 0;
  }
}

@media screen and (width <= 767px) {
  .home-testi-sticky {
    height: auto;
    padding-top: 0;
    position: static;
    top: auto;
  }
}

.home-testi-text-wrap {
  flex-direction: column;
  grid-column: 2 / 7;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  transform: translateY(-10rem);
}

@media screen and (width <= 991px) {
  .home-testi-text-wrap {
    grid-column: 1 / 6;
  }
}

@media screen and (width <= 767px) {
  .home-testi-text-wrap {
    text-align: center;
    grid-column: 1 / -1;
    align-items: center;
    margin-bottom: 6rem;
    transform: translateY(0);
  }
}

.home-testi-heading {
  max-width: 46rem;
  margin-bottom: 6rem;
}

@media screen and (width <= 991px) {
  .home-testi-heading {
    max-width: 25rem;
    margin-bottom: 3.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-testi-heading {
    --fs-h1: 5rem;
    --lh-h1: 1em;
    max-width: 46rem;
  }
}

.home-testi-content {
  grid-column: 8 / 17;
  grid-template-columns: repeat(9, 1fr);
  gap: 0 2rem;
  display: grid;
}

@media screen and (width <= 991px) {
  .home-testi-content {
    grid-column: 7 / -1;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (width <= 767px) {
  .home-testi-content {
    grid-column: 1 / -1;
    padding-inline: 2rem;
    display: block;
  }
}

.home-testi-content-wrap {
  grid-column: span 8;
}

@media screen and (width <= 991px) {
  .home-testi-content-wrap {
    grid-column: span 1;
  }
}

.home-testi-content-listing {
  perspective: 5000px;
  pointer-events: none;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

@media screen and (width <= 767px) {
  .home-testi-content-listing {
    align-items: start;
  }
}

.home-testi-content-item {
  --border-radius: 2rem;
  pointer-events: auto;
  transform-origin: top;
  max-height: 74.2rem;
  height: calc(100vh - var(--height-header)  - 4rem);
  background: linear-gradient(#121212 0%, #080808 100%);
  border-radius: 2rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 10rem;
  display: flex;
  position: absolute;
}

@media screen and (width <= 991px) {
  .home-testi-content-item {
    height: 46.4rem;
    padding: 3.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-testi-content-item {
    transform-origin: center;
    -o-transition: all .5s ease-out;
    border-radius: 1rem;
    flex: none;
    height: 55rem;
    max-height: 60rem;
    transition: all .5s ease-out;
    position: relative;
  }

  .home-testi-content-item.swiper-slide:has( + .swiper-slide.swiper-slide-active) {
    transform: translateX(1rem)scale(.95);
  }

  .home-testi-content-item.swiper-slide.swiper-slide-active + .swiper-slide {
    transform: translateX(-1rem)scale(.95);
  }

  .home-testi-content-item.swiper-slide-active {
    transform: scale(1)translateX(0);
  }
}

.home-testi-content-item.active {
  opacity: 1;
  pointer-events: auto;
}

.home-testi-content-item-fg {
  z-index: 4;
  background: linear-gradient(to top, #010101 .25px, #01010100 .5px) 0 0 / 100% 200%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-testi-content-item:before {
  content: "";
  pointer-events: none;
  border-radius: inherit;
  -webkit-mask-composite: xor;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#ffffff1a 0%, #fff0 100%) border-box;
  padding: 1px;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.home-testi-content-item-txt {
  margin-bottom: 4rem;
}

@media screen and (width <= 991px) {
  .home-testi-content-item-txt {
    --fs-h6: 2rem;
    --lh-h6: 1.2em;
    margin-bottom: 2.44rem;
  }
}

@media screen and (width <= 767px) {
  .home-testi-content-item-txt {
    --fs-h6: 1.8rem;
    --lh-h6: 1.4em;
    margin-bottom: 2.4rem;
  }
}

.home-testi-content-item-author {
  align-items: center;
  margin-top: 4.3rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-testi-content-item-author {
    margin-top: 3.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-testi-content-item-author {
    margin-top: 2.4rem;
  }
}

.home-testi-content-item-author-avt {
  border-radius: 50%;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
  display: flex;
  overflow: hidden;
}

@media screen and (width <= 991px) {
  .home-testi-content-item-author-avt {
    width: 4.8rem;
    height: 4.8rem;
  }
}

.home-testi-content-item-author-avt .img {
  object-fit: cover;
  object-position: 50% 50%;
}

.home-testi-content-item-author-info {
  flex-direction: column;
  display: flex;
}

.home-testi-content-item-author-info-name {
  margin-bottom: .4rem;
}

@media screen and (width <= 991px) {
  .home-testi-content-item-author-info-name {
    --fs-h6: 2rem;
    --lh-h6: 1.2em;
  }

  .home-testi-content-item-author-info-role {
    --fs-18: 1.4rem;
    --lh-18: 1.3em;
  }
}

.home-testi-content-progress {
  top: calc(50vh - var(--height-header));
  background-color: #ffffff1a;
  border-radius: 100vmax;
  place-self: center end;
  width: .5rem;
  height: 20rem;
  position: absolute;
  right: 2.4rem;
  transform: translateY(-50%);
}

@media screen and (width <= 767px) {
  .home-testi-content-progress {
    grid-column: 1 / -1;
    width: 20rem;
    height: .5rem;
    margin: 2.4rem auto auto;
    position: relative;
    top: auto;
    right: auto;
  }
}

.home-testi-content-progress-inner {
  background-color: #f5450d;
  border-radius: 100vmax;
  height: 4rem;
}

@media screen and (width <= 767px) {
  .home-testi-content-progress-inner {
    display: none;
  }
}

.home-testi-content-progress .swiper-scrollbar-drag {
  background-color: #f5450d;
  border-radius: 100vmax;
  width: 4rem;
  height: 100%;
}

.home-pricing {
  padding-top: var(--height-header);
  z-index: 5;
  background-color: #010101;
  padding-bottom: 15rem;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-pricing {
    padding-top: 0;
    padding-bottom: calc(15.6rem - var(--height-header));
  }
}

@media screen and (width <= 767px) {
  .home-pricing {
    padding-top: var(--height-header);
    margin-top: unset;
  }
}

.home-pricing--wrap {
  text-align: center;
  flex-direction: column;
  grid-column: 2 / 16;
  align-items: center;
  padding-top: 8rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-pricing--wrap {
    grid-column: 1 / -1;
    padding-top: 6rem;
  }
}

.home-pricing-text {
  text-align: center;
  flex-direction: column;
  grid-column: 2 / 16;
  align-items: center;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-pricing-text {
    grid-column: 1 / -1;
    padding: 0;
  }
}

.home-pricing-text-title {
  max-width: 125.3rem;
  margin-bottom: 3.2rem;
}

@media screen and (width <= 991px) {
  .home-pricing-text-title {
    max-width: 62rem;
  }
}

.home-pricing-text-sub-wrap {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 3.2rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-pricing-text-sub-wrap {
    gap: .8rem 2.4rem;
    max-width: 45rem;
  }
}

.home-pricing-text-sub-item {
  align-items: center;
  column-gap: .8rem;
  display: flex;
}

.home-pricing-text-sub-item .dot {
  background-color: #f5450d;
  border-radius: 50%;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
}

.home-pricing-text-sub-item-txt {
  color: #fff9;
}

.home-pricing-plan {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

@media screen and (width <= 767px) {
  .home-pricing-plan {
    width: 100%;
  }
}

.home-pricing-plan-switch {
  --border-radius: 2rem;
  background-color: #ffffff03;
  border-radius: 2rem;
  margin-bottom: 8rem;
  padding: 1rem;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 1rem #ffffff0d;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-switch {
    margin-bottom: 7.1rem;
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-switch {
    width: 100%;
    margin-bottom: 4rem;
  }
}

.home-pricing-plan-switch:before {
  --border-width: 1px;
  content: "";
  border-radius: inherit;
  -webkit-mask-composite: xor;
  top: calc(var(--border-width) / 1.5 * -1);
  bottom: calc(var(--border-width) / 2.5 * -1);
  left: calc(var(--border-width) / 1.5 * -1);
  right: calc(var(--border-width) / 2.5 * -1);
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#fff3 0%, #fff0 100%) border-box;
  padding: 1px;
  position: absolute;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.home-pricing-plan-switch-wrap {
  --btn-width: 12.6rem;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-switch-wrap {
    --btn-width: 10.7rem;
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-switch-wrap {
    --btn-width: calc(100% / 2);
    width: 100%;
  }
}

.home-pricing-plan-switch-wrap-btn {
  align-items: center;
  display: flex;
}

.home-pricing-plan-switch-overlay {
  width: var(--btn-width);
  pointer-events: none;
  will-change: transform, opacity;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  z-index: 3;
  height: 100%;
  transition: transform .533s var(--spring-easing-3), opacity .5s ease-in-out;
  -webkit-transition: transform .533s var(--spring-easing-3), opacity .5s ease-in-out;
  -moz-transition: transform .533s var(--spring-easing-3), opacity .5s ease-in-out;
  -ms-transition: transform .533s var(--spring-easing-3), opacity .5s ease-in-out;
  -o-transition: transform .533s var(--spring-easing-3), opacity .5s ease-in-out;
  pointer-events: none;
  grid-template-columns: 1fr;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (width <= 767px) {
  .home-pricing-plan-switch-overlay {
    width: 50%;
  }
}

.home-pricing-plan-switch-overlay:first-of-type {
  mix-blend-mode: hard-light;
  opacity: 0;
}

.home-pricing-plan-switch-overlay:last-of-type {
  mix-blend-mode: lighten;
}

.home-pricing-plan-switch-overlay .btn {
  border-radius: var(--border-radius);
}

@media screen and (width <= 991px) {
  .home-pricing-plan-switch-overlay .btn {
    --border-radius: 1rem;
  }
}

.home-pricing-plan-switch-btn {
  color: #fff;
  width: var(--btn-width);
  cursor: pointer;
  z-index: 1;
  padding: 1.1rem 0;
  position: relative;
}

.home-pricing-plan-switch-btn.active {
  color: #f5450d;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-switch-btn {
    --fs-16: 1.4rem;
    --lh-16: 1.3em;
    width: var(--btn-width);
  }
}

.home-pricing-plan-wrapper {
  width: 100%;
  max-width: 124rem;
}

.home-pricing-plan-listing {
  grid-template-rows: 7rem 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 2rem;
  margin-bottom: 8rem;
  display: grid;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-listing {
    grid-template-rows: 6.8rem 1fr;
    gap: 0 1.6rem;
    margin-bottom: 6rem;
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-listing {
    flex-direction: column;
    gap: 2.4rem;
    margin-bottom: 0;
    padding: 0 2.15rem;
    display: flex;
  }
}

.home-pricing-plan-item {
  --border-radius: 2rem;
  background-color: #0e0e0e;
  border-radius: 2rem;
  flex-direction: column;
  grid-row: 2 / 3;
  padding: 4rem 4rem 5rem;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 1rem #ffffff0d;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-item {
    padding: 3.2rem 1.6rem;
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-item {
    background-color: #fff0;
    padding: 3.2rem 2.4rem;
    box-shadow: inset 0 0 1.5rem #ffffff1a;
  }
}

.home-pricing-plan-item:before {
  content: "";
  border-radius: inherit;
  -webkit-mask-composite: xor;
  pointer-events: none;
  z-index: 20;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#fff3 0%, #fff0 100%) border-box;
  padding: 1px;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.home-pricing-plan-item-bg {
  border-radius: var(--border-radius);
  z-index: 15;
  backdrop-filter: blur(5rem);
  pointer-events: none;
  background-color: #ffffff0d;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.home-pricing-plan-item.popular {
  color: #fff;
  background: linear-gradient(#f5370d 0%, #f5430d 34%, #f58a0d 100%);
  grid-row: 1 / 3;
  box-shadow: inset 0 0 1.5rem #ffffff1a;
}

.home-pricing-plan-item.popular .home-pricing-plan-item-bg {
  backdrop-filter: unset;
  background: linear-gradient(#f5370d 0%, #f5430d 34%, #f58a0d 100%);
}

.home-pricing-plan-item.popular:before {
  pointer-events: none;
  -webkit-mask-composite: xor;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#ff693a 0%, #ff693a00 100%) border-box;
  border-radius: 2rem;
  padding: 1px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.home-pricing-plan-item.popular .home-pricing-plan-item-time {
  color: #fffc;
}

.home-pricing-plan-item.popular .home-pricing-plan-item-detail-txt .img {
  filter: brightness(0) saturate() invert(99%) sepia(10%) saturate(7%) hue-rotate(132deg) brightness(103%) contrast();
}

.home-pricing-plan-item-popularTag {
  color: #fff;
  background: #ffffff0d;
  border: 1px solid #ffffff1a;
  border-radius: 5rem;
  margin: 0 auto 2rem;
  padding: 1.2rem 2rem;
}

@media screen and (width <= 767px) {
  .home-pricing-plan-item-popularTag {
    padding: 1rem 2rem;
  }
}

.home-pricing-plan-item-label {
  margin-bottom: 2rem;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-item-label {
    margin-bottom: 1.2rem;
  }
}

.home-pricing-plan-item-time {
  margin-bottom: 2rem;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-item-time {
    margin-bottom: 1.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-item-time {
    font-size: var(--fs-12);
    line-height: var(--lh-12);
  }
}

.home-pricing-plan-item-off {
  color: #fff9;
  margin-bottom: .8rem;
  text-decoration: line-through;
}

@media screen and (width <= 767px) {
  .home-pricing-plan-item-off {
    font-size: var(--fs-14);
    line-height: var(--lh-14);
  }
}

.home-pricing-plan-item-price {
  justify-content: center;
  align-items: end;
  margin-bottom: 2rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-item-price {
    margin-bottom: 1.2rem;
  }
}

.home-pricing-plan-item-price-inner {
  gap: 0 .4rem;
  display: flex;
}

.home-pricing-plan-item-price-txt .h4 {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

@media screen and (width <= 767px) {
  .home-pricing-plan-item-price-txt .h4 {
    --fs-h4: 3.6rem;
    --lh-h4: 1.1em;
  }
}

.home-pricing-plan-item-price-txt .active {
  opacity: 1;
  pointer-events: auto;
  position: relative;
}

.home-pricing-plan-item-price-unit {
  color: #fff;
  margin-top: .4rem;
}

.home-pricing-plan-item-price-periodic {
  color: #fff9;
  margin-bottom: .4rem;
  margin-left: .4rem;
}

.home-pricing-plan-item-desc {
  text-align: center;
  color: #fff;
  opacity: .8;
  margin-bottom: 2rem;
  max-width: 26rem;
  margin-inline: auto;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-item-desc {
    font-size: var(--fs-12);
    line-height: var(--lh-12);
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-item-desc {
    color: #fff;
    margin-bottom: 1.6rem;
  }
}

.home-pricing-plan-item-detail {
  text-align: left;
  flex-direction: column;
  gap: 1.2rem 0;
  margin-top: 3.2rem;
  margin-bottom: 3.6rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-item-detail {
    margin-top: 2.8rem;
    row-gap: .8rem;
    margin-inline: -.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-item-detail {
    margin-bottom: 3.6rem;
  }
}

.home-pricing-plan-item-detail-txt {
  color: #fff;
  align-items: start;
  gap: 0 .8rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-item-detail-txt {
    --fs-18: 1.2rem;
    --lh-18: 1.4em;
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-item-detail-txt {
    --fs-18: 1.4rem;
    --lh-18: 1.3em;
    gap: 0 1rem;
  }
}

.home-pricing-plan-item-detail-txt img {
  flex: none;
  width: 2rem;
  margin-top: .4rem;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-item-detail-txt img {
    width: 1.6rem;
    margin-top: .1rem;
  }
}

.home-pricing-plan-item-btn {
  margin-top: auto;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-item-btn .txt-btn {
    --fs-18: 1.6rem;
    --lh-18: 1.4em;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }
}

.home-pricing-plan-booking {
  text-align: center;
  margin: 2.4rem auto 0;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-booking {
    --fs-16: 1.4rem;
    --lh-16: 1.3em;
    margin-top: 1.2rem;
    font-weight: 500;
  }
}

.home-pricing-plan-cta {
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

@media screen and (width <= 767px) {
  .home-pricing-plan-cta {
    align-items: stretch;
    padding-top: 6rem;
  }
}

.home-pricing-plan-cta-btn {
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.home-pricing-plan-cta-btn a {
  color: #fff;
}

@media screen and (width <= 767px) {
  .home-pricing-plan-cta-btn .btn-border {
    width: 100%;
  }
}

.home-pricing-plan-cta-heading {
  max-width: 66rem;
  margin-bottom: 2rem;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-cta-heading {
    max-width: 57.3rem;
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-cta-heading {
    --lh-h6: 1.2em;
    max-width: 32rem;
  }
}

.home-pricing-plan-cta-desc {
  max-width: 81.5rem;
  margin-bottom: 4rem;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-cta-desc {
    max-width: 57.3rem;
    margin-bottom: 3.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-cta-desc {
    margin-bottom: 4rem;
  }

  .home-pricing-plan-cta .btn {
    width: 100%;
    height: auto;
  }
}

.home-pricing-plan-cta .txt-btn {
  padding: 2.4rem 10.4rem;
}

@media screen and (width <= 991px) {
  .home-pricing-plan-cta .txt-btn {
    padding: 1.4rem 6.05rem;
  }
}

@media screen and (width <= 767px) {
  .home-pricing-plan-cta .txt-btn {
    --fs-18: 1.6rem;
    --lh-18: 1.4em;
  }
}

.home-explore {
  padding-top: var(--height-header);
  padding-bottom: calc(20rem - var(--height-header));
  display: none;
}

@media screen and (width <= 991px) {
  .home-explore {
    padding-bottom: calc(14rem - var(--height-header));
  }
}

@media screen and (width <= 767px) {
  .home-explore {
    padding-bottom: calc(9.5rem - var(--height-header));
  }
}

.home-explore-heading {
  text-align: center;
  grid-area: 1 / 1 / 2 / 17;
  max-width: 144rem;
  margin: 0 auto;
}

@media screen and (width <= 991px) {
  .home-explore-heading {
    --fs-h3: 3.6rem;
    --lh-h3: 1.1em;
    grid-column: 2 / 12;
  }
}

@media screen and (width <= 767px) {
  .home-explore-heading {
    --fs-h3: 3.2rem;
    grid-column: 1 / -1;
  }
}

.home-explore-img {
  grid-area: 1 / 2 / 2 / 16;
  justify-self: center;
  width: 50.8rem;
  margin-top: 16.3rem;
}

@media screen and (width <= 991px) {
  .home-explore-img {
    grid-column: 2 / 12;
    width: 31.47rem;
    margin-top: 10.1rem;
  }
}

@media screen and (width <= 767px) {
  .home-explore-img {
    grid-column: 1 / -1;
    align-items: center;
    width: 19.8rem;
    margin-top: 0;
    display: flex;
  }
}

.home-explore-industries {
  grid-column: 3 / 15;
  justify-self: center;
  margin-top: 9rem;
}

@media screen and (width <= 991px) {
  .home-explore-industries {
    grid-column: 2 / 12;
    margin-top: 8.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-explore-industries {
    grid-column: 1 / -1;
    margin-top: 10.5rem;
  }
}

.home-explore-industries-title {
  text-align: center;
  max-width: 44rem;
  margin-bottom: 10.7rem;
}

@media screen and (width <= 991px) {
  .home-explore-industries-title {
    --fs-h6: 2rem;
    --lh-h6: 1.2em;
    max-width: 28rem;
    margin-bottom: 8.1rem;
  }
}

@media screen and (width <= 767px) {
  .home-explore-industries-title {
    --fs-h6: 1.8rem;
    --lh-h6: 1.4em;
    margin-bottom: 4.318rem;
  }
}

.home-explore-industries-wrap {
  grid-column: 1 / -1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (width <= 767px) {
  .home-explore-industries-wrap {
    margin: 0 -1.6rem;
  }
}

.home-explore-industries-logo {
  z-index: 7;
  filter: drop-shadow(0 .7rem 1.9rem #f5370d99);
  background: linear-gradient(#f5370d 0%, #f5430d 34%, #f58a0d 100%);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 12.5rem;
  height: 12.5rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (width <= 991px) {
  .home-explore-industries-logo {
    width: 9rem;
    height: 9rem;
  }
}

@media screen and (width <= 767px) {
  .home-explore-industries-logo {
    width: 4.62rem;
    height: 4.62rem;
  }
}

.home-explore-industries-logo-img {
  width: 44%;
  margin-top: 10%;
}

.home-explore-industries-radar {
  aspect-ratio: 2;
  border: 1px solid #f5370d33;
  border-top: none;
  border-radius: 0 0 1000rem 1000rem;
  width: 110.6rem;
  position: relative;
  overflow: hidden;
}

.home-explore-industries-radar-wrapper {
  aspect-ratio: 2;
  border: 1px solid #f5370d33;
  border-top-color: #50140780;
  border-radius: 0 0 1000rem 1000rem;
  width: 92.2242%;
  margin: auto;
  position: relative;
}

.home-explore-industries-radar-wrapper-inner {
  z-index: 1;
  border-radius: 0 0 1000rem 1000rem;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.home-explore-industries-radar-wrapper-inner-wave {
  background: #01010103;
  border: 1px solid #f5370d26;
  border-radius: 50%;
  width: 100%;
  padding-bottom: 100%;
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  box-shadow: inset 0 0 70px 30px #f5430d26, inset 0 0 100px 60px #f5430d0d;
}

.home-explore-industries-radar-wrapper-inner-wave:nth-child(2) {
  transform: scale(.76052);
}

.home-explore-industries-radar-wrapper-inner-wave:nth-child(3) {
  transform: scale(.490196);
}

.home-explore-industries-radar-wrapper-inner-wave:nth-child(4) {
  transform: scale(.200647);
}

.home-explore-industries-radar-wrapper-item {
  z-index: 6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.home-explore-industries-radar-wrapper-item-line {
  transform-origin: 0;
  width: 50%;
  height: 1px;
  transform: translate(100%, -50%) rotate(calc(var(--i) * 30deg));
  -webkit-transform: translate(100%, -50%) rotate(calc(var(--i) * 30deg));
  -moz-transform: translate(100%, -50%) rotate(calc(var(--i) * 30deg));
  -ms-transform: translate(100%, -50%) rotate(calc(var(--i) * 30deg));
  -o-transform: translate(100%, -50%) rotate(calc(var(--i) * 30deg));
  background-color: #50140780;
  align-items: center;
  display: flex;
  position: absolute;
}

.home-explore-industries-radar-wrapper-item-line-dot {
  aspect-ratio: 1;
  filter: drop-shadow(0 0 7px #ff4f04e6);
  width: .6rem;
  translate: -50%;
  rotate: calc(var(--i) * -30deg);
  background-color: #f5450d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: 76.052%;
  display: flex;
  position: absolute;
}

@media screen and (width <= 767px) {
  .home-explore-industries-radar-wrapper-item-line-dot {
    width: .2rem;
  }
}

.home-explore-industries-radar-wrapper-item-line-dot-wrap {
  aspect-ratio: 1;
  opacity: 0;
  -o-transition: opacity .5s cubic-bezier(.12, 0, .39, 0);
  justify-content: center;
  align-items: center;
  width: 7.68rem;
  transition: opacity .5s cubic-bezier(.12, 0, .39, 0);
  display: flex;
  position: absolute;
}

@media screen and (width <= 991px) {
  .home-explore-industries-radar-wrapper-item-line-dot-wrap {
    width: 4.35rem;
  }
}

@media screen and (width <= 767px) {
  .home-explore-industries-radar-wrapper-item-line-dot-wrap {
    width: 2rem;
  }
}

.home-explore-industries-radar-wrapper-item-line-dot-wrap.active, .home-explore-industries-radar-wrapper-item-line-dot-wrap.on-hover {
  opacity: 1;
  -o-transition: opacity 1s cubic-bezier(.61, 1, .88, 1);
  transition: opacity 1s cubic-bezier(.61, 1, .88, 1);
}

.home-explore-industries-radar-wrapper-item-line-dot:nth-child(2) {
  margin-left: 49.0196%;
}

.home-explore-industries-radar-wrapper-item-line-innerdot {
  pointer-events: none;
  -ms-animation: loopDot 4s linear infinite;
  background-color: #f5430d1a;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 4s linear infinite loopDot;
  position: absolute;
}

@keyframes loopDot {
  0% {
    opacity: 1;
    scale: 0;
  }

  50% {
    opacity: 1;
    scale: .5;
  }

  100% {
    opacity: 0;
    scale: 1;
  }
}

.home-explore-industries-radar-wrapper-item-line-innerdot:first-child {
  animation-delay: -1s;
}

.home-explore-industries-radar-wrapper-item-line-innerdot:nth-child(2) {
  animation-delay: -2s;
}

.home-explore-industries-radar-wrapper-item-line-innerdot:nth-child(3) {
  animation-delay: -3s;
}

.home-explore-industries-radar-wrapper-item-line-innerdot:nth-child(4) {
  animation-delay: -4s;
}

.home-explore-industries-radar-wrapper-item-line-content {
  white-space: nowrap;
  pointer-events: none;
  background: linear-gradient(#ffffff26 0%, #ffffff08 100%);
  border-radius: 100vmax;
  padding: .6rem 1.6rem;
  position: absolute;
  translate: 0 -5rem;
}

@media screen and (width <= 991px) {
  .home-explore-industries-radar-wrapper-item-line-content {
    translate: 0 -3rem;
  }
}

@media screen and (width <= 767px) {
  .home-explore-industries-radar-wrapper-item-line-content {
    padding: .4rem .8rem;
    translate: 0 -1.5rem;
  }
}

.home-explore-industries-radar-wrapper-item-line-content:before {
  content: "";
  -webkit-mask-composite: xor;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(#fff3 0%, #fff0 100%) border-box;
  border-radius: 100vmax;
  padding: 1px;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.home-explore-industries-radar-wrapper-item-line-content p {
  margin: 0;
  line-height: 1;
}

@media screen and (width <= 991px) {
  .home-explore-industries-radar-wrapper-item-line-content p {
    --fs-14: 1.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-explore-industries-radar-wrapper-item-line-content p {
    --fs-14: .8rem;
  }

  .home-explore-industries-radar-wrapper-item-line:nth-child(2) .home-explore-industries-radar-wrapper-item-line-content {
    translate: 0 -2.5rem;
  }

  .home-explore-industries-radar-wrapper-item-line:nth-child(4) .home-explore-industries-radar-wrapper-item-line-content {
    translate: 0 -2.3rem;
  }
}

.home-explore-industries-radar-scan {
  pointer-events: none;
  z-index: 2;
  width: 107.25%;
  position: absolute;
  top: -107.25%;
  left: -3.625%;
  right: 0;
}

.home-faq {
  padding-top: 15rem;
  padding-bottom: 25rem;
}

@media screen and (width <= 991px) {
  .home-faq {
    padding-top: 10rem;
    padding-bottom: 12rem;
  }
}

@media screen and (width <= 767px) {
  .home-faq {
    padding-bottom: 10rem;
  }
}

.home-faq-heading {
  text-align: center;
  margin-bottom: 10rem;
  grid-column: 4 / 14;
  max-width: 100rem;
  margin-inline: auto;
}

@media screen and (width <= 991px) {
  .home-faq-heading {
    grid-column: 3 / 11;
    margin-bottom: 6rem;
  }
}

@media screen and (width <= 767px) {
  .home-faq-heading {
    grid-column: 1 / -1;
    margin-bottom: 4.6rem;
  }
}

.home-faq-content {
  grid-column: 4 / 14;
}

@media screen and (width <= 991px) {
  .home-faq-content {
    grid-column: 1 / -1;
  }
}

.home-faq-content-item {
  --widthOrdinal: 13rem;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-faq-content-item {
    --widthOrdinal: 7rem;
  }
}

@media screen and (width <= 767px) {
  .home-faq-content-item {
    --widthOrdinal: 6.2rem;
  }
}

.home-faq-content-item-ques {
  cursor: pointer;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-faq-content-item-ques {
    padding-bottom: 3.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-faq-content-item-ques {
    align-items: flex-start;
    padding: 2.4rem 0;
  }
}

.home-faq-content-item-ques-ordinal {
  color: #fff3;
  width: var(--widthOrdinal);
  flex: none;
}

.home-faq-content-item-ques-title {
  transition: color 1.333s var(--spring-easing-2);
  -webkit-transition: color 1.333s var(--spring-easing-2);
  -moz-transition: color 1.333s var(--spring-easing-2);
  -ms-transition: color 1.333s var(--spring-easing-2);
  -o-transition: color 1.333s var(--spring-easing-2);
  flex-grow: 1;
}

@media screen and (width <= 991px) {
  .home-faq-content-item-ques-title {
    --lh-h6: 1.2em;
  }
}

@media screen and (width <= 767px) {
  .home-faq-content-item-ques-title {
    --fs-h6: 2rem;
  }
}

.home-faq-content-item-ques-dot {
  --border-radius: 100vmax;
  -o-transition: border .65s cubic-bezier(.6, 0, .3, 1) .2s;
  border: 1.5px solid #fff3;
  border-radius: 100vmax;
  flex: none;
  width: 2rem;
  height: 2rem;
  margin-left: 3rem;
  transition: border .65s cubic-bezier(.6, 0, .3, 1) .2s;
  position: relative;
}

@media screen and (width <= 991px) {
  .home-faq-content-item-ques-dot {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.home-faq-content-item-ques-dot:before {
  content: "";
  opacity: 0;
  -o-transition: transform .6s ease-in-out, opacity .6s ease-in-out;
  background: #f5450d;
  border-radius: 50%;
  transition: transform .6s ease-in-out, opacity .6s ease-in-out;
  position: absolute;
  inset: -1px;
  transform: translateY(100%);
}

.home-faq-content-item-answer {
  margin-left: var(--widthOrdinal);
  flex-direction: column;
  row-gap: 1em;
  margin-top: -1.6rem;
  padding-bottom: 4rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .home-faq-content-item-answer {
    padding-bottom: 3.2rem;
  }
}

@media screen and (width <= 767px) {
  .home-faq-content-item-answer {
    --fs-20: 1.4rem;
    --lh-20: 1.3em;
    margin-top: -.8rem;
  }
}

.home-faq-content-item .line {
  width: 100%;
  height: 1.5px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.home-faq-content-item .line:before, .home-faq-content-item .line:after {
  --line-spacing: 1rem;
  content: "";
  width: calc(100% - var(--widthOrdinal));
  -o-transition: transform .65s cubic-bezier(.6, 0, .3, 1) .15s;
  height: 1.5px;
  transition: transform .65s cubic-bezier(.6, 0, .3, 1) .15s;
  display: block;
  position: absolute;
  inset: auto 0 0;
}

@media screen and (width <= 767px) {
  .home-faq-content-item .line:before, .home-faq-content-item .line:after {
    height: 1px;
  }
}

.home-faq-content-item .line:before {
  opacity: 0;
  left: var(--widthOrdinal);
  -o-transition: transform .65s cubic-bezier(.6, 0, .3, 1), opacity .65s cubic-bezier(.6, 0, .3, 1) 50ms;
  background: linear-gradient(270deg, #f5450d 34%, #f58a0d 100%);
  transition: transform .65s cubic-bezier(.6, 0, .3, 1), opacity .65s cubic-bezier(.6, 0, .3, 1) 50ms;
}

.home-faq-content-item .line:after {
  left: calc(-1 * var(--line-spacing));
  opacity: .8;
  -o-transition: transform .65s cubic-bezier(.6, 0, .3, 1) .2s;
  background: linear-gradient(90deg, #f5450d 34%, #f58a0d 100%);
  transition: transform .65s cubic-bezier(.6, 0, .3, 1) .2s;
  transform: translateX(-100%);
}

.home-faq-content-item.active .home-faq-content-item-ques-title {
  color: #f5450d;
}

.home-faq-content-item.active .home-faq-content-item-ques-dot {
  border-color: #f5450d;
}

.home-faq-content-item.active .line:before {
  opacity: 1;
  transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
  -webkit-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
  -moz-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
  -ms-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
  -o-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
}

.home-faq-content-item.active .line:after {
  transform: translateX(calc(var(--line-spacing)  + var(--widthOrdinal))) rotate(.01deg);
  -webkit-transform: translateX(calc(var(--line-spacing)  + var(--widthOrdinal))) rotate(.01deg);
  -moz-transform: translateX(calc(var(--line-spacing)  + var(--widthOrdinal))) rotate(.01deg);
  -ms-transform: translateX(calc(var(--line-spacing)  + var(--widthOrdinal))) rotate(.01deg);
  -o-transform: translateX(calc(var(--line-spacing)  + var(--widthOrdinal))) rotate(.01deg);
}

.home-faq-content-item:hover .home-faq-content-item-ques-title {
  color: #f5450d;
}

.home-faq-content-item:hover .home-faq-content-item-ques-dot {
  border: 1.5px solid #f5450d;
}

.home-faq-content-item:hover .line:before {
  opacity: 1;
  transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
  -webkit-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
  -moz-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
  -ms-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
  -o-transform: translateX(calc(100% + var(--line-spacing))) rotate(.01deg);
}

.home-faq-content-item:hover .line:after {
  transform: translateX(calc(var(--line-spacing)  + var(--widthOrdinal))) rotate(.01deg);
  -webkit-transform: translateX(calc(var(--line-spacing)  + var(--widthOrdinal))) rotate(.01deg);
  -moz-transform: translateX(calc(var(--line-spacing)  + var(--widthOrdinal))) rotate(.01deg);
  -ms-transform: translateX(calc(var(--line-spacing)  + var(--widthOrdinal))) rotate(.01deg);
  -o-transform: translateX(calc(var(--line-spacing)  + var(--widthOrdinal))) rotate(.01deg);
}

.policy {
  --height-header: 15.4rem;
}

.policy-heading {
  white-space: nowrap;
  text-align: center;
  color: #fff;
  margin-top: calc(27rem - var(--height-header));
  grid-column: 3 / 15;
}

@media screen and (width <= 991px) {
  .policy-heading {
    grid-column: 3 / 11;
    margin-top: 16.7286rem;
  }
}

@media screen and (width <= 767px) {
  .policy-heading {
    white-space: normal;
    grid-column: 1 / -1;
    margin-top: 10.3647rem;
  }
}

.policy-heading-date {
  margin-top: 5rem;
}

@media screen and (width <= 991px) {
  .policy-heading-date {
    margin-top: 3.09789rem;
  }
}

@media screen and (width <= 767px) {
  .policy-heading-date {
    margin-top: 1.91939rem;
    padding: 0 3rem;
  }
}

.policy-content {
  flex-direction: column;
  grid-column: 5 / 13;
  row-gap: 4rem;
  margin-top: 15rem;
  margin-bottom: 20rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .policy-content {
    grid-column: 3 / 11;
    margin-top: 9.29368rem;
    margin-bottom: 12.3916rem;
  }
}

@media screen and (width <= 767px) {
  .policy-content {
    grid-column: 1 / -1;
    margin-top: 5.75817rem;
    margin-bottom: 7.67756rem;
  }
}

.policy-content-item-desc {
  font-size: var(--fs-20);
  line-height: var(--lh-20);
}

.policy-content-item-desc :not(li, a) {
  margin-top: 2rem;
}

.policy-content-item-desc h6 {
  font-size: var(--fs-20);
  line-height: var(--lh-20);
  font-weight: 500;
}

.policy-content-item-desc ul {
  padding-left: 2rem;
}

.policy-content-item-desc a {
  color: #ccc;
  display: inline-block;
}

.policy-content-item-desc a span {
  color: #fff;
}

.policy .header-main {
  margin-right: 0;
}

.policy .header-menu, .policy .header-hamburger {
  display: none;
}

.policy .header-main-inner {
  padding: 1rem;
}

.success-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.success-bg-grain-noise {
  pointer-events: none;
  opacity: .6;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.success-bg-grain-noise:before {
  content: "";
  -ms-animation: noise 2s steps(4) infinite;
  background-image: url("bg-noise-grain.98c83be2.webp");
  background-position: 40%;
  width: calc(100% + 42vw);
  height: calc(100% + 42vw);
  animation: 2s steps(4, end) infinite noise;
  position: absolute;
  top: -21vw;
  left: -21vw;
}

.success-bg-blur {
  z-index: 1;
  width: 100%;
  height: 129.2rem;
  position: absolute;
  top: 0;
}

.success-wrap {
  z-index: 3;
  --total-space: calc(100vh - 78.9rem);
  flex-direction: column;
  height: 100vh;
  display: flex;
  position: relative;
}

.success-popup {
  max-width: 60.6rem;
  padding-top: calc(.5 * var(--total-space));
  grid-column: 6 / 12;
  margin: 0 auto 3.6rem;
}

.success-popup-top {
  justify-content: center;
  display: flex;
}

.success-popup-top-mid-shape {
  height: 7.5rem;
}

.success-popup-top-shape {
  z-index: 2;
  position: relative;
}

.success-popup-top-shape.shape-left, .success-popup-top-shape.shape-right {
  backdrop-filter: blur(1rem);
  background-color: #ffffff14;
  border: 1px solid #ffffff1a;
  border-bottom: none;
  flex: 1;
}

.success-popup-top-shape.shape-right {
  border-left: none;
  border-top-right-radius: 2rem;
}

.success-popup-top-shape.shape-left {
  border-right: none;
  border-top-left-radius: 2rem;
}

.success-popup-top-shape.shape-mid {
  backdrop-filter: blur(1rem);
  height: 7.5rem;
}

.success-popup-top-shape.shape-mid img {
  backdrop-filter: blur(1rem);
}

.success-popup-top-circle {
  z-index: 3;
  background: linear-gradient(#f5370d 0%, #f5430d 34%, #f58a0d 100%);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 11rem;
  height: 11rem;
  display: flex;
  position: absolute;
  transform: translateY(-50%);
  box-shadow: inset 0 0 8.3696rem 3.587rem #f5430d26, inset 0 0 11.9565rem 7.1739rem #f5430d0d, 0 0 12rem #f5370d7a;
}

.success-popup-top-circle .img {
  width: 4rem;
}

.success-popup-bot {
  backdrop-filter: blur(1rem);
  text-align: center;
  background-color: #ffffff14;
  border: 1px solid #ffffff1a;
  border-top: none;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 4rem 4rem;
  display: flex;
}

.success-popup-title {
  margin-bottom: 2.4rem;
}

.success-popup-sub {
  margin-bottom: 3.2rem;
}

.success-popup-plan {
  --border-size: 1px;
  --dash-size: 1em;
  background-image: repeating-linear-gradient(0deg, #0000, #0000 10px 10px, #0000 20px 20px), repeating-linear-gradient(90deg, #ffffff1a, #ffffff1a 10px, #0000 10px 20px, #ffffff1a 20px);
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  width: 100%;
  padding-top: 3.2rem;
}

.success-popup-plan-label {
  margin-bottom: .2rem;
}

.success-popup-plan-scope {
  margin-bottom: 2.4rem;
}

.success-popup-plan-price-wrap {
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 3.2rem;
  display: flex;
}

.success-popup-plan-price {
  display: flex;
}

.success-popup-plan-periodic {
  margin-bottom: .8em;
}

.success-popup-plan-renewa p {
  display: inline-block;
}

.success-popup-plan-renewa-date {
  color: #fffc;
}

.success-thanks {
  text-align: center;
  grid-column: 5 / 13;
  max-width: 62rem;
  margin: 0 auto 4.4rem;
}

.success-back {
  text-align: center;
  grid-column: 7 / 11;
  width: 38.5rem;
  margin: 0 auto;
}

.success-contact {
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 0 .6rem;
  margin: auto auto 4rem;
  padding-top: 2rem;
  display: flex;
}
/*# sourceMappingURL=style.77324534.css.map */
